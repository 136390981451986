import { useCallback } from 'react';

import { FieldValues, UseFormSetError } from 'react-hook-form';

import { ActionData } from 'shared/types/actionData';
import { formErrorHelper } from 'shared/helpers/formErrorHelper';
import { useSnackbar } from 'shared/providers/Snackbar';
import { useAuth } from 'shared/providers/Auth';

export const useErrorHandler = <T extends FieldValues>() => {
  const { openSnackbar } = useSnackbar();
  const { postLoginStartAndRedirect } = useAuth();

  return useCallback(
    async (error: ActionData, setError?: UseFormSetError<T>) => {
      if (error.status === 401) {
        await postLoginStartAndRedirect();
        return;
      }

      if (error.validationError && error.validationError.length && setError) {
        formErrorHelper<T>(error.validationError, setError);
      }

      if (error.title) {
        openSnackbar(error.title, 'error');
      }
    },
    [openSnackbar, postLoginStartAndRedirect]
  );
};
