import { styled } from '@mui/material';

import { hideMixin, showMixin } from 'styles/mixins';

const CUSTOM_PROPS = ['isSidebarOpen', 'actingHeight'] as PropertyKey[];

interface StyledFooterProps {
  isSidebarOpen: boolean;
  actingHeight: number;
}

export const StyledFooter = styled('footer', {
  shouldForwardProp: (propName: PropertyKey) => !CUSTOM_PROPS.includes(propName)
})<StyledFooterProps>`
  padding: ${({ theme }) => theme.spacing(2)};
  white-space: break-spaces;
  margin-bottom: ${({ actingHeight }) => `${actingHeight}px`};

  ${({ isSidebarOpen, theme }) =>
    isSidebarOpen ? { ...showMixin(theme) } : { ...hideMixin(theme) }}

  .MuiTypography-root {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
  }

  > svg {
    padding-top: ${({ theme }) => theme.spacing(0.75)};
    overflow: initial;
  }
`;
