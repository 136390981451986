import { forwardRef, ForwardRefRenderFunction } from 'react';

import { AlertColor, Box } from '@mui/material';
import { CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon } from '@mui/icons-material';
import { SnackbarKey, SnackbarMessage } from 'notistack';

import { StyledAlert } from './SnackbarAlert.styled';

interface AlertProps {
  variant: AlertColor;
  message: SnackbarMessage;
  key: SnackbarKey;
  closeSnackbar: (key: SnackbarKey) => void;
}

const SnackbarAlertComponent: ForwardRefRenderFunction<HTMLDivElement, AlertProps> = (
  { variant, message, key, closeSnackbar },
  ref
) => {
  return (
    <StyledAlert
      variant='filled'
      ref={ref}
      onClose={() => closeSnackbar(key)}
      severity={variant}
      iconMapping={{
        success: <CheckCircleOutlineOutlinedIcon />
      }}
    >
      <Box display='flex' flexDirection='column'>
        <Box component='span' fontSize='0.875rem' lineHeight='1.25rem'>
          {message}
        </Box>
      </Box>
    </StyledAlert>
  );
};

export const SnackbarAlert = forwardRef(SnackbarAlertComponent);
