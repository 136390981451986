import { FieldPath, FieldValues, UseFormSetError } from 'react-hook-form';

import { FormattedServerValidation } from '../utils/axiosInstance';

export const formErrorHelper = <TFieldValues extends FieldValues>(
  validationError: FormattedServerValidation[],
  setError: UseFormSetError<TFieldValues>
): void => {
  validationError.forEach((value, index, array) => {
    const currentValue = Object.keys(value)[0] as FieldPath<TFieldValues>;

    setError(currentValue, {
      type: 'server',
      message: array[index][currentValue]
    });
  });
};
