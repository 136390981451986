/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inspired.PaymentPortal.API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  InspiredPaymentPortalAPIContactUsResponsesGetContactUsOptionsResponse,
  InspiredLibrariesApiExceptionHandlingErrorResponse,
  InspiredPaymentPortalAPIContactUsResponsesPostContactUsResponse,
  PostContactUsBody
} from '../../models/payments';
import { customInstance } from '../../utils/axiosInstance';
import type { ErrorType } from '../../utils/axiosInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getContactUsOptions = () => {
  return customInstance<InspiredPaymentPortalAPIContactUsResponsesGetContactUsOptionsResponse>({
    url: `/api/contact-us/options`,
    method: 'get'
  });
};

export const getGetContactUsOptionsQueryKey = () => [`/api/contact-us/options`] as const;

export const getGetContactUsOptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getContactUsOptions>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getContactUsOptions>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<typeof getContactUsOptions>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContactUsOptionsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContactUsOptions>>> = () =>
    getContactUsOptions();

  return { queryKey, queryFn, ...queryOptions };
};

export type GetContactUsOptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getContactUsOptions>>
>;
export type GetContactUsOptionsQueryError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetContactUsOptions = <
  TData = Awaited<ReturnType<typeof getContactUsOptions>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getContactUsOptions>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetContactUsOptionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const postContactUs = (postContactUsBody: PostContactUsBody) => {
  const formData = new FormData();
  formData.append('queryType', postContactUsBody.queryType.toString());
  formData.append('query', postContactUsBody.query);
  if (postContactUsBody.file !== undefined) {
    formData.append('file', postContactUsBody.file);
  }

  return customInstance<InspiredPaymentPortalAPIContactUsResponsesPostContactUsResponse>({
    url: `/api/contact-us`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData
  });
};

export const getPostContactUsMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postContactUs>>,
    TError,
    { data: PostContactUsBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postContactUs>>,
  TError,
  { data: PostContactUsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postContactUs>>,
    { data: PostContactUsBody }
  > = props => {
    const { data } = props ?? {};

    return postContactUs(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostContactUsMutationResult = NonNullable<Awaited<ReturnType<typeof postContactUs>>>;
export type PostContactUsMutationBody = PostContactUsBody;
export type PostContactUsMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const usePostContactUs = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postContactUs>>,
    TError,
    { data: PostContactUsBody },
    TContext
  >;
}) => {
  const mutationOptions = getPostContactUsMutationOptions(options);

  return useMutation(mutationOptions);
};
