/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inspired.PaymentPortal.API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  InspiredPaymentPortalAPIPaymentsResponsesAddDirectDebitMandateResponse,
  InspiredLibrariesApiExceptionHandlingErrorResponse,
  InspiredPaymentPortalAPIPaymentsResponsesDirectDebitInfoResponse
} from '../../models/payments';
import { customInstance } from '../../utils/axiosInstance';
import type { ErrorType } from '../../utils/axiosInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const addMandate = () => {
  return customInstance<InspiredPaymentPortalAPIPaymentsResponsesAddDirectDebitMandateResponse>({
    url: `/api/direct-debit/mandate`,
    method: 'post'
  });
};

export const getAddMandateMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addMandate>>,
    TError,
    TVariables,
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof addMandate>>, TError, TVariables, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof addMandate>>, TVariables> = () => {
    return addMandate();
  };

  return { mutationFn, ...mutationOptions };
};

export type AddMandateMutationResult = NonNullable<Awaited<ReturnType<typeof addMandate>>>;

export type AddMandateMutationError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useAddMandate = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addMandate>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const mutationOptions = getAddMandateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getMandate = () => {
  return customInstance<InspiredPaymentPortalAPIPaymentsResponsesDirectDebitInfoResponse>({
    url: `/api/direct-debit/mandate`,
    method: 'get'
  });
};

export const getGetMandateQueryKey = () => [`/api/direct-debit/mandate`] as const;

export const getGetMandateQueryOptions = <
  TData = Awaited<ReturnType<typeof getMandate>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMandate>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<typeof getMandate>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMandateQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMandate>>> = () => getMandate();

  return { queryKey, queryFn, ...queryOptions };
};

export type GetMandateQueryResult = NonNullable<Awaited<ReturnType<typeof getMandate>>>;
export type GetMandateQueryError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetMandate = <
  TData = Awaited<ReturnType<typeof getMandate>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getMandate>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMandateQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteMandate = () => {
  return customInstance<void>({ url: `/api/direct-debit/mandate`, method: 'delete' });
};

export const getDeleteMandateMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMandate>>,
    TError,
    TVariables,
    TContext
  >;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteMandate>>, TError, TVariables, TContext> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMandate>>,
    TVariables
  > = () => {
    return deleteMandate();
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMandateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMandate>>>;

export type DeleteMandateMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useDeleteMandate = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMandate>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const mutationOptions = getDeleteMandateMutationOptions(options);

  return useMutation(mutationOptions);
};
