import { styled, ButtonProps, Button } from '@mui/material';

export const StyledBurgerButton = styled(Button, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'active'
})<ButtonProps & { active: boolean }>`
  width: ${({ theme }) => theme.spacing(8)};
  height: 100%;
  color: inherit;
  border-radius: 0;

  ${({ active }) =>
    active && {
      display: 'none'
    }}
`;
