import axios from 'axios';

import { BACKEND_URL } from 'config';

import { getByPath } from './getByPath';
import { CustomServerError } from './axiosInstance';

// Set up Base Axios instance
axios.defaults.headers.post['Content-Type'] = 'application/json';
const token = localStorage.getItem('authToken') || '';

axios.defaults.headers.common.Authorization = token;

const axiosInstance = axios.create({ baseURL: BACKEND_URL, withCredentials: true });

export const setAuthHeader = (authToken: string) => {
  localStorage.setItem('authToken', `Bearer ${authToken}`);
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`;
};

export const removeAuthHeader = () => {
  localStorage.removeItem('authToken');
  axiosInstance.defaults.headers.common.Authorization = '';
};

axiosInstance.interceptors.response.use(
  response => response,
  response => {
    const status = getByPath(response, 'response.status', {});
    const responseError = getByPath(response, 'response.data', {});
    const serverValidationError = getByPath(responseError, 'errors', null);
    let validationError;

    const error: CustomServerError<{}> = {
      ...responseError,
      title: responseError.description || responseError.title || 'Something went wrong',
      status
    };

    if (serverValidationError) {
      validationError = Object.keys(serverValidationError).map(item => ({
        [item]: serverValidationError[item][0]
      }));

      error.validationError = validationError;
    }

    return Promise.reject(error);
  }
);

export const baseAxiosInstance = axiosInstance;
