import { FC, useMemo } from 'react';

import { Typography } from '@mui/material';

import { FormattedMessage } from 'shared/components/Intl';
import { InspiredLogo } from 'shared/components/Icons';
import { ACTING_ELEMENT_ID } from 'shared/components/Layout/Acting/Acting.constants';
import { useSidebarContext } from 'shared/providers/Sidebar';
import { useElementSizeById } from 'shared/hooks/useElementSizeById';

import { StyledFooter } from './Footer.styled';

export const Footer: FC = () => {
  const { isOpen } = useSidebarContext();
  const { height } = useElementSizeById(ACTING_ELEMENT_ID);

  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <StyledFooter isSidebarOpen={isOpen} actingHeight={height}>
      <Typography component='span'>
        &#169; {currentYear} <FormattedMessage id='footer.copyright' />
      </Typography>
      <InspiredLogo />
    </StyledFooter>
  );
};
