import { styled } from '@mui/material';

export const StyledDrawerHeader = styled('div', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'bottomBorder'
})<{ bottomBorder?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(0, 2)};
  // necessary for content to be below app bar
  ${({ theme }) => theme.mixins.toolbar}

  ${({ theme, bottomBorder }) =>
    bottomBorder && { borderBottom: `${theme.spacing(0.125)} solid ${theme.palette.divider}` }}
`;
