import { PropsWithChildren, FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useDeviceSize } from 'shared/hooks/useDeviceSize';

import SidebarContext from './Sidebar.context';

const SidebarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const { isMobile, isLargeDesktop, isDesktop } = useDeviceSize();

  useEffect(() => {
    if (isMobile || isDesktop) {
      setOpen(false);
    }

    if (isLargeDesktop) {
      setOpen(true);
    }
  }, [isDesktop, isLargeDesktop, isMobile]);

  const openSidebar = useCallback(() => setOpen(true), []);

  const closeSidebar = useCallback(() => setOpen(false), []);

  const toggleSidebar = useCallback(() => setOpen(!open), [open]);

  const options = useMemo(
    () => ({
      isOpen: open,
      openSidebar,
      closeSidebar,
      toggleSidebar
    }),
    [closeSidebar, open, openSidebar, toggleSidebar]
  );

  return <SidebarContext.Provider value={options}>{children}</SidebarContext.Provider>;
};

export default SidebarProvider;
