import { styled, Button, ButtonProps } from '@mui/material';

import { hideMixin, showMixin } from 'styles/mixins';

export const StyledLocale = styled(Button, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isSidebarOpen'
})<ButtonProps & { isSidebarOpen: boolean }>`
  padding: ${({ theme }) => theme.spacing(1, 2.5)};
  color: ${({ theme }) => theme.palette.text.primary};

  ${({ isSidebarOpen, theme }) =>
    isSidebarOpen ? { ...showMixin(theme) } : { ...hideMixin(theme) }}

  &:hover {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    > .MuiSvgIcon-root {
      font-size: ${({ theme }) => theme.spacing(3)};
    }
  }

  .MuiButton-startIcon {
    margin-right: ${({ theme }) => theme.spacing(4)};
  }

  .MuiButton-endIcon {
    margin-left: auto;
  }
`;
