import { FC, PropsWithChildren, useCallback, useMemo, useRef } from 'react';

import {
  SnackbarProvider as NotistackProvider,
  VariantType,
  OptionsObject,
  SnackbarMessage,
  SnackbarKey
} from 'notistack';
import { AlertColor } from '@mui/material';

import SnackbarContext from './Snackbar.context';
import { DURATION, ERROR_DURATION } from './Snackbar.constants';
import { SnackbarAlert } from './SnackbarAlert';

const SnackbarProvider: FC<PropsWithChildren> = ({ children }) => {
  const notistackRef = useRef<NotistackProvider | null>(null);

  const closeSnackbar = useCallback((key: SnackbarKey) => {
    notistackRef.current?.closeSnackbar(key);
  }, []);

  const getDuration = useCallback((variant: VariantType) => {
    return variant === 'error' ? ERROR_DURATION : DURATION;
  }, []);

  const getContent = useCallback(
    (variant: AlertColor, message: SnackbarMessage, key: SnackbarKey) => (
      <SnackbarAlert closeSnackbar={closeSnackbar} key={key} message={message} variant={variant} />
    ),
    [closeSnackbar]
  );

  const openSnackbar = useCallback(
    (message: SnackbarMessage, variant: VariantType, options?: OptionsObject) => {
      notistackRef.current?.enqueueSnackbar(message, {
        variant,
        autoHideDuration: getDuration(variant),
        content: key => getContent(variant as AlertColor, message, key),
        ...options
      });
    },
    [getContent, getDuration]
  );

  const options = useMemo(
    () => ({
      openSnackbar
    }),
    [openSnackbar]
  );

  return (
    <NotistackProvider
      ref={notistackRef}
      maxSnack={4}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <SnackbarContext.Provider value={options}>{children}</SnackbarContext.Provider>
    </NotistackProvider>
  );
};

export default SnackbarProvider;
