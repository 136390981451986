import { CSSObject, Drawer, styled, Theme } from '@mui/material';

import { openSidebarWidth } from 'shared/constants';

const openedMixin = (theme: Theme): CSSObject => ({
  width: `${openSidebarWidth}px`,
  transition: theme.transitions.create(['width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',

  '.MuiList-root': {
    padding: theme.spacing(0, 1)
  },

  '.MuiListItemButton-root': {
    transition: theme.transitions.create(['padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(['width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  },

  '.MuiList-root': {
    padding: theme.spacing(0, 1)
  },

  '.MuiListItemButton-root': {
    padding: theme.spacing(1, 1.5),
    transition: theme.transitions.create(['padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
});

export const StyledSidebar = styled(Drawer)`
  flex: 0 0 auto;
  white-space: nowrap;

  & > .MuiDrawer-paper {
    max-width: ${openSidebarWidth}px;
    width: 100%;

    ${({ theme, open }) =>
      !open && {
        boxShadow: 'none',
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      }}
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    z-index: ${({ theme }) => theme.zIndex.drawer};
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    .MuiList-root {
      padding: ${({ theme }) => theme.spacing(0, 1)};
    },
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    z-index: ${({ theme }) => theme.zIndex.fab};

    ${({ theme, open }) =>
      open
        ? {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': {
              ...openedMixin(theme)
            }
          }
        : {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': {
              ...closedMixin(theme)
            }
          }}
  }
`;
