import { createTheme, Theme } from '@mui/material';

import { ThemeColor } from 'shared/types/themeColor';

import { getGlobalStyles } from './global';
import { getColors } from './colors';

const initialTheme = createTheme();

export const getTheme = (colors?: ThemeColor): Theme => {
  const customColor = {
    ...colors,
    ...getColors()
  } as ThemeColor;

  const overrides = getGlobalStyles(initialTheme, customColor);

  return createTheme({
    colors: customColor,
    palette: {
      ...customColor
    },
    typography: {
      fontFamily: ['Open Sans', 'arial', 'sans-serif'].join(',')
    },
    mixins: {
      toolbar: {
        minHeight: initialTheme.spacing(7),
        [`${initialTheme.breakpoints.up('xs')} and (orientation: landscape)`]: {
          minHeight: initialTheme.spacing(7)
        },
        [initialTheme.breakpoints.up('sm')]: {
          minHeight: initialTheme.spacing(7)
        }
      }
    },
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          noSsr: true
        }
      },
      MuiButton: {
        defaultProps: {
          size: 'large'
        },
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltipPlacementBottom: {
            [initialTheme.breakpoints.down('sm')]: {
              marginTop: `${initialTheme.spacing(0.5)} !important`,
              marginBottom: `${initialTheme.spacing(0.5)} !important`
            }
          },
          tooltipPlacementTop: {
            [initialTheme.breakpoints.down('sm')]: {
              marginBottom: `${initialTheme.spacing(0.5)} !important`
            }
          },
          tooltipPlacementLeft: {
            [initialTheme.breakpoints.down('sm')]: {
              marginRight: `${initialTheme.spacing(0.5)} !important`
            }
          },
          tooltipPlacementRight: {
            [initialTheme.breakpoints.down('sm')]: {
              marginLeft: `${initialTheme.spacing(0.5)} !important`
            }
          }
        }
      },
      ...overrides
    }
  });
};
