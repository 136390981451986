import { FC, PropsWithChildren } from 'react';

import { BrokenPen } from 'shared/components/Icons';

export const Warning: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <BrokenPen />
      {children}
    </>
  );
};
