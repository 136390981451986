import { FC, useState } from 'react';

import {
  Language as LanguageIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@mui/icons-material';

import { useSidebarContext } from 'shared/providers/Sidebar';
import { useLocale } from 'shared/providers/Locale/Locale.hooks';

import { Dialog } from './Dialog';
import { StyledLocale } from './Locale.styled';

export const Locale: FC = () => {
  const [open, setOpen] = useState(false);

  const { locale, supportedLocales } = useLocale();
  const { isOpen, closeSidebar } = useSidebarContext();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    closeSidebar();
  };

  if (supportedLocales.length === 1) {
    return null;
  }

  return (
    <>
      <StyledLocale
        onClick={handleClickOpen}
        isSidebarOpen={isOpen}
        startIcon={<LanguageIcon color='action' />}
        endIcon={<KeyboardArrowDownIcon color='action' />}
        disableRipple
      >
        {locale}
      </StyledLocale>
      <Dialog fullScreen open={open} onClose={handleClose} />
    </>
  );
};
