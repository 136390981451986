import { useState, useLayoutEffect } from 'react';

interface Size {
  width: number;
  height: number;
}

export const useElementSizeById = (id: string) => {
  const [elementSize, setElementSize] = useState<Size>({
    width: 0,
    height: 0
  });

  useLayoutEffect(() => {
    const element = document.getElementById(id);

    if (!element) {
      return undefined;
    }

    const observer = new ResizeObserver(([entry]) => {
      if (entry.borderBoxSize[0]) {
        return setElementSize({
          width: entry.borderBoxSize[0].inlineSize,
          height: entry.borderBoxSize[0].blockSize
        });
      }

      return setElementSize(entry.contentRect);
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [id]);

  return elementSize;
};
