import { FC, PropsWithChildren, useMemo, useEffect } from 'react';

import { useFavicon } from 'shared/hooks/useFavicon';
import { useGetFormattedSettings } from 'shared/hooks/useGetFormattedSettings';
import { useDocumentTitle } from 'shared/hooks/useDocumentTitle';
import { Preview } from 'shared/components/Layout';
import { Error } from 'shared/components/Error';
import { ErrorType } from 'shared/utils/axiosInstance';
import { InspiredLibrariesApiExceptionHandlingErrorResponse } from 'shared/models/payments';
import { useFilterSessionStorage } from 'shared/components/Filter/hooks';

import SettingsContext from './Settings.context';
import { useAuth } from '../Auth';

const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { error, isLoading: authLoading, isLoggedIn } = useAuth();
  const {
    favIconUrl,
    schoolName,
    defaultAcademicYear,
    isLoading: settingsLoading,
    error: settingsError
  } = useGetFormattedSettings();
  const { storedValue, setStoredValue } = useFilterSessionStorage();

  useFavicon(favIconUrl);
  useDocumentTitle(schoolName);

  // setting default academic year if no value in session storage
  useEffect(() => {
    if (!storedValue?.academicYear && defaultAcademicYear) {
      const filtersCopy = { ...storedValue };

      filtersCopy.academicYear = defaultAcademicYear;

      setStoredValue(filtersCopy);
    }
  }, [defaultAcademicYear, storedValue, setStoredValue]);

  const content = useMemo(() => {
    if (settingsError || error) {
      const combinedError = (settingsError ||
        error) as ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

      return <Error error={combinedError} />;
    }

    if (authLoading || settingsLoading || !isLoggedIn) {
      return <Preview />;
    }

    return children;
  }, [settingsError, error, authLoading, settingsLoading, isLoggedIn, children]);

  return <SettingsContext.Provider value={null}>{content}</SettingsContext.Provider>;
};

export default SettingsProvider;
