import { createContext } from 'react';

import { ErrorType } from 'shared/utils/axiosInstance';
import {
  InspiredLibrariesApiExceptionHandlingErrorResponse,
  InspiredPaymentPortalAuthWebModelsMasqueradingDetails
} from 'shared/models/auth';

export interface AuthContext {
  logout: () => void;
  isLoading: boolean;
  isLoggedIn: boolean;
  isLogOutLoading: boolean;
  postLoginStartAndRedirect: () => Promise<void>;
  error: ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse> | null;
  actAsUser?: InspiredPaymentPortalAuthWebModelsMasqueradingDetails;
}

export default createContext<AuthContext | null>(null);
