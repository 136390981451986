import { FC, memo, useMemo } from 'react';

import { List } from '@mui/material';

import { useSidebarItems } from 'shared/hooks/useSidebarItems';
import { useGetFormattedSettings } from 'shared/hooks/useGetFormattedSettings';

import { NavItem } from '../NavItem';

const NavListComponent: FC = () => {
  const { disabledRoutes } = useGetFormattedSettings();
  const sidebarItems = useSidebarItems();

  const filteredNavItems = useMemo(
    () =>
      sidebarItems
        .filter(navItem => !disabledRoutes.includes(navItem.to))
        .map(item => <NavItem key={item.to} {...item} />),
    [sidebarItems, disabledRoutes]
  );

  return (
    <List component='nav' disablePadding>
      {filteredNavItems}
    </List>
  );
};

export const NavList = memo(NavListComponent);
