import { FC } from 'react';

import { WarningAmberOutlined as WarningAmberOutlinedIcon } from '@mui/icons-material';
import { Box } from '@mui/material';

import { useIntl } from 'shared/components/Intl';
import { useAuth } from 'shared/providers/Auth';

import { StyledActing } from './Acting.styled';
import { StyledLoadingButton } from './StyledLoadingButton';
import { ACTING_ELEMENT_ID } from './Acting.constants';

export const Acting: FC = () => {
  const { formatMessage } = useIntl();

  const { actAsUser, isLogOutLoading, logout } = useAuth();

  if (!actAsUser) {
    return null;
  }

  return (
    <StyledActing id={ACTING_ELEMENT_ID}>
      <Box>
        <WarningAmberOutlinedIcon />
        {formatMessage({ id: 'acting.title' }, { name: actAsUser.name })}
      </Box>
      <StyledLoadingButton loading={isLogOutLoading} onClick={logout}>
        {formatMessage({ id: 'acting.stopActing' })}
      </StyledLoadingButton>
    </StyledActing>
  );
};
