import { styled, Box } from '@mui/material';

export const StyledActing = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1.5, 2)};
  background-color: ${({ theme }) => theme.palette.warning.main};
  color: ${({ theme }) => theme.palette.common.white};
  z-index: ${({ theme }) => theme.zIndex.snackbar};

  .MuiBox-root {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1.5)};
    font-weight: 600;
  }

  .MuiButtonBase-root {
    padding: ${({ theme }) => theme.spacing(0.5, 1.25)};
    background-color: ${({ theme }) => theme.palette.grey['300']};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    color: ${({ theme }) => theme.palette.text.primary};
    font-weight: 600;
    font-size: ${({ theme }) => theme.spacing(1.625)};
    line-height: ${({ theme }) => theme.spacing(2.75)};
  }
`;
