import { Box, styled } from '@mui/system';

export const StyledProfile = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: ${({ theme }) => theme.spacing(3)};
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-right: ${({ theme }) => theme.spacing(2)};
  }

  & > .MuiAvatar-root {
    height: ${({ theme }) => theme.spacing(3)};
    width: ${({ theme }) => theme.spacing(3)};
    background: transparent;
    color: inherit;
  }
`;
