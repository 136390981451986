import { FC, memo } from 'react';

import { useSidebarContext } from 'shared/providers/Sidebar';
import { useGetFormattedSettings } from 'shared/hooks/useGetFormattedSettings';

import { StyledLogoHolder } from './LogoHolder.styled';

const LogoHolderComponent: FC = () => {
  const { isOpen } = useSidebarContext();
  const { logoUrl, parentPortalUrl } = useGetFormattedSettings();

  return (
    <StyledLogoHolder isSidebarOpen={isOpen} href={parentPortalUrl}>
      <img src={logoUrl} alt='Logo' />
    </StyledLogoHolder>
  );
};

export const LogoHolder = memo(LogoHolderComponent);
