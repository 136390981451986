import { useMemo } from 'react';

import { SIDEBAR_ITEMS, ROUTES } from 'shared/constants';
import { useGetFormattedSettings } from 'shared/hooks/useGetFormattedSettings';

export const useSidebarItems = () => {
  const { reEnrolment } = useGetFormattedSettings();

  return useMemo(
    () =>
      SIDEBAR_ITEMS.map(item => ({
        ...item,
        endAdornment: reEnrolment?.hasPendingContracts ? item.endAdornment : undefined
      })).filter(item => (item.to === ROUTES.RE_ENROLMENT ? reEnrolment?.isEnabled && item : item)),
    [reEnrolment]
  );
};
