import { Menu, MenuProps, styled } from '@mui/material';

export const StyledMenu = styled(Menu)<MenuProps>`
  top: ${({ theme }) => theme.spacing(1)};

  .MuiList-root {
    min-width: 200px;
  }

  .MuiTypography-root {
    font-size: 0.875rem;
    letter-spacing: ${({ theme }) => theme.spacing(0.02125)};
  }
`;
