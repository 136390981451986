import { FC, ReactNode, useCallback, useMemo, MouseEvent } from 'react';

import { ListItemIcon, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useSidebarContext } from 'shared/providers/Sidebar';
import { useDeviceSize } from 'shared/hooks/useDeviceSize';

import { StyledNavItem } from './NavItem.styled';
import { NAV_ITEM_TEST_ID } from './NavItem.constants';

export interface NavItemProps {
  name: ReactNode;
  to: string;
  icon: ReactNode;
  tooltipText?: ReactNode;
  endAdornment?: ReactNode;
}

export const NavItem: FC<NavItemProps> = ({ name, to, icon, tooltipText, endAdornment }) => {
  const { pathname } = useLocation();

  const { isOpen, closeSidebar } = useSidebarContext();
  const { isMobile, isDesktop } = useDeviceSize();

  const tooltipOptions = useMemo(() => {
    return !isMobile && isOpen && tooltipText
      ? {
          disableFocusListener: true,
          disableHoverListener: true,
          disableInteractive: true,
          disableTouchListener: true
        }
      : null;
  }, [isMobile, isOpen, tooltipText]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (isDesktop) {
        closeSidebar();
      }

      if (pathname.endsWith(to)) {
        e.preventDefault();
      }
    },
    [isDesktop, closeSidebar, pathname, to]
  );

  return (
    <StyledNavItem data-testid={NAV_ITEM_TEST_ID} to={to} onClick={handleClick}>
      <Tooltip enterTouchDelay={0} title={tooltipText} placement='right' {...tooltipOptions}>
        <ListItemButton>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={name} />
          {endAdornment}
        </ListItemButton>
      </Tooltip>
    </StyledNavItem>
  );
};
