import { FC, useCallback } from 'react';

import { Grid, Typography } from '@mui/material';

import { InspiredLibrariesApiExceptionHandlingErrorResponse } from 'shared/models/payments';
import { ErrorType } from 'shared/utils/axiosInstance';
import { Button } from 'shared/components/Button';

import { StyledGrid } from './Grid.styled';
import { Warning } from '../Warning';

interface ErrorProps {
  error: ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;
}

export const Error: FC<ErrorProps> = ({ error }) => {
  const handleClick = useCallback(() => window.location.reload(), []);

  return (
    <StyledGrid container>
      <Grid item xs={12}>
        <Warning>
          <Typography>{error?.status}</Typography>
          <Typography>{error?.title}</Typography>
          <Typography>{error?.detail}</Typography>
          <Button variant='contained' onClick={handleClick}>
            Refresh
          </Button>
        </Warning>
      </Grid>
    </StyledGrid>
  );
};
