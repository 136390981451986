import { FC } from 'react';

import { Skeleton, Box } from '@mui/material';

import { useDeviceSize } from 'shared/hooks/useDeviceSize';
import { headerDesktopHeight, openSidebarWidth } from 'shared/constants';

import { StyledPreview } from './Preview.styled';
import { PREVIEW_TEST_ID } from './Preview.constants';

export const Preview: FC = () => {
  const { isDesktop, isLargeDesktop } = useDeviceSize();

  const iconSize = 30;
  const roundIconSize = 25;

  return (
    <StyledPreview data-testid={PREVIEW_TEST_ID}>
      <Box
        width='100%'
        height={headerDesktopHeight}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        <Box display='flex' gap={3} pl={2}>
          {isLargeDesktop && (
            <Box mr={10}>
              <Skeleton animation='wave' variant='rounded' width={100} height={iconSize} />
            </Box>
          )}
          <Skeleton animation='wave' variant='rounded' width={iconSize} height={iconSize} />
          <Skeleton animation='wave' variant='rounded' width={150} height={iconSize} />
        </Box>
        <Box display='flex' gap={2} pr={2}>
          {isLargeDesktop && (
            <Skeleton animation='wave' variant='rounded' width={120} height={roundIconSize} />
          )}
          <Skeleton
            animation='wave'
            variant='circular'
            width={roundIconSize}
            height={roundIconSize}
          />
          <Skeleton
            animation='wave'
            variant='circular'
            width={roundIconSize}
            height={roundIconSize}
          />
        </Box>
      </Box>
      {!isDesktop && (
        <Box display='flex' flexDirection='column' gap={2} width={openSidebarWidth} pt={2}>
          <Box display='flex' alignItems='center' mx={2}>
            <Skeleton animation='wave' variant='rounded' width='100%' height={2 * iconSize} />
          </Box>
          <Box display='flex' gap={2} ml={3}>
            <Skeleton animation='wave' variant='rounded' width={iconSize} height={iconSize} />
            <Skeleton animation='wave' variant='rounded' width={150} height={iconSize} />
          </Box>
          <Box display='flex' gap={2} ml={3}>
            <Skeleton animation='wave' variant='rounded' width={iconSize} height={iconSize} />
            <Skeleton animation='wave' variant='rounded' width={80} height={iconSize} />
          </Box>
          <Box display='flex' gap={2} ml={3}>
            <Skeleton animation='wave' variant='rounded' width={iconSize} height={iconSize} />
            <Skeleton animation='wave' variant='rounded' width={100} height={iconSize} />
          </Box>
          <Box display='flex' gap={2} ml={3}>
            <Skeleton animation='wave' variant='rounded' width={iconSize} height={iconSize} />
            <Skeleton animation='wave' variant='rounded' width={130} height={iconSize} />
          </Box>
          <Box display='flex' gap={2} ml={3}>
            <Skeleton animation='wave' variant='rounded' width={iconSize} height={iconSize} />
            <Skeleton animation='wave' variant='rounded' width={110} height={iconSize} />
          </Box>
          <Box display='flex' flexDirection='column' flexGrow={1} justifyContent='flex-end' p={2}>
            <Skeleton animation='wave' variant='rounded' width='100%' height={iconSize} />
          </Box>
        </Box>
      )}
    </StyledPreview>
  );
};
