import { FC, PropsWithChildren, useMemo } from 'react';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import { useGetFormattedSettings } from 'shared/hooks/useGetFormattedSettings';
import { getTheme } from 'styles/theme';

const ThemeContainerProvider: FC<PropsWithChildren> = ({ children }) => {
  const { colors } = useGetFormattedSettings();

  const theme = useMemo(() => getTheme(colors), [colors]);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeContainerProvider;
