import { useIntl as useReactIntl, MessageDescriptor } from 'react-intl';

import { useLocale } from 'shared/providers/Locale';

import type { FormatMessageArgs, MessageFormatPrimitiveValue } from './Intl.types';
import { findFallbackMessage } from './Intl.helpers';

export const useIntl = () => {
  const { formatMessage, ...rest } = useReactIntl();

  const { translations, fallbackSettings, locale } = useLocale();

  const customFormatMessage = (
    descriptor: MessageDescriptor & {
      defaultMessage?: string;
    },
    values?: Record<string, MessageFormatPrimitiveValue>,
    options?: FormatMessageArgs[2]
  ) => {
    const defaultMessage = findFallbackMessage({
      id: descriptor.id,
      defaultLocale: locale,
      fallback: fallbackSettings ? fallbackSettings[locale] : undefined,
      translations
    });

    return formatMessage({ ...descriptor, defaultMessage }, values, options);
  };

  return {
    ...rest,
    formatMessage: customFormatMessage
  };
};
