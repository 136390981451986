import { Alert, AlertProps, styled } from '@mui/material';

export const StyledAlert = styled(Alert)<AlertProps>`
  display: flex;
  align-items: center;
  min-width: ${({ theme }) => theme.spacing(40)};
  max-width: ${({ theme }) => theme.spacing(65)};
  padding: ${({ theme }) => theme.spacing(1.25, 2)};

  .MuiIconButton-root {
    .MuiSvgIcon-root {
      font-size: 1.5rem;
    }
  }

  .MuiAlert-action {
    align-self: flex-start;
  }

  .MuiAlert-icon {
    padding: 0;
    margin-right: ${({ theme }) => theme.spacing(1.75)};

    .MuiSvgIcon-root {
      font-size: 1.375rem;
    }
  }

  .MuiAlert-action {
    padding: 0;
    margin-left: ${({ theme }) => theme.spacing(4)};
    font-size: 1.5rem;
  }

  .MuiAlert-message {
    flex: 1 auto;
    padding-top: 0;
    padding-bottom: 0;
  }
`;
