import { styled } from '@mui/material';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { transparentize } from 'polished';

export const StyledNavItem = styled(NavLink)<NavLinkProps>`
  display: flex;
  text-decoration: none;
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  transition: ${({ theme }) =>
    theme.transitions.create('background-color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })};

  .MuiListItemText-root {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .MuiTouchRipple-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }

  &.active {
    background-color: ${({ theme }) => transparentize(0.92, theme.palette.primary.main)};
  }
`;
