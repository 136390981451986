import { Button, ButtonProps, styled } from '@mui/material';

export const StyledLocale = styled(Button)<ButtonProps>`
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  .MuiButton-startIcon,
  .MuiButton-endIcon {
    > .MuiSvgIcon-root {
      font-size: ${({ theme }) => theme.spacing(3)};
    }
  }
`;
