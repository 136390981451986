import {
  DashboardOutlined as DashboardOutlinedIcon,
  RequestQuoteOutlined as RequestQuoteOutlinedIcon,
  TaskOutlined as TaskOutlinedIcon,
  CreditCardOutlined as CreditCardOutlinedIcon,
  AccountBalanceOutlined as AccountBalanceOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  Info as InfoIcon
} from '@mui/icons-material';

import { FormattedMessage } from 'shared/components/Intl';
import { NavItemProps } from 'shared/components/Layout/Sidebar/NavItem/NavItem';
import { InfoTooltip } from 'shared/components/InfoTooltip';

import { ROUTES } from './index';

export const SIDEBAR_ITEMS: NavItemProps[] = [
  {
    name: <FormattedMessage id='accountSummary.title' />,
    to: ROUTES.HOME,
    icon: <DashboardOutlinedIcon />,
    tooltipText: <FormattedMessage id='accountSummary.title' />
  },
  {
    name: <FormattedMessage id='invoices.title' />,
    to: ROUTES.INVOICES,
    icon: <RequestQuoteOutlinedIcon />,
    tooltipText: <FormattedMessage id='invoices.title' />
  },
  {
    name: <FormattedMessage id='payments.title' />,
    to: ROUTES.PAYMENTS,
    icon: <TaskOutlinedIcon />,
    tooltipText: <FormattedMessage id='payments.title' />
  },
  // TODO: uncomment when page will be ready
  // {
  //   name: <FormattedMessage id='statements.title' />,
  //   to: ROUTES.STATEMENTS,
  //   icon: <SourceOutlined />,
  //   tooltipText: <FormattedMessage id='statements.title' />
  // },
  {
    name: <FormattedMessage id='makePayment.title' />,
    to: ROUTES.MAKE_PAYMENT,
    icon: <CreditCardOutlinedIcon />,
    tooltipText: <FormattedMessage id='makePayment.title' />
  },
  {
    name: <FormattedMessage id='directDebit.title' />,
    to: ROUTES.DIRECT_DEBIT,
    icon: <AccountBalanceOutlinedIcon />,
    tooltipText: <FormattedMessage id='directDebit.title' />
  },
  {
    name: <FormattedMessage id='reEnrolment.title' />,
    to: ROUTES.RE_ENROLMENT,
    icon: <DescriptionOutlinedIcon />,
    tooltipText: <FormattedMessage id='reEnrolment.title' />,
    endAdornment: (
      <InfoTooltip title={<FormattedMessage id='reEnrolmentActionRequired.title' />}>
        <InfoIcon sx={{ color: 'info.main' }} />
      </InfoTooltip>
    )
  }
];
