import { Dispatch, SetStateAction, useState, useEffect, useCallback } from 'react';

type SetValue<T> = Dispatch<SetStateAction<T>>;

export const useSessionStorage = <T>(key: string, initialValue: T): [T, SetValue<T>] => {
  const readValue = useCallback(() => {
    try {
      const jsonValue = window.sessionStorage.getItem(key);

      if (typeof initialValue === 'function') {
        return initialValue();
      }

      return jsonValue ? JSON.parse(jsonValue) : initialValue;
    } catch (_) {
      return initialValue;
    }
  }, [initialValue, key]);

  const [value, setValue] = useState<T>(readValue);

  useEffect(() => {
    value
      ? window.sessionStorage.setItem(key, JSON.stringify(value))
      : window.sessionStorage.removeItem(key);
  }, [key, value]);

  return [value, setValue];
};
