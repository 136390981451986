import type { FindFallbackMessageProps } from './Intl.types';

export const findFallbackMessage = ({
  id,
  defaultLocale,
  translations,
  fallback
}: FindFallbackMessageProps) => {
  if (!translations || !fallback || !id) {
    return '';
  }

  const message = translations.find(tr => tr.locale === fallback.locale)?.tokens[id] || '';

  if (!message && fallback.fallback) {
    findFallbackMessage({ id, defaultLocale, translations, fallback: fallback.fallback });
  }

  return message;
};
