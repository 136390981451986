export const FullyPaidIcon = () => {
  return (
    <svg width='273' height='206' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#a)'>
        <path
          d='M261.708 112.226a4.944 4.944 0 0 1-5.617 4.162 4.941 4.941 0 0
          1-4.167-5.614c1.035-6.95-5.322-20.591-18.714-26.836-.003-.003-.006-.006-.009-.006-7.578-3.535-15.789-4.04-23.939-2.688-.026.007-.052.01-.078.013-6.823 1.142-13.605 3.58-19.939
           6.623a4.95 4.95 0 0 1-6.601-2.312 4.95 4.95 0 0 1 2.32-6.6c6.523-3.134 13.45-5.657 20.509-7.057l.004-.004c8.486-1.684 17.16-1.752 25.556.686.084 0 1.718.524 2.449.786h.004c19.105 6.652 30.108 26.163 28.222 38.847Z'
          fill='#F1F4F5'
        />
        <path
          d='M209.236 81.242a.096.096 0 0 0-.052.016c-6.823 1.142-13.605 3.58-19.94 6.624a4.949 4.949 0 0 1-6.6-2.313 4.95 4.95 0 0 1 2.32-6.6c6.522-3.134 13.449-5.657 20.509-7.057-3.514 1.798-1.362 8.984 3.763 9.33Z'
          fill='#1B75BB'
        />
        <path
          d='M187.114 89.175a5.772 5.772 0 0 1-5.199-3.255 5.766 5.766 0 0 1 2.698-7.68c7.091-3.407 14.056-5.803 20.702-7.121a.81.81 0 0 1
           .921.528.807.807 0 0 1-.394.985c-1.262.645-1.438 2.205-1.182 3.45.406 1.973 2.029 4.178 4.63 4.353a.808.808 0 0 1 .158
            1.587.843.843 0 0 1-.13.033c-6.189 1.036-12.825 3.241-19.723 6.555a5.726 5.726 0 0 1-2.481.565Zm16.164-15.96c-5.798
             1.36-11.83 3.535-17.964 6.482a4.146 4.146 0 0 0-1.941 5.522 4.15 4.15 0 0 0 5.521 1.933c5.971-2.868 11.76-4.924
              17.249-6.129-1.668-1.106-2.72-2.927-3.067-4.616-.244-1.184-.163-2.282.202-3.193Z'
          fill='#1B75BB'
        />
        <path
          d='M256.832 117.252c-.304 0-.587-.021-.866-.065a5.721 5.721 0 0 1-3.773-2.262 5.704 5.704 0 0 1-1.069-4.27c1.016-6.822-5.437-20.007-18.255-25.984a.69.69
           0 0 1-.047-.024c-6.822-3.172-14.697-4.05-23.407-2.609a1.603 1.603 0 0 1-.113.02c-6.184 1.036-12.814 3.24-19.707 6.551a5.77 5.77 0 0
            1-7.679-2.69 5.765 5.765 0 0 1 2.698-7.68c7.07-3.397 14.016-5.79 20.645-7.11l.06-.014c9.271-1.84 17.998-1.605 25.94.702l-.001.005c.631.147
             2.184.686 2.457.781a.884.884 0 0 1 .037.012c8.96 3.12 16.911 9.326 22.389 17.475 4.962 7.382 7.342 15.701 6.368 22.255a5.731 5.731
              0 0 1-5.599 4.906h-.077l-.001.001ZM233.58 83.218c6.132 2.867 11.488 7.642 15.085 13.448 3.119 5.035 4.636 10.354 4.059 14.227a4.111
               4.111 0 0 0 .769 3.069 4.12 4.12 0 0 0 2.717 1.627 4.118 4.118 0 0 0 4.698-3.482c.917-6.165-1.367-14.058-6.11-21.115-5.276-7.85-12.926-13.828-21.543-16.837a50.684
                50.684 0 0 0-2.356-.764.78.78 0 0 1-.091-.021c-7.674-2.23-16.124-2.458-25.117-.68l-.06.014c-6.513 1.292-13.348 3.645-20.316 6.992a4.146 4.146
                0 0 0-1.941 5.523 4.149 4.149 0 0 0 5.521 1.932c7.035-3.38 13.817-5.63 20.156-6.691l.036-.006.043-.007c9.062-1.504 17.276-.578 24.414
                 2.752l.036.02Z'
          fill='#1B75BB'
        />
        <path
          d='M146.079 197.815c-19.124 10.463-48.54 9.138-51.86.161-2.555-6.91 5.211-15.082 11.48-19.006a79.523 79.523 0 0 1 10.671-5.648c6.483-2.822 13.362-4.717 20.167-5.126.62-.037
           1.229-.063 1.823-.072 2.235-.045 4.273.091 6.073.365 10.832 1.645 22.605 17.86 1.646 29.326Z'
          fill='#F1F4F5'
        />
        <path
          d='M115.246 206.018c-5.871 0-11.142-.79-15.017-2.239-3.588-1.342-5.929-3.251-6.768-5.522-2.772-7.495 5.543-16.05 11.808-19.972a80.298 80.298 0 0 1 10.779-5.704c7.007-3.051
           13.885-4.798 20.44-5.192a42.992 42.992 0 0 1 1.859-.074c2.195-.044 4.284.082 6.208.374 5.778.878 12.495 5.973 13.735 12.98.686 3.877.062 11.354-11.823 17.856-9.521 5.209-21.237
            7.493-31.221 7.493Zm23.947-37.095c-.27 0-.543.003-.817.009-.54.008-1.125.031-1.791.07-6.365.384-13.058 2.086-19.893 5.061a78.706 78.706 0 0 0-10.562 5.591c-5.597 3.503-13.571
             11.499-11.152 18.042.672 1.816 2.684 3.396 5.818 4.569 10.173 3.804 30.642 2.638 44.895-5.159 8.151-4.459 12.06-10.197 11.006-16.156-1.113-6.292-7.171-10.87-12.385-11.662a34.256 34.256 0 0 0-5.119-.364v-.001Z'
          fill='#1B75BB'
        />
        <path
          d='M138.36 168.123a397.21 397.21 0 0 0-2.575 4.35c-4.376-1.737-11.463-1.587-19.415.849 6.483-2.823 13.362-4.717 20.167-5.127a42.75 42.75 0 0 1 1.823-.072Z'
          fill='#1B75BB'
        />
        <path
          d='M116.37 174.131a.807.807 0 0 1-.793-.644.808.808 0 0 1 .47-.906c7.009-3.051 13.886-4.798 20.442-5.193a41.281 41.281 0 0 1 1.859-.073.807.807 0 0 1 .706 1.224 386.547 386.547
           0 0 0-2.57 4.342.811.811 0 0 1-.997.344c-4.374-1.737-11.432-1.412-18.88.87a.81.81 0 0 1-.237.035v.001Zm12.243-3.619c2.576 0 4.894.321 6.815.97.495-.845.988-1.68 1.475-2.497l-.318.017c-3.184.192-6.45.714-9.775 1.56.6-.033 1.202-.05 1.803-.05Z'
          fill='#1B75BB'
        />
        <path
          d='M157.251 107.819c-1.167 3.664-2.343 7.805-3.473 12.259-4.404 17.369-8.136 39.426-8.162 55.901-4.216-.097-8.063-.795-9.847-2.883.002-.115-.003.115 0 0 .101-4.006.383-8.273.817-12.675
           1.358-13.843 4.191-29.051 7.397-41.905 1.246-4.994 2.551-9.632 3.848-13.699a4.943 4.943 0 0 1 7.604-2.507 4.928 4.928 0 0 1 1.816 5.509Z'
          fill='#F1F4F5'
        />
        <path
          d='M145.624 175.768h-.009a.813.813 0 0 1-.8-.817c.22-20.907 6.036-49.721 11.667-67.377a.821.821 0 0 1 .4-.473.802.802 0 0 1 .893.1.82.82 0 0 1 .249.864c-5.595 17.541-11.373 46.155-11.591
           66.903a.808.808 0 0 1-.809.8Zm-9.839-2.485h-.024a.819.819 0 0 1-.565-.253.805.805 0 0 1-.22-.579c.637-21.688 6.56-50.565 12.083-67.88a.81.81 0 0 1 1.541.491c-5.488 17.208-11.374 45.897-12.007 67.437a.808.808 0 0 1-.808.784Z'
          fill='#1B75BB'
        />
        <path
          d='M157.251 107.819c-1.167 3.664-2.343 7.805-3.473 12.259 1.317-4.999-8.389-5.6-9.795-1.562 1.246-4.994 2.551-9.632 3.848-13.699a4.943 4.943 0 0 1 6.213-3.209 4.94 4.94 0 0 1 3.207 6.211Z'
          fill='#1B75BB'
        />
        <path
          d='M153.779 120.887a.815.815 0 0 1-.639-.312.797.797 0 0 1-.153-.331.8.8 0 0 1 .007-.364l.017-.067c.441-1.815-1.097-2.577-2.061-2.871-2.278-.694-5.553-.029-6.203 1.84a.813.813 0 0
           1-.994.504.807.807 0 0 1-.555-.965c1.246-4.997 2.546-9.623 3.862-13.749a5.762 5.762 0 0 1 7.23-3.734 5.758 5.758 0 0 1 3.732 7.227c-1.172 3.678-2.33 7.761-3.441 12.137l-.02.082a.81.81 0 0
            1-.782.603Zm-4.793-5.843c.834 0 1.667.117 2.436.351 1.014.31 1.814.796 2.372 1.413a203.253 203.253 0 0 1 2.687-9.234 4.137 4.137 0 0 0-2.682-5.195 4.142 4.142 0 0 0-5.197 2.684 210.87 210.87 0 0 0-3.093 10.747c1.021-.511 2.25-.766 3.477-.766Z'
          fill='#1B75BB'
        />
        <path
          d='M154.733 155.946c-7.402 8.388-17.069 12.719-26.136 12.43-4.886-.157-9.594-1.658-13.684-4.587-6.885-4.927-11.792-13.965-11.143-27.096.24-4.934 1.266-10.451 3.268-16.54.004-.004.004-.007.004-.011
           2.017-6.131 5.022-12.848 9.208-20.153a4.943 4.943 0 0 1 6.749-1.829 4.94 4.94 0 0 1 1.829 6.746c-3.284 5.727-5.739 10.982-7.502 15.775-6.913 18.779-3.167 30.409 3.345 35.073 2.28 1.631 5.168 2.593
            8.345 2.695 5.762.186 12.837-2.437 18.654-9.029 1.805-2.047 4.578-2.255 6.627-.451a4.937 4.937 0 0 1 1.492 2.383 4.93 4.93 0 0 1-1.056 4.594Z'
          fill='#F1F4F5'
        />
        <path
          d='M124.829 104.907c-3.284 5.726-5.74 10.981-7.502 15.774l-.047.119c1.506-4.501-8.258-6.045-10.238-.658 2.018-6.131 5.023-12.848 9.209-20.153A4.943 4.943 0 0 1 123 98.16a4.943 4.943 0 0 1 1.829 6.747Z'
          fill='#1B75BB'
        />
        <path
          d='M117.28 121.61a.807.807 0 0 1-.766-1.065c.553-1.655-1.082-2.428-1.811-2.683-2.213-.776-5.844-.318-6.901 2.56a.808.808 0 0 1-1.528-.531c2.112-6.418 5.233-13.249 9.275-20.303a5.709 5.709 0 0 1 3.48-2.689 5.723 5.723
           0 0 1 4.373.562 5.695 5.695 0 0 1 2.69 3.482 5.702 5.702 0 0 1-.561 4.367c-3.125 5.45-5.63 10.716-7.445 15.652l-.053.138a.815.815 0 0 1-.752.51h-.001Zm-4.632-5.704c.889 0 1.774.144 2.591.43 1.01.354 1.79.891 2.302
            1.546 1.728-4.268 3.939-8.76 6.586-13.377a4.107 4.107 0 0 0 .404-3.139 4.11 4.11 0 0 0-1.934-2.503 4.107 4.107 0 0 0-3.144-.404 4.098 4.098 0 0 0-2.5 1.932c-3.228 5.631-5.857 11.112-7.844 16.345 1.072-.555 2.309-.83 3.539-.83Z'
          fill='#1B75BB'
        />
        <path
          d='M129.469 169.2c-.299 0-.598-.004-.897-.014-5.147-.165-10.033-1.804-14.13-4.738-5.266-3.77-8.916-9.466-10.554-16.474-1.887-8.075-1.086-17.52 2.382-28.072l.007-.021c2.112-6.415 5.231-13.242 9.272-20.293a5.705 5.705
           0 0 1 3.48-2.689 5.722 5.722 0 0 1 4.373.562 5.72 5.72 0 0 1 2.69 3.482 5.72 5.72 0 0 1-.562 4.367c-3.125 5.45-5.63 10.716-7.444 15.652-6.862 18.64-2.921 29.856 3.056 34.136 2.193 1.569 4.925 2.449 7.9 2.545 6.362.205
            12.93-2.987 18.022-8.756 1.016-1.153 2.355-1.842 3.772-1.942 1.425-.099 2.844.404 3.996 1.419a5.72 5.72 0 0 1 1.937 3.955 5.719 5.719 0 0 1-1.429 4.163c-7.164 8.119-16.547 12.718-25.871 12.718Zm-21.665-48.784c-6.211
             18.905-3.377 34.874 7.58 42.717 3.836 2.747 8.414 4.282 13.24 4.437.281.009.564.013.846.013 8.859.001 17.8-4.401 24.656-12.17a4.111 4.111 0 0 0 1.027-2.993 4.095 4.095 0 0 0-1.392-2.842c-.824-.726-1.823-1.088-2.813-1.019-.989.069-1.938.566-2.672
              1.398-5.412 6.132-12.437 9.524-19.286 9.303-3.297-.106-6.336-1.09-8.79-2.846-2.608-1.868-5.946-5.602-7.036-12.779-.997-6.571.147-14.387 3.403-23.23 1.846-5.021 4.389-10.37 7.559-15.898a4.096 4.096 0 0 0 .404-3.14 4.105 4.105 0 0
               0-1.933-2.502 4.11 4.11 0 0 0-3.144-.405 4.096 4.096 0 0 0-2.501 1.932c-3.988 6.96-7.064 13.69-9.142 20.005l-.007.02.001-.001Z'
          fill='#1B75BB'
        />
        <path
          d='M70.01 88.519c-6.271 4.323-12.454 7.123-18.33 8.637-24.565 6.334-43.742-9.84-41.31-31.586.399-3.594 1.388-7.338 3.042-11.156a4.95 4.95 0 0 1 6.504-2.575 4.941 4.941 0 0 1 2.575 6.498c-8.785 20.301 7.538 36.228 30.092 28.211
           3.79-1.347 7.76-3.37 11.812-6.165a4.945 4.945 0 0 1 6.879 1.26 4.943 4.943 0 0 1-1.264 6.876Z'
          fill='#F1F4F5'
        />
        <path
          d='M70.01 88.518c-6.271 4.323-12.454 7.124-18.33 8.637 4.982-1.4 5.895-10.217.903-10.607 3.79-1.348 7.76-3.371 11.812-6.166a4.943 4.943 0 1 1 5.615 8.136Z'
          fill='#1B75BB'
        />
        <path
          d='M51.68 97.965a.81.81 0 0 1-.22-1.587c2.53-.711 3.842-3.725 3.607-6.11-.08-.822-.49-2.752-2.547-2.913a.81.81 0 0 1-.208-1.568c3.868-1.375 7.779-3.417 11.623-6.07a5.716 5.716 0 0 1 4.307-.924 5.713 5.713 0 0 1
           3.698 2.391 5.76 5.76 0 0 1-1.47 8.001c-6.095 4.202-12.35 7.147-18.588 8.754a.807.807 0 0 1-.203.026Zm3.17-11.42c1.015.756 1.673 1.996 1.827 3.565.164 1.668-.253 3.531-1.2 5.045 4.724-1.662 9.442-4.108 14.073-7.301a4.141
            4.141 0 0 0 1.057-5.752 4.106 4.106 0 0 0-2.657-1.718 4.107 4.107 0 0 0-3.096.665c-3.304 2.278-6.66 4.122-10.004 5.496Z'
          fill='#1B75BB'
        />
        <path
          d='M41.089 99.354c-8.108 0-15.395-2.472-21.127-7.18-5.101-4.188-8.533-9.838-9.925-16.338-1.466-6.854-.556-14.374 2.634-21.745a5.715 5.715 0 0 1 3.162-3.065 5.718 5.718 0 0 1 4.403.07 5.757 5.757 0 0 1 2.997 7.562c-4.2
           9.704-2.641 19.154 4.068 24.664 6.61 5.428 20.17 7.74 36.635-3.608a5.717 5.717 0 0 1 4.306-.922 5.72 5.72 0 0 1 3.697 2.392 5.757 5.757 0 0 1-1.47 8c-9.312 6.418-19.13 9.93-28.393 10.158-.33.008-.66.012-.987.012ZM17.952
            52.237c-.521 0-1.038.1-1.523.293a4.11 4.11 0 0 0-2.273 2.202C8.133 68.651 10.75 82.52 20.989 90.925c12.227 10.04 31.288 8.833 48.562-3.072a4.138 4.138 0 0 0-.1-6.877 4.112 4.112 0 0 0-1.5-.593 4.11 4.11 0 0 0-3.096.662c-7.346
             5.063-14.958 7.959-22.014 8.373-6.418.377-12.301-1.344-16.567-4.847-7.29-5.986-9.024-16.161-4.526-26.555a4.139 4.139 0 0 0-3.796-5.78Z'
          fill='#1B75BB'
        />
        <path
          d='M203.272 107.508c-2.68-33.45-3.784-66.754-3.325-99.8.016-1.17-.497-2.182-1.314-2.832l.002-.001-3.86-3.3-3.478 2.9c-43.25 3.066-85.43 6.275-128.569 9.363a3.536 3.536 0 0 0-3.285 3.555c.28 31.132.925 62.334 3.915 93.766l-2.371
           1.977 3.833 3.275.055.046.063.054.002-.002c.67.54 1.513.817 2.372.778a308373.1 308373.1 0 0 1 132.598-5.966 3.534 3.534 0 0 0 3.362-3.814v.001Z'
          fill='#F1F4F5'
        />
        <path
          d='M67.15 118.099a4.328 4.328 0 0 1-2.642-.904.766.766 0 0 1-.095-.07l-.064-.055a.71.71 0 0 1-.055-.045l-3.834-3.274a.816.816 0 0 1-.283-.62.804.804 0 0 1 .291-.616l2.046-1.704c-2.79-29.525-3.569-58.712-3.879-93.413a4.35 4.35 0 0
           1 4.035-4.367c20.295-1.452 40.713-2.955 60.458-4.41 22.142-1.63 45.034-3.317 67.85-4.937l3.278-2.733a.809.809 0 0 1 1.044.007l3.859 3.302c1.025.813 1.616 2.08 1.599 3.456a1084.86 1084.86 0 0 0-.081 21.648l.002.208c.012 1.3.023
            2.602.038 3.905l.001.085c.01 1.002.019 2.008.035 3.011.055 4.014.124 7.633.211 11.065v.017a1073.705 1073.705 0 0 0 3.113 59.786 4.312 4.312 0 0 1-1.07 3.221 4.296 4.296 0 0 1-3.061 1.467h-.001l-29.396 1.316a175306.564 175306.564
             0 0 0-71.215 3.204l-.02.001-3.016.137c-1.484.065-2.969.134-4.45.202l-24.5 1.106c-.066.002-.132.004-.198.004Zm-1.751-2.26a.689.689 0 0 1 .051.039c.515.416 1.164.63 1.825.601l24.5-1.106c1.481-.068 2.968-.137 4.45-.202l3.032-.138h.022a167627.172
              167627.172 0 0 1 71.198-3.204l13.612-.609 15.784-.707a2.7 2.7 0 0 0 1.921-.92 2.697 2.697 0 0 0 .671-2.023 1075.545 1075.545 0 0 1-3.118-59.89v-.015a948.144 948.144 0 0 1-.246-14.089l-.001-.082c-.015-1.303-.026-2.607-.038-3.908l-.002-.21a1080.18
               1080.18 0 0 1 .081-21.682c.01-.872-.358-1.67-1.01-2.188l-3.363-2.876-2.953 2.462a.813.813 0 0 1-.461.186c-22.901 1.625-45.882 3.318-68.106 4.955-19.747 1.454-40.165 2.959-60.462 4.41a2.73 2.73 0 0 0-2.532 2.74c.31 34.825 1.095 64.087 3.91
                93.699a.81.81 0 0 1-.286.698l-1.637 1.363 3.087 2.638a.961.961 0 0 1 .066.054l.006.004h-.001Z'
          fill='#1B75BB'
        />
        <path
          d='m196.037 108.012-3.891.173c-42.902 1.919-85.804 3.85-128.705 5.793a3.535 3.535 0 0 1-3.675-3.17c-2.87-28.764-3.721-57.33-4.075-85.82-.05-3.636-.09-7.271-.12-10.907a3.53 3.53 0 0 1 3.284-3.553c44.761-3.206 88.482-6.539 133.438-9.71 2.032-.146
           3.683 1.347 3.781 3.343.001.076.005.156.002.236-.05 3.608-.082 7.217-.096 10.826a1067.28 1067.28 0 0 0 3.422 88.974 3.536 3.536 0 0 1-3.365 3.815Z'
          fill='#87B4D7'
        />
        <path
          d='m196.039 108.01-50.182 2.25 23.444-27.625 27.178-31.137a1072.057 1072.057 0 0 0 2.921 52.699 3.53 3.53 0 0 1-3.361 3.813ZM89.509 22.635l-32.523 37.87c-.438-12.267-.663-24.2-.804-35.552l33.327-2.318Zm13.751-.955L57.592 74.855a651.936 651.936
           0 0 1-.236-5.034l41.055-47.804 4.849-.337Z'
          fill='#F1F4F5'
        />
        <path
          d='M195.98 15.225 55.69 24.988c-.049-3.636-.087-7.273-.119-10.905a3.533 3.533 0 0 1 3.285-3.554c44.759-3.205 88.48-6.538 133.436-9.709 2.111-.15 3.812 1.462 3.783 3.577-.049 3.609-.081 7.218-.095 10.828Z'
          fill='#F1F4F5'
        />
        <path
          d='M55.69 25.798a.81.81 0 0 1-.055-1.615l1.65-.116 61.846-4.302 76.793-5.347a.812.812 0 0 1 .782.446.812.812 0 0 1-.365 1.085.808.808 0 0 1-.305.082l-5.754.399-71.038 4.948-63.495 4.418a.86.86 0 0 1-.058.002Z'
          fill='#1B75BB'
        />
        <path
          d='M63.28 114.789a4.34 4.34 0 0 1-4.318-3.898c-3.379-33.833-3.94-67.706-4.2-96.8a4.358 4.358 0 0 1 4.037-4.37C79.04 8.271 99.406 6.771 119.1 5.32c23.848-1.755 48.508-3.572 73.134-5.31 1.25-.087 2.425.324 3.308 1.16.883.835
           1.359 1.985 1.34 3.24-.216 15.54-.079 31.374.404 47.062a1076.574 1076.574 0 0 0 2.919 52.659 4.313 4.313 0 0 1-1.076 3.228 4.292 4.292 0 0 1-3.055 1.457l-50.181 2.251c-27.501 1.236-54.973 2.474-82.416 3.716a4.373 4.373
            0 0 1-.197.005ZM192.563 1.617c-.07 0-.142.003-.213.007-24.624 1.738-49.283 3.554-73.13 5.31-19.695 1.45-40.06 2.95-60.304 4.399a2.737 2.737 0 0 0-2.536 2.744c.26 29.058.821 62.888 4.192 96.653a2.724 2.724 0 0 0 2.835
             2.439c27.442-1.242 54.914-2.48 82.415-3.716l50.181-2.251h.001a2.698 2.698 0 0 0 1.915-.913 2.713 2.713 0 0 0 .676-2.028 1076.089 1076.089 0 0 1-2.924-52.738 1061.214 1061.214 0 0 1-.405-47.136c.013-.797-.284-1.522-.834-2.043a2.686
              2.686 0 0 0-1.869-.727Z'
          fill='#1B75BB'
        />
        <path
          d='M144.31 204.919c-9.327-1.858-13.518-16.685-9.359-33.115 6.614-26.179 27.753-35.804 33.931-16.027 6.535 20.79-9.465 52.16-24.572 49.142Z'
          fill='#F1F4F5'
        />
        <path
          d='M168.721 178.524c-4.179 16.523-15.067 27.449-22.754 26.524-6.841-4.003-9.831-16.265-6.7-29.765 4.392-18.929 18.129-31.986 25.651-26.703 6.434 6.804 6.269 20.209 3.803 29.944Z'
          fill='#1B75BB'
        />
        <path
          d='M146.26 205.914a10.955 10.955 0 0 1-2.109-.205c-3.113-.619-5.815-2.62-7.816-5.786-4.111-6.507-4.942-17.358-2.169-28.319a54.862 54.862 0 0 1 2.932-8.58.809.809 0 0 1 1.481.65 53.344 53.344 0 0 0-2.844 8.326c-2.668 10.546-1.914
           20.914 1.968 27.059 1.758 2.783 4.097 4.533 6.764 5.064.31.062.615.106.932.134 8.756.819 18.655-10.572 22.54-25.932 2.035-8.042 2.098-16.173.172-22.308-1.732-5.531-4.895-9.017-8.905-9.815-5.986-1.192-13.209 3.924-18.4 13.031a.811.811
            0 0 1-1.103.302.808.808 0 0 1-.303-1.102c5.627-9.873 13.338-15.167 20.122-13.817 4.617.92 8.216 4.797 10.132 10.918 2.043 6.505 1.991 14.741-.147 23.188-3.932 15.545-13.989 27.191-23.247 27.192Z'
          fill='#1B75BB'
        />
        <path
          d='M131.532 68.492c8.04 2.738 15.892-5.58 15.321-17.284-.815-16.72-16.948-22.883-23.367-8.883-4.668 10.167-.082 23.416 8.046 26.167Z'
          fill='#F1F4F5'
        />
        <path
          d='M136.675 64.836c4.449 2.96 9.371-2.117 9.66-10.67.414-12.22-8.478-18.644-12.881-9.274-3.2 6.802-1.277 16.964 3.221 19.944Z'
          fill='#1B75BB'
        />
        <path
          d='M139.296 66.49c-1.042 0-2.086-.329-3.069-.983-4.952-3.282-6.753-14.056-3.504-20.96 1.757-3.74 4.41-5.489 7.28-4.797 3.669.883 7.418 6.245 7.141 14.441-.186 5.503-2.325 10.095-5.449 11.7-.778.4-1.588.599-2.399.599Zm-.471-25.265c-1.763
           0-3.42 1.416-4.638 4.009-3.138 6.668-1.007 16.315 2.935 18.926l.001.001c1.254.835 2.579.935 3.832.292 2.61-1.341 4.405-5.39 4.572-10.316.239-7.074-2.814-12.071-5.903-12.816a3.403 3.403 0 0 0-.799-.096Z'
          fill='#1B75BB'
        />
        <path
          d='M139.35 53.598c1.059-.04 1.873-1.28 1.817-2.771-.056-1.491-.961-2.668-2.021-2.628-1.059.04-1.873 1.281-1.817 2.772.056 1.49.961 2.667 2.021 2.627Z'
          fill='#F1F4F5'
        />
        <path
          d='M134.637 69.825c-1.119 0-2.249-.188-3.364-.568-3.558-1.204-6.682-4.407-8.571-8.788-2.559-5.933-2.54-12.842.049-18.482 2.777-6.055 7.612-9.058 12.934-8.032 5.415 1.044 11.472 6.875 11.976 17.213.332 6.82-2.177 13.1-6.549
           16.392-1.991 1.499-4.213 2.265-6.475 2.265Zm-.951-34.447c-3.87 0-7.327 2.62-9.464 7.283-2.366 5.153-2.379 11.731-.035 17.168 1.709 3.96 4.48 6.839 7.604 7.896l.002.001c2.846.97 5.81.452 8.346-1.458 3.95-2.974 6.213-8.73
            5.906-15.021-.461-9.466-5.852-14.776-10.666-15.705a8.945 8.945 0 0 0-1.693-.164Zm11.162-.826a.808.808 0 0 1-.659-.339c-3.112-4.352-8.367-6.382-13.713-5.297a.812.812 0 0 1-.954-.631.817.817 0 0 1 .118-.608.818.818 0 0 1
             .514-.346c5.981-1.214 11.863 1.063 15.351 5.942a.803.803 0 0 1 .061.84.814.814 0 0 1-.718.439Z'
          fill='#1B75BB'
        />
        <path
          d='M95.914 70.748c7.398 2.954 14.444-4.745 13.732-16.037-1.017-16.131-15.872-22.703-21.516-9.475-4.104 9.604.306 22.542 7.784 25.512Z'
          fill='#F1F4F5'
        />
        <path
          d='M99.115 72.193a9.385 9.385 0 0 1-3.502-.694c-3.284-1.304-6.2-4.524-7.995-8.833-2.415-5.797-2.502-12.432-.233-17.747 2.299-5.386 6.416-8.17 11.019-7.452l.032.004c5.489.875 11.379 7.062 12.016 17.192.357 5.646-1.161 10.786-4.163
           14.103-1.941 2.144-4.314 3.327-6.862 3.421l-.029.001a9.539 9.539 0 0 1-.282.005ZM96.972 38.97c-3.38 0-6.288 2.34-8.099 6.583-2.102 4.922-2.013 11.087.239 16.49 1.628 3.91 4.217 6.808 7.101 7.953a7.725 7.725 0 0 0
            3.152.575h.023c2.094-.084 4.065-1.083 5.701-2.89 2.712-2.996 4.078-7.704 3.749-12.917-.583-9.268-5.787-14.905-10.634-15.693a7.677 7.677 0 0 0-1.231-.1Zm-10.47-2.381a.809.809 0 0 1-.652-1.286c2.749-3.762 6.714-5.686
             10.879-5.28a.809.809 0 0 1-.157 1.61c-3.634-.355-6.978 1.287-9.416 4.624a.809.809 0 0 1-.654.332Z'
          fill='#1B75BB'
        />
        <path
          d='M100.424 67.25c4.247 2.656 8.893-1.956 9.114-9.69.315-11.05-8.177-16.821-12.303-8.33-2.999 6.166-1.106 15.344 3.189 18.02Z'
          fill='#1B75BB'
        />
        <path
          d='M102.9 68.813c-.983 0-1.97-.294-2.904-.878-4.76-2.964-6.552-12.76-3.489-19.059 1.682-3.462 4.243-5.057 7.024-4.376 3.547.869 7.027 5.641 6.815 13.082-.142 4.966-2.049 9.024-4.977 10.591a5.2 5.2 0 0 1-2.469.64Zm-.57-22.843c-1.673
           0-3.231 1.274-4.367 3.613-2.92 6-.848 14.652 2.888 16.98h.001c1.236.773 2.534.837 3.754.184 2.381-1.274 4-4.89 4.123-9.211.186-6.514-2.738-10.769-5.583-11.465a3.422 3.422 0 0 0-.816-.1Z'
          fill='#1B75BB'
        />
        <path
          d='M101.903 56.931c.939-.107 1.58-1.256 1.43-2.566-.15-1.31-1.033-2.286-1.973-2.179-.94.108-1.58 1.257-1.43 2.567.149 1.31 1.033 2.286 1.973 2.178ZM29.73 60.903c-4.005.922-9.042 1.058-12.648-1.023C5.68 61.44.14 53.897 1.89
           42.372c1.944-12.798 2.877-13.854 1.635-27.044-.207-2.192-.52-4.357.254-6.463 1.247-3.386 4.85-3.914 7.047-2.573.357-1.405 1.285-2.766 3.06-3.12C22.317 1.495 25.013 15.8 25.28 24.407c6.932-2.879 14.802.729 18.38 5.843 1.966
            2.808 3.254 8.378.44 10.275-1.435.963-3.512.55-6.966-3.222-8.099-8.85-9.72 8.66-5.28 9.957 3.133.913 3.722-5.2 8.112-5.95 10.072-1.727 7.103 15.596-10.235 19.594Z'
          fill='#F1F4F5'
        />
        <path
          d='m25.199 32.608-.04-.001a.809.809 0 0 1-.769-.846c.18-3.784.152-5.095.036-8.67a.808.808 0 1 1 1.617-.052c.118 3.624.147 4.953-.037 8.799a.808.808 0 0 1-.807.77Zm-8.71 2.29a.81.81 0 0 1-.803-.915c1.155-8.754-.062-23.024-5.35-27.047a.808.808
           0 1 1 .98-1.286c6.687 5.087 6.913 21.428 5.974 28.545a.808.808 0 0 1-.8.703Z'
          fill='#1B75BB'
        />
        <path
          d='M13.734 35.712a.809.809 0 0 1-.315-1.554c.77-.324 1.745-.613 2.9-.86a28.314 28.314 0 0 1 5.456-.61.809.809 0 0 1 .126 1.613c-.007 0-.046.004-.116.004a26.59 26.59 0 0 0-5.13.575c-1.038.222-1.94.488-2.607.769a.804.804 0 0
           1-.314.063Zm19.624 15.18a.81.81 0 0 1-.808-.852c.04-.779.274-2.795 1.543-4.687a.809.809 0 0 1 1.344.9c-1.045 1.559-1.237 3.227-1.271 3.872a.808.808 0 0 1-.807.766h-.001Z'
          fill='#1B75BB'
        />
        <path
          d='M24.233 62.356c-2.78 0-5.418-.538-7.555-1.776a.809.809 0 1 1 .812-1.399c5.276 3.059 14.406 1.208 19.84-2.261 6.08-3.89 8.517-9.723 7.286-12.898-.63-1.626-2.234-2.307-4.514-1.915-1.876.32-2.965 1.83-4.017 3.289-1.101
          1.526-2.348 3.255-4.46 2.635-1.677-.483-2.79-2.61-2.975-5.69-.19-3.172.713-7.055 3.145-8.083 1.745-.738 3.743.102 5.936 2.5 2.658 2.903 4.649 3.944 5.918 3.095.607-.409 1.004-1.087
           1.177-2.017.43-2.314-.622-5.4-1.83-7.126-3.41-4.87-10.852-8.28-17.406-5.556a.81.81 0 0 1-1.12-.718c-.244-6.958-1.967-16.22-6.02-19.427-1.306-1.033-2.746-1.375-4.405-1.045-1.65.33-2.232 1.723-2.433 2.524a.81.81 0 0
            1-1.208.492 4 4 0 0 0-3.368-.354c-1.157.4-2.042 1.295-2.493 2.52-.651 1.763-.458 3.707-.166 6.65.425 4.283 1.008 10.148-.576 19.77-.074.762-.252 1.732-.458 2.852-.708 3.85-1.778 9.666.156 14.27a.944.944 0 0 1
             .023.05c1.377 3.44 4.252 5.688 8.095 6.331a.808.808 0 1 1-.267 1.595c-4.41-.738-7.717-3.327-9.317-7.292l-.012-.028c-2.139-5.053-1.014-11.17-.27-15.219.202-1.096.376-2.043.442-2.739a.836.836 0 0 1
              .007-.056c1.555-9.427.984-15.176.567-19.374-.303-3.052-.522-5.258.258-7.37C3.647 6.9 4.883 5.66 6.506 5.099a5.575 5.575 0 0 1 3.852.062c.642-1.48 1.832-2.474 3.37-2.781 2.124-.423 4.05.036 5.726
               1.362 4.266 3.376 6.178 12.094 6.584 19.526 7.028-2.273 14.69 1.384 18.283 6.515 1.433 2.047 2.612 5.572 2.095 8.35-.252 1.352-.897 2.412-1.865 3.063a4.29 4.29 0 0 1-.102.066c.745.513 1.315 1.244
                1.677 2.177 1.492 3.85-1.063 10.456-7.924 14.844-3.796 2.423-9.099 4.074-13.968 4.074h-.001Zm8.82-26.733a1.595 1.595 0 0 0-.628.124c-1.394.59-2.343 3.444-2.16 6.497.135 2.26.863 3.96 1.81 4.234.927.272
                 1.504-.375 2.697-2.028 1.078-1.495 2.408-3.34 4.736-3.873-1.094-.77-2.115-1.793-2.97-2.728-.978-1.068-2.309-2.226-3.485-2.226Z'
          fill='#1B75BB'
        />
        <path
          d='M78.074 15.923c.005 2.463-1.258 4.714-3.195 5.103-1.994.4-3.918-1.21-3.923-3.674-.005-2.464 1.258-4.715 3.195-5.104 1.995-.4 3.919 1.212 3.923 3.675Z'
          fill='#87B4D7'
        />
        <path
          d='M74.761 13.806c.82 0 1.693.743 1.696 2.12.003 1.757-.83 3.302-1.897 3.516-.097.02-.194.03-.29.03-.82 0-1.694-.744-1.697-2.122-.003-1.757.83-3.3 1.897-3.515.097-.02.195-.03.291-.03Zm0-1.617c-.205 0-.409.02-.61.06-1.937.39-3.2
           2.64-3.195 5.104.004 2.21 1.554 3.735 3.313 3.735.201 0 .405-.02.61-.06 1.937-.39 3.2-2.641 3.195-5.104-.004-2.21-1.554-3.735-3.313-3.735Zm10.958.807c.82 0 1.693.743 1.696 2.12.003 1.758-.83 3.302-1.897 3.516-.097.02-.194.03-.29.03-.82
            0-1.694-.744-1.697-2.122-.003-1.757.83-3.3 1.897-3.515.097-.02.195-.03.291-.03m0-1.616c-.205 0-.409.02-.61.06-1.938.39-3.2 2.64-3.195 5.104.004 2.21 1.554 3.735 3.314 3.735.2 0 .404-.02.609-.06
             1.937-.39 3.2-2.641 3.195-5.104-.004-2.21-1.554-3.735-3.313-3.735Zm-18.603 5.354c.005 2.463-1.258 4.714-3.196 5.103-1.993.4-3.918-1.21-3.922-3.674-.005-2.463 1.258-4.714 3.195-5.103 1.995-.4 3.919
             1.21 3.923 3.674Zm75.81 69.279a30.01 30.01 0 0 0 4.442-.654c4.221-.962 8.446-2.99 12.132-6.888 2.892-3.058 3.899-6.097 3.686-8.555-.45-5.216-6.401-7.818-11.563-2.411-10.062 10.543-16.513 8.953-18.31 13.228-1.313 3.118 3.42 5.746 9.613 5.28Z'
          fill='#1B75BB'
        />
        <path
          d='M147.368 85.357c4.221-.962 8.446-2.991 12.131-6.888 1.978-2.092 3.074-4.174 3.502-6.067-1.927-1.763-10.247-1.037-11.063 4.904-3.662-.263-6.128 4.538-4.57 8.051Z'
          fill='#F1F4F5'
        />
        <path
          d='M151.914 79.902a.81.81 0 0 1-.804-.728c-.326-3.26 1.105-5.863 4.028-7.327 2.859-1.433 6.867-1.452 8.409-.041a.8.8 0 0 1 .266.561.815.815 0 0 1-.211.585.807.807 0 0 1-1.147.046c-.856-.783-4.001-1.004-6.592.294-1.614.809-3.47
           2.457-3.143 5.721a.803.803 0 0 1-.206.624.804.804 0 0 1-.6.265Z'
          fill='#1B75BB'
        />
        <path
          d='M141.538 86.871c-3.854 0-7.196-1.177-8.574-3.091-.737-1.025-.878-2.22-.396-3.364.899-2.139 2.731-2.975 5.268-4.133 3.207-1.463 7.599-3.468 13.202-9.339 2.661-2.787 5.915-3.866 8.705-2.886 2.385.838 4.013 3.055 4.249
           5.785.259 2.985-1.128 6.245-3.905 9.18-3.409 3.606-7.628 6.002-12.539 7.122-1.435.327-2.97.553-4.561.671a19.27 19.27 0 0 1-1.449.055Zm16.067-21.558c-1.784 0-3.68.95-5.397 2.748-5.818 6.096-10.562 8.26-13.701 9.693-2.365
            1.08-3.793 1.732-4.448 3.29-.266.632-.195 1.218.218 1.791.991 1.378 4.102 2.707 8.588 2.37h.001a29.36 29.36 0 0 0 4.322-.636c4.593-1.047 8.537-3.287 11.723-6.656 2.455-2.595 3.687-5.412 3.469-7.93-.18-2.089-1.397-3.775-3.174-4.4a4.812
             4.812 0 0 0-1.601-.27Z'
          fill='#1B75BB'
        />
        <path
          d='M271.13 131.451c-.708 6.657-2.978 14.001-6.392 18.038-1.896 2.24-5.256 4.541-6.68 2.991-3.584 5.543-6.455 6.847-8.38 6.019-2.487-1.068-3.388-5.703-2.185-9.347-6.784 9.824-13.392 6.777-10.691-5.759 1.192-5.551 3.082-10.218
          2.62-15.708-3.149 4.513-9.517 5.146-13.038 2.722-1.234-.85-2.106-1.732-2.637-2.641-1.833-3.128.404-6.525 6.071-9.51 4.139-2.181 5.714-5.777 8.769-9.129 1.096-1.205 2.384-2.381 4.059-3.447a20.2 20.2 0 0 1 3.622-1.836c5.614-2.189
           11.72-1.687 16.217 1.243.349.225.689.465 1.016.721 7.4 5.78 8.567 16.838 7.629 25.643Z'
          fill='#F1F4F5'
        />
        <path
          d='M239.441 128.493a.808.808 0 0 1-.806-.747 17.352 17.352 0 0 0-1.433-5.724.812.812 0 0 1 .427-1.042.812.812 0 0 1 1.055.394 18.93 18.93 0 0 1 1.564 6.248.817.817 0 0 1-.214.612.801.801 0 0 1-.594.259h.001Zm18.595 24.784a.803.803
           0 0 1-.706-.415.81.81 0 0 1 .02-.819c2.826-4.553 4.974-10.676 6.049-17.242a.808.808 0 0 1 1.446-.331c.126.17.18.383.15.592-1.108 6.771-3.335 13.105-6.271 17.833a.808.808 0 0 1-.688.382Zm-10.673-3.128a.807.807 0 0 1-.71-1.195c1.642-3.121
            3.187-7.207 4.356-11.513.279-1.021.534-2.048.765-3.08l.029-.137c.18-.801.344-1.606.494-2.414a.808.808 0 1 1 1.591.295 68.923 68.923 0 0 1-.504 2.464l-.029.137a71.827 71.827 0 0 1-.785 3.161c-1.199 4.414-2.79 8.617-4.48 11.83l-.016.03a.806.806 0 0 1-.711.422Z'
          fill='#1B75BB'
        />
        <path
          d='M250.825 159.55a3.675 3.675 0 0 1-1.599-.366c-2.18-1.042-3.16-4.04-3.062-7.027-2.508 2.785-5.036 4.049-7.163 3.512-1.953-.492-3.21-2.411-3.538-5.4-.403-3.592.534-7.228 1.526-11.077.794-3.081 1.611-6.249 1.687-9.413-3.647
           3.397-9.35 3.633-12.751 1.294-2.516-1.728-3.729-3.67-3.606-5.77.158-2.705 2.621-5.389 7.122-7.763 2.678-1.412 4.259-3.505 5.933-5.721 1.763-2.334 3.586-4.747 6.837-6.82 3.328-2.126 7.104-3.273 10.919-3.317a.802.802 0 0
            1 .819.799.811.811 0 0 1-.8.818c-3.512.041-6.994 1.1-10.068 3.063-3.006 1.917-4.739 4.212-6.415 6.431-1.786 2.364-3.473 4.597-6.47 6.177-3.916 2.066-6.14 4.348-6.262 6.427-.087 1.496.891 2.958 2.908 4.344 3.189 2.192
             9.088 1.571 11.915-2.515a.81.81 0 0 1 1.471.39c.357 4.107-.674 8.109-1.672 11.98-.954 3.701-1.854 7.196-1.485 10.494.249 2.272 1.076 3.696 2.327 4.012 1.521.383 4.273-.836 7.429-5.41a.81.81 0 0 1
             1.412.148c.075.179.083.38.022.565-1.081 3.281-.258 7.402 1.663 8.32 1.834.877 4.551-1.194 7.453-5.682a.812.812 0 0 1 .972-.315.806.806 0 0 1 .302.206c.101.109.282.252.672.244 1.209-.026 3.27-1.406
              4.795-3.21 3.011-3.558 5.447-10.467 6.207-17.602.569-5.33.993-18.426-7.323-24.921a14.257 14.257 0 0 0-2.317-1.466.808.808 0 0 1 .732-1.442c.911.46 1.776 1.007 2.582 1.634 8.955 6.993 8.532 20.768
               7.935 26.366-.63 5.917-2.69 13.877-6.581 18.475-1.565 1.852-4.027 3.74-5.995 3.782a2.649 2.649 0 0 1-1.075-.19c-3.136 4.624-5.631 5.945-7.457 5.945l-.001.001Z'
          fill='#1B75BB'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path fill='#fff' transform='translate(.782)' d='M0 0h271.435v206H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};
