import { useMemo } from 'react';

import { useWatch } from 'react-hook-form';

import { ALL_VALUE } from '../FilterForm/FilterForm.constants';

export const useFilteredValueCount = (name?: string) => {
  const watchProps = (name ? { name } : {}) as object;
  const values = useWatch(watchProps) as Record<string, string> | string;

  const selectedValueCount = useMemo(() => {
    const objectResult = typeof values === 'string' ? { name: values } : values || {};

    return Object.values(objectResult)?.filter(value => value !== ALL_VALUE && Boolean(value))
      .length;
  }, [values]);

  return {
    selectedValueCount,
    values
  };
};
