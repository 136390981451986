import { FC, useCallback } from 'react';

import {
  DialogProps as MuiDialogProps,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Box,
  ListItem
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { FormattedMessage } from 'shared/components/Intl';
import { useLocale } from 'shared/providers/Locale/Locale.hooks';

import { StyledDialog } from './Dialog.styled';

interface DialogProps extends MuiDialogProps {
  onClose: () => void;
}

export const Dialog: FC<DialogProps> = ({ open, onClose, ...other }) => {
  const { locale, switchLocale, supportedLocales } = useLocale();

  const handleClick = useCallback(
    (loc: string) => {
      switchLocale(loc);
      onClose();
    },
    [switchLocale, onClose]
  );

  return (
    <StyledDialog open={open} {...other}>
      <DialogTitle>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <FormattedMessage id='labels.selectLanguage' />
          <IconButton aria-label='close' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {supportedLocales.map(loc => (
            <ListItem key={loc.locale}>
              <ListItemButton
                selected={loc.locale === locale}
                onClick={() => handleClick(loc.locale)}
              >
                <ListItemText>{loc.localeName}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </StyledDialog>
  );
};
