import { FC, useCallback, useMemo } from 'react';

import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import { FormattedMessage } from 'shared/components/Intl';
import { Button } from 'shared/components/Button';
import { ROUTES } from 'shared/constants';
import { InspiredLibrariesApiExceptionHandlingErrorResponse } from 'shared/models/payments';
import { ErrorType } from 'shared/utils/axiosInstance';
import { useAuth } from 'shared/providers/Auth';
import { useDeviceSize } from 'shared/hooks/useDeviceSize';
import { Layout } from 'shared/components/Layout';
import { StyledInfoGrid } from 'shared/components/InfoGrid';
import { Warning } from 'shared/components/Warning';

export const RootBoundary: FC = () => {
  const error = useRouteError() as ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

  const { isMobile } = useDeviceSize();
  const { postLoginStartAndRedirect } = useAuth();

  const isNotAuthorized = useMemo(() => error?.status === 401, [error?.status]);

  const isNotFound = useMemo(() => isRouteErrorResponse(error) && error.status === 404, [error]);

  const handleClick = useCallback(() => window.location.reload(), []);

  const fullWidth = useMemo(() => isMobile, [isMobile]);

  if (isNotAuthorized) {
    postLoginStartAndRedirect();

    return null;
  }

  return (
    <Layout>
      <StyledInfoGrid container>
        <Grid item xs={12}>
          <Warning>
            {isNotFound ? (
              <>
                <Typography>
                  <FormattedMessage id='errors.pageNotFound' />
                </Typography>
                <Typography>
                  <FormattedMessage id='errors.doesntExists' />
                </Typography>
                <Typography>
                  <FormattedMessage id='errors.goBack' />
                </Typography>
                <Button variant='contained' href={ROUTES.HOME} fullWidth={fullWidth}>
                  <FormattedMessage id='accountSummary.title' />
                </Button>
              </>
            ) : (
              <>
                <Typography>
                  <FormattedMessage id='errors.somethingWentWrong' />
                </Typography>
                <Typography>
                  <FormattedMessage id='errors.canNotLoadData' />
                </Typography>
                <Button variant='contained' onClick={handleClick} fullWidth={fullWidth}>
                  <FormattedMessage id='errors.refresh' />
                </Button>
              </>
            )}
          </Warning>
        </Grid>
      </StyledInfoGrid>
    </Layout>
  );
};
