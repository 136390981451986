import { InspiredPaymentPortalAPISettingsFilterSettings } from 'shared/models/payments';

import { ActiveFilter } from '../Filter.types';

export const ALL_VALUE = 'all';

export const INITIAL_VALUE: Record<ActiveFilter, string> = {
  academicYear: '',
  invoiceStatus: ALL_VALUE,
  paymentStatus: ALL_VALUE
};

export const FORM_DICTIONARY: Record<
  ActiveFilter,
  keyof InspiredPaymentPortalAPISettingsFilterSettings
> = {
  academicYear: 'academicYears',
  invoiceStatus: 'invoiceStatuses',
  paymentStatus: 'paymentStatuses'
};

export const MAX_SELECT_WIDTH = 200;

export const SESSION_STORAGE_FILTER_KEY = 'paymentHubFilter';
