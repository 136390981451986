import { createContext } from 'react';

import {
  InspiredPaymentPortalAPISettingsTranslationSettingsTokens,
  InspiredPaymentPortalAPISettingsTranslationSettings,
  InspiredPaymentPortalAPISettingsLocalizationSettingsFallbackSettings
} from 'shared/models/payments';

export interface LocaleContext {
  switchLocale: (locale: string) => void;
  locale: string;
  supportedLocales: { locale: string; localeName: string }[];
  tokens: InspiredPaymentPortalAPISettingsTranslationSettingsTokens | undefined;
  translations?: InspiredPaymentPortalAPISettingsTranslationSettings[];
  fallbackSettings?: InspiredPaymentPortalAPISettingsLocalizationSettingsFallbackSettings;
}

export default createContext<LocaleContext | null>(null);
