import { styled } from '@mui/material';

import { openSidebarWidth, closeSidebarWidth } from 'shared/constants/styles';

export const StyledMain = styled('main', {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isSidebarOpen'
})<{ isSidebarOpen: boolean }>`
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: calc(
      100% -
        ${({ isSidebarOpen }) =>
          isSidebarOpen ? `${openSidebarWidth}px` : `${closeSidebarWidth}px`}
    );
  }
`;
