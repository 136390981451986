import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useIntl } from 'shared/components/Intl';
import { ROUTES } from 'shared/constants';

export const useCurrentPageName = () => {
  const location = useLocation();
  const { formatMessage } = useIntl();

  return useMemo(() => {
    const locationArray = location.pathname.split('/').pop();

    switch (locationArray) {
      case ROUTES.MAKE_PAYMENT:
        return formatMessage({ id: 'makePayment.title' });
      case ROUTES.INVOICES:
        return formatMessage({ id: 'invoices.title' });
      case ROUTES.STATEMENTS:
        return formatMessage({ id: 'statements.title' });
      case ROUTES.PAYMENTS:
        return formatMessage({ id: 'payments.title' });
      case ROUTES.PROFILE:
        return formatMessage({ id: 'profile.title' });
      case ROUTES.CONTACT_US:
        return formatMessage({ id: 'contactUs.title' });
      case ROUTES.DIRECT_DEBIT:
        return formatMessage({ id: 'directDebit.title' });
      case ROUTES.RE_ENROLMENT:
        return formatMessage({ id: 'reEnrolment.title' });
      case ROUTES.RE_ENROLMENT_OPTIONS:
        return formatMessage({ id: 'reEnrolmentOptions.title' });
      case ROUTES.RE_ENROLMENT_CONFIRMATION:
        return formatMessage({ id: 'reEnrolmentConfirmation.title' });
      case ROUTES.RE_ENROLMENT_CONTRACT:
        return formatMessage({ id: 'reEnrolmentContract.title' });
      case '':
        return formatMessage({ id: 'accountSummary.title' });
      default:
        return '';
    }
  }, [formatMessage, location.pathname]);
};
