import { GetFieldType } from 'shared/types/filedType';

export function getByPath<TData, TPath extends string, TDefault = GetFieldType<TData, TPath>>(
  data: TData,
  path: TPath,
  defaultValue?: TDefault
): GetFieldType<TData, TPath> | TDefault {
  const value = path
    .split(/[.[\]]/)
    .filter(Boolean)
    .reduce<GetFieldType<TData, TPath>>((v, key) => (v as any)?.[key], data as any);

  return value !== undefined ? value : (defaultValue as TDefault);
}
