import { styled, Grid, GridProps } from '@mui/material';

export const StyledInfoGrid = styled(Grid)<GridProps>`
  height: 100%;

  .MuiGrid-root {
    align-self: center;
    text-align: center;
  }

  .MuiTypography-root {
    letter-spacing: ${({ theme }) => theme.spacing(0.009375)};

    &:first-of-type {
      &:not(.MuiLink-root) {
        margin: ${({ theme }) => theme.spacing(5, 0, 1)};
        font-size: ${({ theme }) => theme.spacing(4.25)};
        letter-spacing: ${({ theme }) => theme.spacing(0.015625)};
        font-weight: 300;
        line-height: 123.5%;
      }
    }
  }

  .MuiButtonBase-root {
    margin-top: ${({ theme }) => theme.spacing(3)};
  }
`;
