import { createContext } from 'react';

export interface SidebarContext {
  isOpen: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
  toggleSidebar: () => void;
}

export default createContext<SidebarContext | null>(null);
