import { Box, styled } from '@mui/material';

import { headerDesktopHeight } from 'shared/constants';

export const StyledPreview = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;

  > .MuiBox-root {
    background-color: rgba(0, 0, 0, 0.05);

    &:nth-of-type(2) {
      box-sizing: border-box;
      height: calc(100vh - ${headerDesktopHeight}px);
    }
  }
`;
