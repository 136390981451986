import { useMemo } from 'react';

import { useGetSettings } from 'shared/hooks/payment-api/settings-endpoint';
import { ThemeColor } from 'shared/types/themeColor';
import { queryKeys, ROUTES } from 'shared/constants';
import { useAuth } from 'shared/providers/Auth';
import { InspiredPaymentPortalAPISettingsFilterSettings } from 'shared/models/payments';

export const useGetFormattedSettings = () => {
  const { isLoggedIn } = useAuth();

  const {
    data: settingsData,
    isLoading: settingsLoading,
    error: settingsError
  } = useGetSettings({
    query: {
      staleTime: Infinity,
      queryKey: queryKeys.settings,
      enabled: isLoggedIn,
      useErrorBoundary: false
    }
  });

  const disabledRoutes = useMemo(() => {
    const disabledRoutesArray = [];

    if (!settingsData?.directDebitEnabled) {
      disabledRoutesArray.push(ROUTES.DIRECT_DEBIT);
    }

    if (settingsData?.disableOneOffPayments) {
      disabledRoutesArray.push(ROUTES.MAKE_PAYMENT);
    }

    if (!settingsData?.reEnrolment.isEnabled) {
      disabledRoutesArray.push(
        ROUTES.RE_ENROLMENT,
        ROUTES.RE_ENROLMENT_OPTIONS,
        ROUTES.RE_ENROLMENT_CONFIRMATION,
        ROUTES.RE_ENROLMENT_CONTRACT
      );
    }

    return disabledRoutesArray;
  }, [
    settingsData?.directDebitEnabled,
    settingsData?.disableOneOffPayments,
    settingsData?.reEnrolment.isEnabled
  ]);

  const defaultAcademicYear = useMemo(() => {
    if (!settingsData?.filters.academicYears.length) {
      return '';
    }

    const newAcademicYears = [...settingsData.filters.academicYears];

    return newAcademicYears.pop()?.value || '';
  }, [settingsData?.filters.academicYears]);

  const hasFilters = useMemo(() => {
    if (!settingsData?.filters) {
      return false;
    }

    return Object.values(settingsData.filters).some(filter => !!filter.length);
  }, [settingsData]);

  return {
    disabledRoutes,
    localization: settingsData?.localization,
    error: settingsError,
    isLoading: settingsLoading,
    logoUrl: settingsData?.visual.logoUrl,
    favIconUrl: settingsData?.visual.favIconUrl,
    parentPortalUrl: settingsData?.parentPortalUrl,
    schoolName: settingsData?.schoolName || 'Loading...',
    colors: settingsData?.visual.theme.colors as unknown as ThemeColor,
    disableOneOffPayments: settingsData?.disableOneOffPayments,
    hasFilters,
    filters: settingsData
      ? settingsData.filters
      : ({} as InspiredPaymentPortalAPISettingsFilterSettings),
    defaultAcademicYear,
    reEnrolment: settingsData?.reEnrolment
  };
};
