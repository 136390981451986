import { FC } from 'react';

import { Link } from 'react-router-dom';
import { MenuItem, MenuProps, Divider, ListItemIcon, ListItemText } from '@mui/material';
import { AccountCircleOutlined as AccountCircleOutlinedIcon } from '@mui/icons-material';

import { FormattedMessage } from 'shared/components/Intl';
import { ROUTES } from 'shared/constants';
import { useAuth } from 'shared/providers/Auth';

import { StyledMenu } from './Menu.styled';

export const ProfileMenuComponent: FC<MenuProps> = props => {
  const { logout } = useAuth();

  return (
    <StyledMenu {...props}>
      <MenuItem component={Link} to={ROUTES.PROFILE}>
        <ListItemIcon>
          <AccountCircleOutlinedIcon />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id='profile.title' />
        </ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={logout}>
        <ListItemText>
          <FormattedMessage id='auth.logout' />
        </ListItemText>
      </MenuItem>
    </StyledMenu>
  );
};
