import { Theme } from '@mui/material';

import { ThemeColor } from 'shared/types/themeColor';

export const getGlobalStyles = (initialTheme: Theme, colors: ThemeColor) => ({
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        // Firefox only
        scrollbarWidth: 'thin',
        '*::-webkit-scrollbar': {
          width: initialTheme.spacing(0.75),
          height: initialTheme.spacing(0.75),
          backgroundColor: colors!.scrollbarBackground
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: colors?.text?.secondary,
          borderRadius: `${initialTheme.shape.borderRadius}px`
        },
        '*::-webkit-scrollbar-corner': {
          backgroundColor: colors!.scrollbarBackground
        }
      }
    }
  }
});
