import { FC } from 'react';

import { Typography } from '@mui/material';

import { useCurrentPageName } from 'shared/hooks/useCurrentPageName';

import { PAGE_NAME_TEST_ID } from './PageName.constants';
import { useReEnrolmentPageName } from './PageName.hooks';

export const PageName: FC = () => {
  // TODO: refactor and use breadcrumbs like in GSP
  const pageName = useCurrentPageName();
  const reEnrolmentPageName = useReEnrolmentPageName();

  return (
    <Typography fontSize='1.25em' variant='body1' fontWeight='600' data-testid={PAGE_NAME_TEST_ID}>
      {pageName || reEnrolmentPageName}
    </Typography>
  );
};
