import { useCallback } from 'react';

import { FieldValues } from 'react-hook-form';

import { useSessionStorage } from 'shared/hooks/useSessionStorage';

import { StoredFiltersType, ActiveFilter } from '../Filter.types';
import { ALL_VALUE, SESSION_STORAGE_FILTER_KEY } from '../FilterForm/FilterForm.constants';

const INITIAL_FILTER_STATE = {};

export const useFilterSessionStorage = () => {
  const [storedValue, setStoredValue] = useSessionStorage<StoredFiltersType>(
    SESSION_STORAGE_FILTER_KEY,
    INITIAL_FILTER_STATE
  );

  const setFiltersToStorage = useCallback(
    (values: FieldValues) => {
      let valuesCopy: StoredFiltersType = { ...storedValue, ...values };

      for (const key in valuesCopy) {
        if (Object.hasOwn(valuesCopy, key)) {
          const propKey = key as ActiveFilter;

          if (!valuesCopy[propKey] || valuesCopy[propKey] === ALL_VALUE) {
            const { [propKey]: _, ...otherValues } = valuesCopy;

            valuesCopy = otherValues;
          }
        }
      }

      setStoredValue(valuesCopy);
    },
    [storedValue, setStoredValue]
  );

  const removeFiltersFromStorage = useCallback(
    () => setStoredValue(INITIAL_FILTER_STATE),
    [setStoredValue]
  );

  return { storedValue, setStoredValue, setFiltersToStorage, removeFiltersFromStorage };
};
