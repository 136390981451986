import { FC, useCallback, useState, SyntheticEvent } from 'react';

import { Avatar } from '@mui/material';
import { AccountCircleOutlined as AccountCircleOutlinedIcon } from '@mui/icons-material';

import { StyledProfile } from './Profile.styled';
import { Menu } from './Menu';

export const ProfileComponent: FC = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = useCallback((event: SyntheticEvent<HTMLDivElement, Event>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  return (
    <>
      <StyledProfile onClick={handleClick}>
        <Avatar component={AccountCircleOutlinedIcon} />
      </StyledProfile>
      <Menu
        open={!!menuAnchorEl}
        anchorEl={menuAnchorEl}
        onClose={handleClose}
        onClick={handleClose}
      />
    </>
  );
};
