/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inspired.PaymentPortal.Auth.Web
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  InspiredPaymentPortalAuthWebModelsStartLoginResponse,
  InspiredLibrariesApiExceptionHandlingErrorResponse,
  InspiredPaymentPortalAuthWebModelsStartLoginRequest,
  InspiredPaymentPortalAuthWebModelsLoginStatusResponse
} from '../../models/auth';
import { customInstance } from '../../utils/axiosInstance';
import type { ErrorType } from '../../utils/axiosInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const postAuthApiLoginStart = (
  inspiredPaymentPortalAuthWebModelsStartLoginRequest: InspiredPaymentPortalAuthWebModelsStartLoginRequest
) => {
  return customInstance<InspiredPaymentPortalAuthWebModelsStartLoginResponse>({
    url: `/auth/api/login/start`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: inspiredPaymentPortalAuthWebModelsStartLoginRequest
  });
};

export const getPostAuthApiLoginStartMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthApiLoginStart>>,
    TError,
    { data: InspiredPaymentPortalAuthWebModelsStartLoginRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthApiLoginStart>>,
  TError,
  { data: InspiredPaymentPortalAuthWebModelsStartLoginRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthApiLoginStart>>,
    { data: InspiredPaymentPortalAuthWebModelsStartLoginRequest }
  > = props => {
    const { data } = props ?? {};

    return postAuthApiLoginStart(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthApiLoginStartMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthApiLoginStart>>
>;
export type PostAuthApiLoginStartMutationBody = InspiredPaymentPortalAuthWebModelsStartLoginRequest;
export type PostAuthApiLoginStartMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const usePostAuthApiLoginStart = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthApiLoginStart>>,
    TError,
    { data: InspiredPaymentPortalAuthWebModelsStartLoginRequest },
    TContext
  >;
}) => {
  const mutationOptions = getPostAuthApiLoginStartMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAuthApiLoginStatus = () => {
  return customInstance<InspiredPaymentPortalAuthWebModelsLoginStatusResponse>({
    url: `/auth/api/login/status`,
    method: 'get'
  });
};

export const getGetAuthApiLoginStatusQueryKey = () => [`/auth/api/login/status`] as const;

export const getGetAuthApiLoginStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuthApiLoginStatus>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAuthApiLoginStatus>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<typeof getAuthApiLoginStatus>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAuthApiLoginStatusQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuthApiLoginStatus>>> = () =>
    getAuthApiLoginStatus();

  return { queryKey, queryFn, ...queryOptions };
};

export type GetAuthApiLoginStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuthApiLoginStatus>>
>;
export type GetAuthApiLoginStatusQueryError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetAuthApiLoginStatus = <
  TData = Awaited<ReturnType<typeof getAuthApiLoginStatus>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getAuthApiLoginStatus>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAuthApiLoginStatusQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
