import { Dialog, styled } from '@mui/material';
import { transparentize } from 'polished';

export const StyledAlertDialog = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: ${({ theme }) => transparentize(0.5, theme.palette.common.black)};
  }

  .MuiDialogTitle-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    line-height: ${({ theme }) => theme.spacing(4)};
  }

  .MuiDialogActions-root,
  .MuiDialogContent-root,
  .MuiDialogTitle-root {
    padding: ${({ theme }) => theme.spacing(1, 2)};
  }
`;
