/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inspired.PaymentPortal.API
 * OpenAPI spec version: 1.0
 */
import { useQuery } from 'react-query';
import type { UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from 'react-query';
import type {
  InspiredPaymentPortalAPIInvoicesInvoicesResponse,
  InspiredLibrariesApiExceptionHandlingErrorResponse,
  GetInvoicesParams
} from '../../models/payments';
import { customInstance } from '../../utils/axiosInstance';
import type { ErrorType } from '../../utils/axiosInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getInvoices = (params?: GetInvoicesParams) => {
  return customInstance<InspiredPaymentPortalAPIInvoicesInvoicesResponse>({
    url: `/api/invoices`,
    method: 'get',
    params
  });
};

export const getGetInvoicesQueryKey = (params?: GetInvoicesParams) =>
  [`/api/invoices`, ...(params ? [params] : [])] as const;

export const getGetInvoicesQueryOptions = <
  TData = Awaited<ReturnType<typeof getInvoices>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  params?: GetInvoicesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetInvoicesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getInvoices>>> = () => getInvoices(params);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof getInvoices>>>;
export type GetInvoicesQueryError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetInvoices = <
  TData = Awaited<ReturnType<typeof getInvoices>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  params?: GetInvoicesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getInvoices>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInvoicesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
