/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inspired.PaymentPortal.API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  InspiredPaymentPortalAPIProfileProfileResponse,
  InspiredLibrariesApiExceptionHandlingErrorResponse,
  InspiredPaymentPortalAPIProfileUpdateProfilePreferencesRequest
} from '../../models/payments';
import { customInstance } from '../../utils/axiosInstance';
import type { ErrorType } from '../../utils/axiosInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getProfile = () => {
  return customInstance<InspiredPaymentPortalAPIProfileProfileResponse>({
    url: `/api/profile`,
    method: 'get'
  });
};

export const getGetProfileQueryKey = () => [`/api/profile`] as const;

export const getGetProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof getProfile>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getProfile>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<typeof getProfile>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProfileQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProfile>>> = () => getProfile();

  return { queryKey, queryFn, ...queryOptions };
};

export type GetProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getProfile>>>;
export type GetProfileQueryError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetProfile = <
  TData = Awaited<ReturnType<typeof getProfile>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getProfile>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateProfilePreferences = (
  inspiredPaymentPortalAPIProfileUpdateProfilePreferencesRequest: InspiredPaymentPortalAPIProfileUpdateProfilePreferencesRequest
) => {
  return customInstance<void>({
    url: `/api/profile`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: inspiredPaymentPortalAPIProfileUpdateProfilePreferencesRequest
  });
};

export const getUpdateProfilePreferencesMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProfilePreferences>>,
    TError,
    { data: InspiredPaymentPortalAPIProfileUpdateProfilePreferencesRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateProfilePreferences>>,
  TError,
  { data: InspiredPaymentPortalAPIProfileUpdateProfilePreferencesRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateProfilePreferences>>,
    { data: InspiredPaymentPortalAPIProfileUpdateProfilePreferencesRequest }
  > = props => {
    const { data } = props ?? {};

    return updateProfilePreferences(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateProfilePreferencesMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateProfilePreferences>>
>;
export type UpdateProfilePreferencesMutationBody =
  InspiredPaymentPortalAPIProfileUpdateProfilePreferencesRequest;
export type UpdateProfilePreferencesMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useUpdateProfilePreferences = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProfilePreferences>>,
    TError,
    { data: InspiredPaymentPortalAPIProfileUpdateProfilePreferencesRequest },
    TContext
  >;
}) => {
  const mutationOptions = getUpdateProfilePreferencesMutationOptions(options);

  return useMutation(mutationOptions);
};
