import { CSSObject, Theme } from '@mui/material';

export const hideMixin = (theme: Theme): CSSObject => ({
  visibility: 'hidden',
  opacity: 0,
  transition: theme.transitions.create(['visibility', 'opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
});

export const showMixin = (theme: Theme): CSSObject => ({
  visibility: 'visible',
  opacity: 1,
  transition: theme.transitions.create(['visibility', 'opacity'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
});
