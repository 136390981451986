import { useCallback } from 'react';

import { useGetFormattedSettings } from 'shared/hooks/useGetFormattedSettings';

import { ActiveFilter } from '../Filter.types';
import { FORM_DICTIONARY } from '../FilterForm/FilterForm.constants';

export const useCurrentFilterOptions = () => {
  const { filters } = useGetFormattedSettings();

  return useCallback(
    (filedName: ActiveFilter) => {
      const filterName = FORM_DICTIONARY[filedName];

      return filters[filterName];
    },
    [filters]
  );
};
