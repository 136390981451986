import { FC, useCallback, useState, MouseEvent } from 'react';

import {
  Language as LanguageIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from '@mui/icons-material';

import { useLocale } from 'shared/providers/Locale/Locale.hooks';

import { StyledLocale } from './Locale.styled';
import { Menu } from './Menu';

export const LocaleComponent: FC = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

  const { locale, supportedLocales } = useLocale();

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  if (supportedLocales.length === 1) {
    return null;
  }

  return (
    <>
      <StyledLocale
        variant='text'
        color='inherit'
        onClick={handleClick}
        endIcon={menuAnchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        startIcon={<LanguageIcon />}
        disableRipple
      >
        {locale}
      </StyledLocale>
      <Menu
        open={!!menuAnchorEl}
        anchorEl={menuAnchorEl}
        onClose={handleClose}
        onClick={handleClose}
      />
    </>
  );
};
