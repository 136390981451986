import { QueryClient } from 'react-query';

import { CustomServerError } from './axiosInstance';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 120000,
      useErrorBoundary: true,
      retry: 0
    },
    mutations: {
      retry: 0,
      useErrorBoundary: err => {
        const error = err as CustomServerError<{}>;
        return error.status === 401;
      }
    }
  }
});
