import { FC, PropsWithChildren, useMemo } from 'react';

import { Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useSidebarContext } from 'shared/providers/Sidebar';
import { ROUTES } from 'shared/constants';

import { StyledMain } from './Main.styled';
import { StyledBox } from './Box.styled';

const pagesWithoutFixedWidth = [ROUTES.INVOICES, ROUTES.RE_ENROLMENT, ROUTES.RE_ENROLMENT_CONTRACT];

export const Main: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const { isOpen } = useSidebarContext();

  // TODO: refactore this to have common layout
  const fixedWidth = useMemo(
    () => !pagesWithoutFixedWidth.includes(location.pathname.split('/').pop() || ''),
    [location.pathname]
  );

  return (
    <StyledMain isSidebarOpen={isOpen}>
      <StyledBox fixedWidth={fixedWidth}>
        <Toolbar />
        {children}
      </StyledBox>
    </StyledMain>
  );
};
