import { FC } from 'react';

import { IconButton, Typography, Box } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';

import { FormattedMessage } from 'shared/components/Intl';
import { useSidebarContext } from 'shared/providers/Sidebar';
import { useDeviceSize } from 'shared/hooks/useDeviceSize';

import { StyledSidebar } from './Sidebar.styled';
import { LogoHolder } from './LogoHolder';
import { NavList } from './NavList';
import { StyledDrawerHeader } from '../DrawerHeader';
import { Footer } from './Footer';
import { Locale } from './Locale';
import { SIDEBAR_TEST_ID, CLOSE_SIDEBAR_TEST_ID } from './Sidebar.constants';

export const Sidebar: FC = () => {
  const { isOpen, closeSidebar } = useSidebarContext();
  const { isDesktop } = useDeviceSize();

  return (
    <StyledSidebar
      data-testid={SIDEBAR_TEST_ID}
      variant={isDesktop ? 'temporary' : 'permanent'}
      open={isOpen}
      onClose={closeSidebar}
      ModalProps={{
        keepMounted: true // Better open performance on mobile.
      }}
      data-open={!isDesktop && isOpen}
    >
      <StyledDrawerHeader bottomBorder>
        <Typography fontWeight='bold' variant='body1'>
          <FormattedMessage id='title' />
        </Typography>
        <IconButton onClick={closeSidebar} data-testid={CLOSE_SIDEBAR_TEST_ID}>
          <ChevronLeftIcon />
        </IconButton>
      </StyledDrawerHeader>
      <LogoHolder />
      <NavList />
      <Box flexGrow={1} display='flex' flexDirection='column' justifyContent='flex-end'>
        {isDesktop && <Locale />}
        <Footer />
      </Box>
    </StyledSidebar>
  );
};
