import { useMemo } from 'react';

import { useLocation, matchPath } from 'react-router-dom';

import { useIntl } from 'shared/components/Intl';
import { ROUTES } from 'shared/constants';

export const useReEnrolmentPageName = () => {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();

  return useMemo(() => {
    if (matchPath(ROUTES.RE_ENROLMENT_OPTIONS, pathname)) {
      return formatMessage({ id: 'reEnrolmentOptions.title' });
    }

    if (matchPath(ROUTES.RE_ENROLMENT_CONFIRMATION, pathname)) {
      return formatMessage({ id: 'reEnrolmentConfirmation.title' });
    }

    if (matchPath(ROUTES.RE_ENROLMENT_CONTRACT, pathname)) {
      return formatMessage({ id: 'reEnrolmentContract.title' });
    }

    return '';
  }, [formatMessage, pathname]);
};
