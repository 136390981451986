import { FC, PropsWithChildren } from 'react';

import { Outlet } from 'react-router-dom';

import { StyledLayout } from './Layout.styled';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Main } from './Main';
import { Acting } from './Acting';
import { ReEnrolmentActionRequired } from './ReEnrolmentActionRequired';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <StyledLayout>
      <Header />
      <Sidebar />
      <Main>
        <Outlet />
        {children}
      </Main>
      <Acting />
      <ReEnrolmentActionRequired />
    </StyledLayout>
  );
};
