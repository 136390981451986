import { styled, Dialog, DialogProps } from '@mui/material';

export const StyledDialog = styled(Dialog)<DialogProps>`
  .MuiDialogTitle-root {
    padding: ${({ theme }) => theme.spacing(1.5, 2)};
    font-weight: 600;
  }

  .MuiDialogContent-root {
    padding: ${({ theme }) => theme.spacing(4, 1)};
  }

  .MuiList-root,
  .MuiListItem-root {
    padding: 0;
  }

  .MuiListItemButton-root {
    border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`};
  }
`;
