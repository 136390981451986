import { FC, PropsWithChildren, useState, useEffect, useMemo, useCallback } from 'react';

import { IntlProvider } from 'react-intl';

import { useGetFormattedSettings } from 'shared/hooks/useGetFormattedSettings';
import { useUpdateProfilePreferences } from 'shared/hooks/payment-api/profile-endpoint';
import { InspiredLibrariesApiExceptionHandlingErrorResponse } from 'shared/models/payments';
import { useErrorHandler } from 'shared/hooks/useErrorHandler';
import { ErrorType } from 'shared/utils/axiosInstance';

import LocaleContext, { LocaleContext as ILocaleContext } from './Locale.context';
import { DEFAULT_LOCALE } from './Locale.constants';

const LocaleContainerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [locale, setLocale] = useState<string>(DEFAULT_LOCALE);

  const { localization } = useGetFormattedSettings();
  const errorHandler = useErrorHandler();

  const { mutateAsync } = useUpdateProfilePreferences();

  useEffect(() => {
    if (localization?.preferredLocale) {
      setLocale(localization.preferredLocale);

      document.documentElement.lang = localization.preferredLocale;
    }
  }, [localization?.preferredLocale]);

  // use this tokens if messages are localy but not coming from server
  // const tokens = useMemo(() => flattenMessages(LOCALES[locale as Lang]), [locale]);

  const tokens = useMemo(
    () => localization?.translations.find(translation => translation.locale === locale)?.tokens,
    [localization?.translations, locale]
  );

  const supportedLocales = useMemo(
    () =>
      localization?.supportedLocales
        .map(loc => ({
          locale: loc,
          localeName:
            localization?.translations.find(translation => translation.locale === loc)?.tokens
              .localeName || ''
        }))
        .filter(loc => loc.localeName) || [],
    [localization?.supportedLocales, localization?.translations]
  );

  const mutateProfilePreferencesAsync = useCallback(
    async (loc: string) => {
      try {
        await mutateAsync({
          data: { locale: loc }
        });
      } catch (err) {
        await errorHandler(err as ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>);
      }
    },
    [errorHandler, mutateAsync]
  );

  const switchLocale = useCallback(
    (lang: string) => {
      if (locale !== lang) {
        setLocale(lang);

        mutateProfilePreferencesAsync(lang);

        document.documentElement.lang = lang;
      }
    },
    [mutateProfilePreferencesAsync, locale]
  );

  const value: ILocaleContext = useMemo(
    () => ({
      switchLocale,
      supportedLocales,
      tokens,
      locale,
      translations: localization?.translations,
      fallbackSettings: localization?.fallbackSettings
    }),
    [
      switchLocale,
      supportedLocales,
      tokens,
      locale,
      localization?.translations,
      localization?.fallbackSettings
    ]
  );

  return (
    <LocaleContext.Provider value={value}>
      <IntlProvider locale={locale} messages={tokens as keyof typeof tokens} textComponent='span'>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleContainerProvider;
