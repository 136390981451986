import { useCallback, useMemo } from 'react';

import { FieldValues, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { ALL_VALUE, INITIAL_VALUE } from '../FilterForm/FilterForm.constants';
import { ActiveFilter } from '../Filter.types';
import { useFilterSessionStorage } from './useFilterSessionStorage';

export const useFilter = <T extends FieldValues>(
  initFormValue: T,
  disableClearing?: ActiveFilter[]
) => {
  const { setValue, reset, getValues } = useFormContext();
  const [_, setSearchParams] = useSearchParams();
  const { setFiltersToStorage } = useFilterSessionStorage();

  const values = getValues();

  const setFilters = useCallback(() => {
    setFiltersToStorage(values);

    const filledValues = Object.fromEntries(
      Object.entries(values).filter(
        item => item[item.length - 1] && item[item.length - 1] !== ALL_VALUE
      )
    );

    setSearchParams(filledValues);
  }, [values, setSearchParams, setFiltersToStorage]);

  const handleResetField = useCallback(
    (filterName: keyof typeof INITIAL_VALUE) => {
      setValue(filterName, initFormValue[filterName]);
    },
    [initFormValue, setValue]
  );

  const interceptedValues = useMemo(() => {
    const interceptedObject = {} as T;

    for (const key in initFormValue) {
      if (!disableClearing?.includes(key as ActiveFilter)) {
        interceptedObject[key] = initFormValue[key];
      }
    }

    return { ...values, ...interceptedObject };
  }, [disableClearing, values, initFormValue]);

  const handleReset = useCallback(() => {
    reset(interceptedValues);
  }, [interceptedValues, reset]);

  return {
    reset,
    setFilters,
    handleReset,
    handleResetField
  };
};
