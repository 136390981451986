import { useCallback, useEffect, useState } from 'react';

import { generatePath, useNavigate } from 'react-router-dom';

import { useIntl } from 'shared/components/Intl';
import { AlertDialog } from 'shared/components/AlertDialog';
import { ROUTES } from 'shared/constants';
import { useGetFormattedSettings } from 'shared/hooks/useGetFormattedSettings';

import {
  useIsReEnrolmentRoutesPath,
  useReEnrolmentActionRequiredSessionStorage
} from './ReEnrolmentActionRequired.hooks';

export const ReEnrolmentActionRequired = () => {
  const [isActionRequiredModalOpen, setIsActionRequiredModalOpen] = useState(false);
  const navigate = useNavigate();

  const isReEnrolmentRoutesPath = useIsReEnrolmentRoutesPath();
  const { storedValue, setStoredValue } = useReEnrolmentActionRequiredSessionStorage();

  const { formatMessage } = useIntl();
  const { reEnrolment } = useGetFormattedSettings();

  // we not showing action required popup if already visited reEnrolment pages
  useEffect(() => {
    if (isReEnrolmentRoutesPath && !storedValue) {
      setStoredValue(true);
    }
  }, [isReEnrolmentRoutesPath, storedValue, setStoredValue]);

  useEffect(() => {
    if (reEnrolment?.hasPendingContracts && !isReEnrolmentRoutesPath && !storedValue) {
      setIsActionRequiredModalOpen(true);
      setStoredValue(true);
    }
  }, [reEnrolment?.hasPendingContracts, isReEnrolmentRoutesPath, storedValue, setStoredValue]);

  const handleCloseActionRequiredModal = useCallback(() => setIsActionRequiredModalOpen(false), []);

  const handleGoToReEnrolments = useCallback(() => {
    navigate(generatePath(`${ROUTES.HOME}${ROUTES.RE_ENROLMENT}`));

    setIsActionRequiredModalOpen(false);
  }, [navigate]);

  return (
    <AlertDialog
      open={isActionRequiredModalOpen}
      title={formatMessage({ id: 'reEnrolmentActionRequired.title' })}
      description={formatMessage({
        id: 'reEnrolmentActionRequired.description'
      })}
      cancelBtnText={formatMessage({ id: 'reEnrolmentActionRequired.cancel' })}
      confirmBtnText={formatMessage({ id: 'reEnrolmentActionRequired.confirm' })}
      onCancel={handleCloseActionRequiredModal}
      onConfirm={handleGoToReEnrolments}
      onClose={handleCloseActionRequiredModal}
      isError={false}
      fullWidth
    />
  );
};
