import React from 'react';

import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import { CssBaseline } from '@mui/material';

import { queryClient } from 'shared/utils/quryClient';
import { LocaleProvider } from 'shared/providers/Locale';
import { ThemeProvider } from 'shared/providers/Theme';
import { SidebarProvider } from 'shared/providers/Sidebar';
import { SnackbarProvider } from 'shared/providers/Snackbar';
import { SettingsProvider } from 'shared/providers/Settings';
import { AuthProvider } from 'shared/providers/Auth';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <SettingsProvider>
          <ThemeProvider>
            <SidebarProvider>
              <SnackbarProvider>
                <LocaleProvider>
                  <CssBaseline />
                  <App />
                </LocaleProvider>
              </SnackbarProvider>
            </SidebarProvider>
          </ThemeProvider>
        </SettingsProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
