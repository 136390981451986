import { FC } from 'react';

import { Box, IconButton, Toolbar } from '@mui/material';
import {
  Menu as MenuIcon,
  ContactSupportOutlined as ContactSupportOutlinedIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

import { useSidebarContext } from 'shared/providers/Sidebar';
import { useDeviceSize } from 'shared/hooks/useDeviceSize';
import { ROUTES } from 'shared/constants';

import { StyledAppBar } from './AppBar.styled';
import { Profile } from './Profile';
import { Locale } from './Locale';
import { PageName } from './PageName';
import { StyledBurgerButton } from './BurgerButton';
import { HEADER_TEST_ID } from './Header.constants';
import { BURGER_BUTTON_TEST_ID } from './BurgerButton/BurgerButton.constants';

export const Header: FC = () => {
  const { isOpen, toggleSidebar } = useSidebarContext();
  const { isMobile, isLargeDesktop } = useDeviceSize();

  return (
    <StyledAppBar
      data-testid={HEADER_TEST_ID}
      position='fixed'
      open={!isMobile && isOpen}
      data-open={!isMobile && isOpen}
    >
      <Toolbar disableGutters>
        <Box display='flex' alignItems='center'>
          <StyledBurgerButton
            disableRipple
            data-testid={BURGER_BUTTON_TEST_ID}
            aria-label='open drawer'
            active={isOpen}
            onClick={toggleSidebar}
          >
            <MenuIcon />
          </StyledBurgerButton>
          <PageName />
        </Box>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          {isLargeDesktop && <Locale />}
          <IconButton component={Link} to={ROUTES.CONTACT_US} disableRipple color='inherit'>
            <ContactSupportOutlinedIcon />
          </IconButton>
          <Profile />
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};
