import { FC } from 'react';

import { TooltipProps } from '@mui/material';

import { StyledInfoTooltip } from './InfoTooltip.styled';

export const InfoTooltip: FC<TooltipProps> = ({ children, ...otherProps }) => {
  return (
    <StyledInfoTooltip enterTouchDelay={0} leaveTouchDelay={3000} {...otherProps}>
      {children}
    </StyledInfoTooltip>
  );
};
