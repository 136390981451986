export const HOME = '/';

export const INVOICES = 'invoices';
export const MAKE_PAYMENT = 'make-payment';
export const STATEMENTS = 'statements';
export const PROFILE = 'profile';
export const CONTACT_US = 'contact-us';
export const PAYMENTS = 'payments';
export const DIRECT_DEBIT = 'direct-debit';
export const RE_ENROLMENT = 're-enrolment';
export const RE_ENROLMENT_OPTIONS = 're-enrolment-options/:reEnrolmentId';
export const RE_ENROLMENT_CONFIRMATION = 're-enrolment-confirmation/:reEnrolmentId';
export const RE_ENROLMENT_CONTRACT = 're-enrolment-contract/:reEnrolmentId';
export const SUCCESS_PAYMENT = 'success-payment/:sessionId';
// TODO: remove boleto after business confirmation
export const BOLETO_PAYMENT = 'boleto-payment/:checkoutSessionId';
