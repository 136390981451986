import axios, { AxiosRequestConfig } from 'axios';
import { ValidationError } from 'yup';

import { baseAxiosInstance } from 'shared/utils/apiService';

export type FormattedServerValidation = {
  [key: string]: string;
};

export type CustomServerError<E extends {}> = E & {
  status: number;
  detail: string;
  validationError?: FormattedServerValidation[];
  title?: string;
  statusText?: string;
  inner?: ValidationError[];
};

export const customInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = axios.CancelToken.source();
  const promise = baseAxiosInstance({ ...config, cancelToken: source.token }).then(
    ({ data }) => data
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

export default customInstance;

export type ErrorType<Error extends {}> = CustomServerError<Error>;
