export const BrokenPen = () => {
  return (
    <svg width='236' height='236' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 0h236v236H0V0Z' fill='none' />
      <path
        d='M141.716 180.15v.002c-.186.517-.372 1.032-.552 1.549-.183.514-.363 1.028-.541 1.543-.18.514-.358 1.026-.533 1.535l-.523 1.53h-.002c-1.799
         1.397-3.986 2.971-5.643 3.798-2.214.455-7.289 2.194-16.669.374a256.205 256.205 0 0
          1-3.278-1.676c-.363-.191-.726-.381-1.09-.575-.948-.501-1.892-1.01-2.835-1.524-4.214-9.256-7.781-18.104-10.73-26.576-15.407-44.266-16.825-85.503
           10.155-123.37l35.191 18.349.126-.008c-19.474 29.709-21.434 64.036-6.061 106.859 2.206 6.146.065 11.684 2.985 18.19Z'
        fill='#87B4D7'
      />
      <path
        d='M125.192 191.952c-2.244 0-4.911-.218-8.064-.83a.63.63 0 0 1-.168-.057 201.604 201.604 0 0 1-4.382-2.258c-.95-.503-1.897-1.012-2.841-1.528a.652.652 0 0
         1-.282-.303c-4.18-9.182-7.797-18.143-10.751-26.631-17.484-50.231-14.23-89.62 10.238-123.963a.657.657 0 0 1 .835-.2l35.035 18.267a.659.659 0 0 1 .559.351.65.65
          0 0 1-.032.659c-19.461 29.688-21.365 63.46-5.993 106.281 1.055 2.939 1.149 5.762 1.24 8.492.103 3.083.21 6.271 1.726 9.651a.647.647 0 0 1 .057.267v.003a.661.661
           0 0 1-.038.22l-.074.204c-.16.446-.319.892-.476 1.339a168.22 168.22 0 0 0-.54 1.541c-.179.511-.357 1.022-.533 1.534l-.142.416-.38 1.112a.655.655 0 0
            1-.255.333c-1.634 1.267-3.939 2.952-5.717 3.84a.707.707 0 0 1-.16.056c-.253.052-.545.121-.876.199-1.668.396-4.235 1.005-7.986 1.005Zm-7.726-2.094c8.092
             1.555 12.85.426 15.41-.181.275-.066.552-.13.828-.19
              1.633-.83 3.754-2.375 5.309-3.576l.317-.927.142-.416c.176-.511.354-1.024.535-1.54.178-.516.358-1.031.541-1.546.153-.436.308-.871.465-1.306-1.52-3.521-1.628-6.763-1.733-9.901-.092-2.75-.179-5.348-1.164-8.094-7.787-21.692-11.083-40.862-10.075-58.605.995-17.507
               6.157-33.273 15.776-48.174l-34.139-17.8c-23.9 33.86-26.993 72.746-9.74 122.314 2.919 8.39 6.491 17.245 10.616 26.322a256.364 256.364 0 0 0 5.188 2.746c.574.293 1.148.584 1.723.874h.001Z'
        fill='#1B75BB'
      />
      <path
        d='M109.986 186.684c-12.89-7.049-25.305-15.443-36.015-24.205l-.15-.548c-3.826-14.033-6.468-26.73-8.033-38.34-.442-3.279-.8-6.472-1.072-9.585-3.782-43.102 8.429-70.698 30.122-98.042l.24-.303c.285-.36.572-.72.862-1.08.189-.011 2.415.24
         2.64.226l10.475 21.725.356.207C99.014 51.328 92.841 66.42 90.057 81.987c-4.456 24.881-.253 50.966 9.2 78.122 2.948 8.472 6.516 17.32 10.729 26.575Z'
        fill='#87B4D7'
      />
      <path
        d='M71.67 125a164.66 164.66 0 0 0-5.882-1.41c-.442-3.279-.8-6.472-1.072-9.584 2.425 3.224 5.136 7.201 6.955 10.994Z'
        fill='#1B75BB'
      />
      <path
        d='M71.67 125.652a.658.658 0 0 1-.163-.021 163.333 163.333 0 0 0-5.86-1.404.657.657 0 0 1-.507-.55 208.432 208.432 0 0 1-1.075-9.615.654.654 0 0 1 1.173-.449c3.106 4.13 5.469 7.866 7.022 11.105a.649.649 0
         0 1-.274.853.647.647 0 0 1-.316.082v-.001Zm-5.295-2.601c1.36.304 2.717.625 4.07.963-1.227-2.34-2.853-4.926-4.858-7.721.226 2.257.489 4.51.788 6.758Zm43.1-86.292c-2.732 3.83-4.934
          7.356-7.049 11.088l-.003-.003c-.16-.29-12.513-25.194-12.553-25.272.751-1.125 4.536-5.987 6.134-7.971.232-.016 2.204-.145 2.452-.16l10.663 22.112c.121.068.238.138.356.206Z'
        fill='#1B75BB'
      />
      <path
        d='M102.426 48.5a.652.652 0 0 1-.574-.34c-.021-.037-.065-.116-1.54-3.085-3.348-6.733-11.027-22.213-11.027-22.213a.653.653 0 0 1 .042-.652c.195-.293 2.298-3.136 5.062-6.63.366-.465.735-.928 1.106-1.388a.654.654
         0 0 1 .467-.242c.18-.012.556.018 1.44.094.468.04 1.175.1 1.318.095.26-.01.515.136.627.372l10.275 21.578.045.027.134.078a.645.645 0 0 1 .312.427.663.663 0 0 1-.106.517c-.322.451-.641.905-.956 1.36l-.579.844a113.242
          113.242 0 0 0-1.496 2.248l-.144.223c-.173.269-.347.538-.517.805l-.2.318a120.155 120.155 0 0 0-2.482 4.117l-.035.061c-.202.352-.404.704-.603 1.055a.654.654 0 0 1-.569.332ZM90.63 22.634a39669.37 39669.37
           0 0 0 11.833 23.834l.001-.002.035-.062a122.284 122.284 0 0 1 2.51-4.161l.201-.32c.174-.273.349-.545.523-.815l.132-.205.015-.024c.256-.392.512-.784.772-1.173.245-.368.492-.735.741-1.1l.581-.846c.197-.285.396-.57.595-.852a.665.665
            0 0 1-.04-.073L98.337 15.429c-.35-.025-.699-.053-1.048-.084-.327-.03-.653-.056-.98-.08a237.696 237.696 0 0 0-5.679 7.368Z'
        fill='#1B75BB'
      />
      <path
        d='M110.05 187.358a.649.649 0 0 1-.314-.08c-12.718-6.955-25.207-15.348-36.115-24.272a.654.654 0 0 1-.217-.335l-.15-.548c-9.639-35.36-12.104-63.003-7.758-86.994 3.756-20.73
         12.667-39.096 28.893-59.549l.241-.304c.287-.362.575-.723.865-1.083a.654.654 0 0 1 .466-.243c.197-.013.713-.01 1.478-.003a40.5 40.5 0 0 0 1.054.003.654.654 0 0 1 .628.368l10.5 21.772.182.105a.654.654
          0 0 1 .204.943C99.947 51.255 93.653 65.97 90.764 82.123c-4.093 22.856-1.092 48.302 9.174 77.792 2.941 8.451 6.543 17.374 10.707 26.52a.656.656 0 0 1-.595.923Zm-35.44-25.23c10.317
           8.422 22.045 16.365 34.035 23.056-3.839-8.556-7.18-16.904-9.941-24.84-10.342-29.707-13.36-55.369-9.226-78.45 2.847-15.92 9.27-31.053 19.092-44.987a.863.863 0 0 1-.039-.07l-10.407-21.58c-.181
            0-.412-.002-.697-.004-.358-.003-.809-.008-1.104-.006l-.669.84-.24.303C67.6 51.448 54.403 87.998 74.516 161.78l.094.347Z'
        fill='#1B75BB'
      />
      <path
        d='M175.965 94.368c-2.441 8.926-9.354 15.406-17.051 20.547-2.438 1.629-4.952 3.122-7.43 4.517a4.049 4.049 0 0 1-3.978-7.052c3.024-1.7 6.108-3.556 8.954-5.614a52.254 52.254
         0 0 0 3.105-2.42c4.067-3.438 7.3-7.412 8.586-12.111 1.301-4.768.585-9.974-2.198-15.835a39.597 39.597 0 0 0-.836-1.669 50.277 50.277 0 0 0-.881-1.587 4.043 4.043 0 0 1 1.479-5.527
          4.053 4.053 0 0 1 5.536 1.478 53.542 53.542 0 0 1 1.664 3.104c3.892 7.895 4.919 15.339 3.05 22.169Z'
        fill='#87B4D7'
      />
      <path
        d='M149.5 120.607a4.72 4.72 0 0 1-4.677-4.137 4.662 4.662 0 0 1 .142-1.828 4.665 4.665 0 0 1 2.221-2.831c3.615-2.032 6.435-3.799 8.873-5.56a52.339 52.339 0 0 0 3.085-2.404c4.51-3.813
         7.25-7.668 8.377-11.785 1.264-4.63.558-9.662-2.158-15.383a37.393 37.393 0 0 0-.824-1.642c-.28-.528-.57-1.05-.869-1.567a4.701 4.701 0 0 1 5.29-6.89 4.68 4.68 0 0 1 2.857 2.188 54.16 54.16 0 0 1 1.685 3.142c3.962 8.039
          5.003 15.653 3.094 22.63-2.134 7.805-7.638 14.452-17.318 20.917-2.186 1.461-4.56 2.905-7.473 4.543a4.696 4.696 0 0 1-2.304.607h-.001Zm18.239-52.883a3.395 3.395 0 0 0-2.938 5.093c.308.531.606 1.068.893 1.61.297.558.581
           1.123.85 1.695 2.855 6.012 3.587 11.34 2.237 16.284-1.198 4.38-4.075 8.448-8.794 12.438a53.14 53.14 0 0 1-3.052 2.381c-.017.012-.034.023-.05.037a.435.435 0 0 1-.042.033c-2.483 1.795-5.348 3.591-9.017 5.653a3.373 3.373
            0 0 0-1.603 2.044 3.366 3.366 0 0 0 .313 2.578 3.4 3.4 0 0 0 4.626 1.293c2.884-1.623 5.231-3.049 7.389-4.491 9.404-6.281 14.737-12.692 16.784-20.177 1.822-6.66.811-13.964-3.006-21.708a52.41 52.41 0 0 0-1.644-3.066
             3.371 3.371 0 0 0-2.063-1.58 3.418 3.418 0 0 0-.884-.117h.001Z'
        fill='#1B75BB'
      />
      <path
        d='M166.851 40.378c3.019 2.89 4.21 5.724 5.336 10.655 3.717 4.672 6.063 15.506 2.197 19.938-.003.008-.006.012-.011.014-2.091 2.201-6.028 3.52-8.927
         3.706h-.004c-1.644.235-3.696-.046-5.767-1.07-6.247-3.202-6.366-9.955-10.97-10.834-4.127-.79-7.574-1.41-6.366-4.765 1.363-3.79 8.373-3.488 12.112-.485-.4-4-2.554-8.39-6.804-12.348-1.872-1.74-4.009-3.303-2.53-5.444 1.019-1.48 2.665-1.332
          4.701-.192-.337-1.074.078-2.336 1.08-3.239 3.089-2.772 7.324 2.011 9.48 4.566 1.529-2.614 4.857-2.046 6.473-.502Z'
        fill='#87B4D7'
      />
      <path
        d='M164.338 75.42c-2.344 0-4.933-.892-6.878-2.422-2.111-1.621-3.378-3.63-4.496-5.404-1.333-2.114-2.386-3.785-4.382-4.165l-.132-.025c-3.059-.586-5.7-1.091-6.656-2.765-.444-.779-.468-1.733-.07-2.838.509-1.413 1.695-2.419 3.43-2.906
         2.565-.721 5.889-.186 8.425 1.265-.756-3.653-2.981-7.33-6.378-10.494-.18-.167-.363-.332-.545-.497-1.751-1.59-3.737-3.392-2.076-5.794.985-1.432 2.492-1.738 4.488-.914.094-.95.583-1.901 1.392-2.631.818-.734 1.762-1.069 2.809-.996
          2.466.171 4.927 2.528 7.066 4.992.661-.753 1.531-1.22 2.557-1.363 1.537-.215 3.268.352 4.41 1.444 3.209 3.072 4.417 6.19 5.489 10.839 3.778 4.878 6.204 15.952 2.089 20.652l-.016.018c-.463.528-.919.85-1.252
           1.087-.098.069-.187.131-.263.192a.654.654 0 0 1-.81-1.025 7.85 7.85 0 0 1 .317-.232c.311-.22.663-.47 1.024-.88l.013-.016c3.692-4.208 1.264-14.727-2.218-19.103a.651.651 0 0
            1-.125-.26c-1.047-4.582-2.131-7.438-5.151-10.329-.851-.814-2.187-1.254-3.326-1.094-.925.13-1.642.619-2.13 1.454a.66.66 0 0 1-.508.321.655.655 0 0 1-.556-.23c-1.261-1.493-4.214-4.992-6.7-5.165-.689-.048-1.293.17-1.845.664-.802.724-1.153
             1.728-.892 2.559a.658.658 0 0 1-.213.701.652.652 0 0 1-.73.063c-2.463-1.378-3.347-.728-3.843-.007-.916 1.325-.131 2.263 1.879 4.087.186.168.372.337.556.508 4.078 3.797 6.567 8.33 7.01 12.762a.652.652 0 0
              1-1.06.573c-2.257-1.813-5.926-2.627-8.533-1.894-.915.257-2.1.828-2.555 2.092-.266.738-.274 1.31-.023 1.749.657 1.15 3.139 1.626 5.766 2.129l.131.025c2.554.487 3.86 2.559 5.243 4.752 1.059 1.68 2.26 3.585
               4.192 5.069 2.023 1.59 4.803 2.404 7.088 2.078l.021-.003c.311-.04.619-.098.923-.173a.655.655 0 0 1 .793.885.655.655 0 0 1-.446.374 9.329 9.329 0 0 1-1.113.21l-.006.001a8.477 8.477 0 0 1-1.185.08h.001Z'
        fill='#1B75BB'
      />
      <path
        d='M164.721 52.322a.654.654 0 0 1-.648-.575c-.356-2.965-1.64-4.75-2.773-6.324-1.112-1.547-2.073-2.882-1.548-4.721a.657.657 0 0 1 .807-.446.653.653 0 0 1 .45.804c-.336 1.177.232 2.044 1.352 3.6 1.164 1.619 2.613 3.633
         3.009 6.931a.663.663 0 0 1-.16.511.662.662 0 0 1-.49.22h.001Zm-6.807 1.54a.652.652 0 0 1-.643-.542c-.604-3.458-2.05-5.531-3.386-7.102a45.711 45.711 0 0 0-1.37-1.517c-1.402-1.506-2.726-2.929-3.324-4.965a3.76 3.76 0 0
          1-.058-.209.66.66 0 0 1 .255-.68.646.646 0 0 1 .486-.114.651.651 0 0 1 .531.495c.011.045.025.09.038.135.513 1.746 1.679 2.998 3.03 4.449.441.474.898.965 1.351 1.495l.024.03c.008.008.015.014.022.023 1.369 1.606 3.025
           3.938 3.688 7.736a.645.645 0 0 1-.368.704.656.656 0 0 1-.276.061Zm8.631 11.852a.657.657 0 0 1-.646-.56c-.25-1.738-1.918-3.676-4.623-4.017a.654.654 0 1 1 .163-1.295c3.354.422 5.433 2.898 5.753 5.126a.652.652 0 0 1-.647.746Z'
        fill='#1B75BB'
      />
      <path
        d='M165.451 63.365a.654.654 0 0 1-.652-.618c-.07-1.328.597-2.892 1.742-4.081a.654.654 0 0 1 .942.905c-.889.923-1.43 2.142-1.378 3.107a.66.66 0 0 1-.395.634.658.658 0 0 1-.258.053h-.001ZM154.52 59.52a.656.656 0 0
         1-.654-.65c-.012-4.523-2.38-9.21-6.665-13.202a.655.655 0 0 1-.032-.922.645.645 0 0 1 .455-.207.652.652 0 0 1 .468.174c4.553 4.24 7.067 9.266 7.081 14.153a.669.669 0 0 1-.19.462.662.662 0 0 1-.462.193h-.001Zm3.393
          56.055c-2.438 1.629-3.951 2.464-6.429 3.858a4.048 4.048 0 1 1-3.978-7.052c3.024-1.7 6.108-3.556 8.955-5.613-3.354 2.433-2.614 9.206 1.453 8.807h-.001Z'
        fill='#1B75BB'
      />
      <path
        d='M149.5 120.607a4.72 4.72 0 0 1-4.677-4.137 4.663 4.663 0 0 1 .142-1.828 4.665 4.665 0 0 1 2.221-2.831c3.624-2.037 6.449-3.808 8.891-5.573a.656.656 0 0 1 .913.145.649.649 0 0 1-.145.911l-.002.001c-1.822 1.324-2.215
         4.079-1.533 5.873.275.722.97 1.91 2.54 1.756a.653.653 0 0 1 .427 1.192c-2.317 1.548-3.801 2.382-6.046 3.645l-.426.239a4.699 4.699 0 0 1-2.304.607h-.001Zm4.5-11.364c-1.802 1.172-3.823 2.385-6.173 3.706a3.38 3.38 0 0
          0-1.604 2.044 3.381 3.381 0 0 0 1.561 3.843 3.382 3.382 0 0 0 3.378.028l.427-.241c1.73-.972 3.003-1.688 4.557-2.676-.909-.401-1.633-1.198-2.057-2.316-.488-1.281-.545-2.906-.089-4.389v.001Z'
        fill='#1B75BB'
      />
      <path
        d='M158.137 147.788c-.277.35-.549.7-.818 1.057-6.866 9.055-15.088 1.828-19.05 12.843-12.679-40.566-15.134-72.986 6.523-106.587 0 0 11.795-.784 31.724-.888-10.436 10.924-17.208 22.678-20.773 35.35-4.95 17.572-3.743 36.905 2.394 58.225Z'
        fill='#87B4D7'
      />
      <path
        d='m73.886 161.951.149.548c12.663 10.36 27.644 20.169 43.218 27.98 1.27.246 2.462.429 3.575.556 7.054.812 11.061-.509 13.094-.929 1.657-.828 3.845-2.402 5.643-3.799a304.678 304.678 0 0 1 3.745-10.469c.149-.392.288-.755.418-1.089.842-2.183
         1.263-3.156 1.263-3.188.434-1.06.878-2.117 1.333-3.167l.002-.01a.033.033 0 0 0 .005-.016c3.105-7.146 6.942-14.443 11.759-20.521l.005-.005c-.008-.016-.013-.034-.021-.05v-.003c-6.753-16.711-11.236-6.143-19.343
          14.17-.07.177-.057.146-.068.169l-.002-.002c-10.842-12.488-39.16-16.997-39.183-1.865l-.157-.133c-13.946-11.814-26.661-12.278-25.435 1.823Z'
        fill='#F1F4F5'
      />
      <path
        d='M120.828 191.037a47.142 47.142 0 0 1-3.575-.556c-15.575-7.812-30.556-17.62-43.219-27.98l-.149-.549c-1.225-14.101 11.49-13.636 25.436-1.822l.157.133c9.529 6.882 14.735 15.389 17.301 24.271a394.028 394.028 0 0 0 4.049 6.503Z'
        fill='#F1F4F5'
      />
      <path
        d='M120.828 191.689a.628.628 0 0 1-.075-.004 48.297 48.297 0 0 1-3.625-.564.648.648 0 0 1-.169-.057c-15.289-7.669-30.275-17.371-43.339-28.059a.652.652 0 0 1-.217-.334l-.148-.548a.67.67 0 0 1-.02-.114c-.576-6.619 1.885-9.341
         4.052-10.46 4.95-2.555 13.554.542 22.456 8.083l.138.117c8.713 6.299 14.601 14.548 17.502 24.52a379.417 379.417 0 0 0 3.996 6.419.649.649 0 0 1-.219.91.65.65 0 0 1-.332.091Zm-3.363-1.832c.7.134 1.391.251 2.066.351a351.12
          351.12 0 0 1-3.311-5.337.684.684 0 0 1-.068-.157c-2.812-9.73-8.551-17.779-17.057-23.922a.687.687 0 0 1-.04-.032l-.157-.133c-8.361-7.083-16.609-10.191-21.011-7.918-2.587 1.336-3.747 4.492-3.356 9.129l.079.29c12.93 10.556
           27.741 20.141 42.855 27.729Zm59.051-135.644c-2.463 2.559-4.873 5.385-7.128 8.323-.274.023-23.209 3.247-31.08 3.94 1.882-3.873 4.101-7.735 6.479-11.368 11.198 0 25.39-.86 31.729-.895Z'
        fill='#1B75BB'
      />
      <path
        d='M144.791 55.753a.654.654 0 0 1-.545-1.01c.975-1.487 1.037-3.148 1.103-4.905.078-2.06.158-4.191 1.734-6.112a.643.643 0 0 1 .441-.236.656.656 0 0 1 .64.342.657.657 0 0 1-.07.722c-1.298 1.581-1.367 3.404-1.44 5.333-.07
         1.859-.142 3.78-1.316 5.57a.645.645 0 0 1-.547.296Zm-35.317-18.341a.65.65 0 0 1-.58-.354.656.656 0 0 1 .048-.677c2.474-3.48 5.218-6.98 8.157-10.402a.656.656 0 0 1 .916-.064.65.65 0 0 1 .076.914 143.292 143.292 0 0 0-8.083
         10.308.655.655 0 0 1-.534.275Z'
        fill='#1B75BB'
      />
      <path d='m144.625 55.082.002.003-.007-.003h.005Z' fill='#F1F4F5' />
      <path
        d='M144.627 55.738a.666.666 0 0 1-.557-.304.647.647 0 0 1 .123-.845.65.65 0 0 1 .426-.16h.002a.666.666 0 0 1 .471.197l.003.003a.65.65 0 0 1-.106 1 .656.656 0 0 1-.362.109Z'
        fill='#1B75BB'
      />
      <path d='M144.792 55.102c-.003.002-.003.005-.005.007h-.121l.126-.007Z' fill='#F1F4F5' />
      <path
        d='M144.787 55.76h-.121a.655.655 0 0 1-.652-.632.652.652 0 0 1 .612-.672l.125-.007a.655.655 0 0 1 .694.676.66.66 0 0 1-.112.341.66.66 0 0 1-.546.294Z'
        fill='#1B75BB'
      />
      <path
        d='M144.792 55.102c11.141.037 12.195.007-.005.007.002-.002.002-.005.005-.007Z'
        fill='#F1F4F5'
      />
      <path
        d='M153.01 55.772c-.379.002-.947 0-1.707-.002l-3.831-.009h-2.685a.657.657 0 0 1-.578-.349.645.645 0 0 1 .039-.673.645.645 0 0 1 .546-.292l2.673.009c1.658 0 2.965.002 3.936.003l1.615.003c.149 0
         .274.001.381.004h.13v.005c.331.02.449.087.584.32a.648.648 0 0 1-.001.654c-.134.231-.246.299-.584.318v.007l-.141-.001c-.106.002-.23.003-.377.003Zm16.379 7.416a.654.654 0 0 1-.468-1.108.66.66 0 0 1 1.131.453.655.655 0 0 1-.663.655Z'
        fill='#1B75BB'
      />
      <path
        d='M73.8 125.607c-.728 0-1.465-.196-2.13-.606-4.651-2.877-8.603-5.95-11.883-9.133-.481-.467-.946-.934-1.396-1.404l-.008-.008c-.29-.3-.572-.601-.844-.903-.005-.006-.01-.008-.016-.013a48.229 48.229 0
         0 1-3.068-3.697c-10.938-14.613-8.925-30.31 3.568-39.346 2.87-2.08 6.296-3.806 10.242-5.086a4.055 4.055 0 0 1 5.105 2.6 4.048 4.048 0 0 1-2.604 5.1c-21.193 6.871-19.424 26.262-.744 40.896a65.425 65.425 0 0
          0 5.914 4.112c3.458 2.138 1.937 7.488-2.135 7.488Z'
        fill='#87B4D7'
      />
      <path
        d='M71.671 125.652a.653.653 0 0 1-.343-.098c-16.936-10.472-26.084-24.683-24.473-38.015 1.27-10.5 9-18.791 21.208-22.75a.654.654 0 0 1 .698.197l5.802 6.775a.653.653 0 0 1-.345 1.06c-10.106 2.41-16.439
         7.565-17.832 14.517-1.725 8.603 4.31 19.12 15.752 27.446a.657.657 0 0 1 .256.683.653.653 0 0 1-1.026.372c-5.89-4.287-10.501-9.215-13.333-14.251-2.816-5.007-3.83-10.023-2.93-14.506 1.45-7.236 7.748-12.633
          17.78-15.273l-4.832-5.642c-11.464 3.846-18.709 11.673-19.9 21.528-1.55 12.814 7.371 26.552 23.863 36.749a.655.655 0 0 1 .283.734.656.656 0 0 1-.628.474Zm27.902-54.415a.654.654 0 0 1-.5-1.072c3.745-4.459
           8.881-6.6 14.089-5.874.487.068.97.158 1.448.273a.653.653 0 1 1-.304 1.27 14.086 14.086 0 0 0-1.325-.25c-4.753-.662-9.457 1.313-12.907 5.42a.648.648 0 0 1-.5.233Z'
        fill='#1B75BB'
      />
      <path
        d='M104.959 102.535c-5.367-1.917-8.94-9.362-6.135-17.819 4.749-14.286 21.314-14.843 23.084-.543l-.008.002c1.434 11.613-9.042 21.712-16.941 18.36Z'
        fill='#F1F4F5'
      />
      <path
        d='M156.494 79.401a.653.653 0 0 1-.644-.552c-.462-2.965-2.614-5.377-5.616-6.294a.651.651 0 0 1-.113-1.198c.152-.082.33-.1.495-.05 3.485 1.064 5.986 3.877 6.525 7.341a.657.657 0 0 1-.15.525.645.645 0 0 1-.496.228h-.001Z'
        fill='#1B75BB'
      />
      <path
        d='M149.791 101.812c-9.94 8.557-20.025-2.17-15.696-14.132 4.739-13.037 19.989-13.113 21.254.07l-.002.006c.552 5.801-1.993 10.953-5.555 14.056h-.001Z'
        fill='#F1F4F5'
      />
      <path
        d='M108.18 103.797c-2.093 0-4.116-.656-5.901-1.975-3.926-2.9-6.71-9.376-4.075-17.311 1.256-3.779 3.395-6.856 6.186-8.901 2.577-1.889 5.572-2.772 8.433-2.49 5.23.518 8.959 4.722 9.733 10.973a.75.75 0 0 1
         .004.106c.761 6.502-2.261 13.552-7.373 17.177-2.27 1.611-4.682 2.421-7.007 2.421Zm3.639-29.42c-4.427 0-9.926 3.18-12.375 10.545-2.428 7.314.064 13.231 3.611 15.85 3.292 2.431 7.545 2.259
          11.375-.461h.001c4.764-3.377 7.569-9.98 6.82-16.055a.686.686 0 0 1-.005-.102c-.73-5.64-3.921-9.276-8.552-9.735a8.883 8.883 0 0 0-.875-.042Zm30.639 31.164a8.326 8.326 0 0
           1-3.408-.714c-2.811-1.112-4.931-3.538-5.97-6.833-1.026-3.25-.883-6.991.401-10.536 2.84-7.816 9.142-10.74 13.797-10.15 4.823.612 8.165 4.59 8.721 10.381a.433.433 0 0 1
            .003.051c.556 5.983-2.153 12.046-6.91 15.456-2.161 1.55-4.458 2.345-6.634 2.345Zm3.621-27.002c-4.014 0-8.984 2.8-11.369 9.364-2.415 6.664-.247 13.714 4.833 15.716l.027.011c2.612
             1.169 5.805.624 8.76-1.494 4.398-3.154 6.897-8.773 6.367-14.317a.398.398 0 0 1-.003-.05c-.514-5.204-3.346-8.629-7.581-9.166a8.232 8.232 0 0 0-1.034-.064Z'
        fill='#1B75BB'
      />
      <path
        d='M147.39 103.501c-2.164 1.2-4.504 1.676-6.666 1.192-.011-4.25 2.89-9.03 5.364-8.31 2.225.65 2.039 4.912 1.303 7.118h-.001Z'
        fill='#1B75BB'
      />
      <path
        d='M142.458 105.536a8.588 8.588 0 0 1-1.877-.205.654.654 0 0 1-.511-.635c-.008-3.204 1.521-6.775 3.558-8.306.879-.661 1.793-.88 2.643-.633.702.206 1.276.702 1.661 1.437.894 1.707.707 4.63.078 6.514a.653.653 0 0
         1-.303.364c-1.748.97-3.533 1.464-5.249 1.464Zm-1.065-1.382c1.733.252 3.602-.124 5.453-1.1.477-1.62.585-4-.072-5.255-.223-.425-.507-.683-.87-.789-.444-.129-.945.013-1.49.423-1.572 1.182-2.866 4.107-3.021
          6.721Zm-27.708-2.595c-2.389 1.363-5.251 1.982-8.042 1.198-.023-4.94 3.408-10.294 6.351-9.433 2.595.757 2.603 5.516 1.691 8.235Z'
        fill='#1B75BB'
      />
      <path
        d='M108.163 103.752a9.952 9.952 0 0 1-2.697-.368.653.653 0 0 1-.476-.625c-.017-3.564 1.717-7.565 4.032-9.306 1.052-.791 2.143-1.053 3.156-.756.844.246 1.51.825 1.978 1.719 1.046 1.999.867 5.21.149 7.349a.65.65 0 0
         1-.295.359c-1.886 1.076-3.894 1.628-5.847 1.628Zm-1.856-1.503c2.198.478 4.597.081 6.834-1.135.558-1.857.658-4.562-.143-6.093-.301-.576-.69-.926-1.187-1.071-.61-.179-1.284.005-2.003.545-1.878 1.413-3.361 4.74-3.501 7.754Z'
        fill='#1B75BB'
      />
      <path
        d='M94.418 122.636c-3.603.126-7.874.931-11.295-3.626-4.172-5.563 1.126-13.005 9.472-15.383'
        fill='#87B4D7'
      />
      <path
        d='M91.133 123.42c-2.826 0-5.945-.572-8.533-4.019-1.62-2.161-2.013-4.678-1.135-7.279 1.36-4.034 5.66-7.615 10.951-9.123a.65.65 0 0 1 .359 1.255c-4.891 1.393-8.845 4.645-10.072 8.285-.734 2.176-.408 4.278.943 6.079
         2.842 3.785 6.25 3.601 9.547 3.423.407-.022.809-.044 1.202-.058a.656.656 0 0 1 .677.63.646.646 0 0 1-.176.468.659.659 0 0 1-.456.207c-.385.013-.778.035-1.176.056-.68.037-1.396.075-2.13.075v.001Z'
        fill='#1B75BB'
      />
      <path
        d='M85.288 112.781c2.14-4.933 11.198-9.085 19.788-5.306.145.064.297.132.437.197 10.5 4.901 11.051 15.113 6.09 17.784-5.69 3.064-8.622-4.099-12.951-5.494-5.003-1.614-9.614 1.704-13.02-2.833-.972-1.296-1.013-2.824-.345-4.348Z'
        fill='#1B75BB'
      />
      <path
        d='M108.876 126.867c-2.539 0-4.549-1.81-6.382-3.459-1.355-1.219-2.635-2.371-4.043-2.825-1.829-.59-3.63-.476-5.373-.366-2.791.175-5.677.357-7.97-2.696-1.062-1.417-1.212-3.192-.42-4.999 1.057-2.436
         3.657-4.66 6.956-5.952 4.37-1.71 9.361-1.598 13.695.309.149.065.305.135.449.201 6.828 3.188 9.379 8.45 9.49 12.312.085 2.977-1.173 5.459-3.365 6.64-1.096.59-2.103.835-3.038.835h.001Zm-13.696-8.043c1.177 0
          2.416.111 3.672.517 1.675.539 3.12 1.84 4.517 3.097 2.503 2.253 4.665 4.199 7.924 2.444 1.748-.942 2.75-2.98 2.679-5.453-.1-3.473-2.447-8.23-8.736-11.166a21.264 21.264 0 0 0-.423-.19c-4.018-1.768-8.644-1.873-12.692-.288-2.982
           1.168-5.313 3.132-6.234 5.256l-.001.002c-.421.961-.716 2.381.269 3.695 1.868 2.489 4.063 2.351 6.84 2.175.696-.043 1.428-.089 2.185-.089Z'
        fill='#1B75BB'
      />
      <path
        d='M94.265 110.358c-2.58-1.202-6.566 1.969-2.217 5.177 4.407 3.252 10.047.858 10.215-1.905.262-4.275-7.67-6.748-7.998-3.272Z'
        fill='#F1F4F5'
      />
      <path
        d='M96.736 117.742a8.476 8.476 0 0 1-5.076-1.683c-3.04-2.243-2.146-4.316-1.8-4.897.774-1.3 2.455-1.955 3.947-1.641.442-1.025 1.568-1.567 3.11-1.448 2.636.205 6.192 2.432 5.999 5.595-.081 1.327-1.121
         2.581-2.781 3.355-.905.423-2.093.719-3.399.719Zm-3.677-6.99c-.825 0-1.676.405-2.075 1.077-.554.931-.025 2.09 1.452 3.18 2.552 1.883 5.445 1.625 7.146.831 1.201-.56 1.979-1.423
          2.029-2.252.143-2.334-2.744-4.055-4.794-4.214-.67-.051-1.805.015-1.902 1.044a.652.652 0 0 1-.927.53 2.193 2.193 0 0 0-.93-.196Z'
        fill='#1B75BB'
      />
      <path
        d='M97.45 112.336c-1.218-1.025-2.368-1.609-3.209-1.991-2.579-1.172-6.528 1.99-2.193 5.189'
        fill='#F1F4F5'
      />
      <path
        d='M92.047 116.188a.652.652 0 0 1-.387-.128c-3.029-2.235-2.148-4.304-1.806-4.885.89-1.514 3.024-2.165 4.658-1.423.891.405 2.079 1.008 3.358 2.086a.651.651 0 0 1-.365 1.149.65.65 0 0
         1-.477-.151c-1.158-.975-2.242-1.525-3.057-1.895-1.017-.463-2.443-.036-2.99.896-.548.93-.017 2.086 1.455 3.173a.65.65 0 0 1 .233.729.654.654 0 0 1-.621.449Zm46.26-49.059a.656.656 0 0
          1-.651-.618.655.655 0 0 1 .064-.32 103.93 103.93 0 0 1 6.52-11.44.646.646 0 0 1 .547-.295c6.591 0 14.079-.293 20.686-.551 4.522-.177 8.427-.33 11.039-.345a.664.664 0 0 1
           .604.396.65.65 0 0 1-.129.71 97.786 97.786 0 0 0-7.08 8.268.661.661 0 0 1-.465.253l-1.789.244c-5.615.77-22.703 3.116-29.288 3.695a.604.604 0 0 1-.057.003h-.001Zm6.834-11.368a103.325 103.325 0 0
            0-5.73 9.956c7.219-.719 22.762-2.852 28.064-3.58l1.56-.213a100.612 100.612 0 0 1 5.945-7.037c-2.489.05-5.767.178-9.456.322-6.501.255-13.854.542-20.383.552Z'
        fill='#1B75BB'
      />
      <path
        d='M144.787 55.109a103.877 103.877 0 0 0-6.48 11.367h-.002c-.329-.188-.917 1.385-35.879-18.625 1.812-3.262 4.383-7.364 7.049-11.091.255.14 34.958 18.217 35.144 18.323l.008.002.003.003h.002l.008.005a.01.01 0 0 0 .006.003l.021.013h.12Z'
        fill='#1B75BB'
      />
      <path
        d='M138.308 67.13a.67.67 0 0 1-.194-.03c-2.633-.263-15.418-6.896-36.013-18.683a.65.65 0 0 1-.246-.883c1.967-3.54 4.551-7.605 7.088-11.153a.654.654 0 0 1 .846-.193c.195.105 8.488 4.426 16.507 8.605 11.712
         6.104 17.677 9.213 18.538 9.665a.652.652 0 0 1 .498 1.01 102.659 102.659 0 0 0-6.437 11.294.655.655 0 0 1-.588.367h.001Zm-34.995-19.524c26.594 15.204 33.069 17.76 34.626 18.14a104.248 104.248 0 0 1
          5.878-10.347c-2.086-1.09-10.55-5.5-18.125-9.448-8.573-4.467-14.323-7.464-16.015-8.348-2.241 3.17-4.57 6.832-6.365 10.003h.001Z'
        fill='#1B75BB'
      />
      <path
        d='M92.164 50.544c-3.604 3.21-5.322 4.695-6.61 7.731-.627 4.645-2.125 8.17-4.231 10.587-3.58 4.125-8.915 5.039-14.657 2.812-2.332-.909-5.217-3.65-6.322-6.739-.753-2.094-1.292-4.287-1.454-6.378-.337-4.303.913-8.162 5.173-9.77.572-2.82
         1.066-4.282 2.783-6.584 3.079-4.13 8.91-7.84 10.073-3.24 2.645-2.444 9.124-5.358 10.953-1.677 1.61 3.24-4.38 7.206-4.71 7.556 1.399-.906 3.158-1.856 5.468-2.958v-.002c2.726-1.692 5.996-2.327 7.164.67.802 2.058-.76 5.439-3.63 7.992Z'
        fill='#87B4D7'
      />
      <path
        d='M72.547 73.51c-.506 0-1.021-.028-1.544-.083a.654.654 0 1 1 .138-1.299c7.338.782 12.483-4.43 13.765-13.94a.655.655 0 0 1 .046-.169c1.318-3.104 3.063-4.657 6.54-7.753l.237-.21c2.662-2.369
         4.148-5.493 3.456-7.267-.29-.743-.721-1.227-1.317-1.479-1.066-.45-2.426-.065-3.379.337-.51.216-1.002.471-1.472.763a.642.642 0 0 1-.105.063c-2.305 1.099-4.019 2.026-5.394 2.916a.653.653 0 0
          1-.831-.995l.019-.021c.04-.047.102-.117.195-.185 1.697-1.233 5.515-4.342 4.386-6.612-.273-.55-.672-.926-1.218-1.148-1.285-.524-3.415-.151-5.699.997-1.145.574-2.24 1.309-3.007 2.016a.655.655 0 0
           1-1.078-.32c-.219-.868-.612-1.376-1.2-1.552-1.755-.532-5.288 1.768-7.715 5.023-1.613 2.163-2.101 3.538-2.667 6.324a.653.653 0 0 1-.41.48C61.107 50.6 59.49 53.25 59.49 57.27c0 2.192.509 4.767 1.47
            7.445.727 2.022 2.072 3.532 3.071 4.443a.653.653 0 1 1-.88.964c-1.11-1.01-2.603-2.692-3.421-4.966-1.012-2.818-1.547-5.545-1.547-7.886 0-4.413 1.835-7.502 5.312-8.959.556-2.65 1.115-4.2 2.828-6.498
             2.436-3.267 6.467-6.302 9.14-5.493.564.17 1.27.578 1.75 1.543a15.882 15.882 0 0 1 2.571-1.604c1.256-.631 4.432-1.996 6.779-1.039.842.342 1.48.94 1.896 1.776.681 1.37.324 2.988-1.004
              4.732.28-.138.562-.274.844-.408.563-.349 1.13-.643 1.684-.875 1.698-.717 3.218-.833 4.395-.336.925.39 1.607 1.133 2.026 2.207.909 2.33-.656 5.914-3.804 8.716l-.236.21c-3.404 3.03-4.975
               4.428-6.173 7.209-.702 5.097-2.514 9.162-5.243 11.757-2.29 2.176-5.17 3.303-8.4 3.303Z'
        fill='#1B75BB'
      />
      <path
        d='M76.46 53.338a.654.654 0 0 1-.572-.966c1.947-3.559 3.228-5.688 6.919-8.08a.654.654 0 0 1 .711 1.095c-3.434 2.225-4.585 4.14-6.483 7.61a.654.654 0 0 1-.574.34Zm-8.252-1.81a.654.654 0 0 1-.618-.864c1.69-4.93
         4.275-8.467 8.918-12.21a.654.654 0 1 1 .82 1.016c-4.435 3.575-6.898 6.94-8.502 11.617a.653.653 0 0 1-.618.441Zm-4.401-.668a.655.655 0 0 1-.647-.75c.002-.012.18-1.196.276-1.513a.652.652 0 0 1 1.041-.315.653.653 0 0
          1 .21.695c-.034.113-.144.717-.235 1.326a.652.652 0 0 1-.645.556Zm79.921 124.542a.654.654 0 0 1-.602-.399 254.94 254.94 0 0 1-5.01-12.824c-7.788-21.692-11.083-40.862-10.075-58.606 1.009-17.764 6.31-33.737 16.205-48.83a.645.645 0 0
           1 .519-.295c1.317-.084 12.809-.788 31.747-.888a.653.653 0 0 1 .476 1.103c-23.611 24.713-29.422 54.248-18.286 92.947l.008.03.008.028a.651.651 0 0 1-.116.585c-4.192 5.289-8.005 11.943-11.656 20.341a.637.637 0 0 1-.023.061c-.45
            1.039-.889 2.083-1.318 3.131-.028.079-.076.197-.158.398-.379.93-.748 1.864-1.109 2.801a.651.651 0 0 1-.599.418l-.011-.001Zm1.432-119.67c-19.296 29.622-21.141 63.317-5.814 106.007a251.43 251.43 0 0 0
             4.362 11.268c.238-.6.413-1.029.529-1.314.066-.164.109-.268.127-.317a.632.632 0 0 1 .022-.061c.43-1.053.871-2.101 1.323-3.145a.505.505 0 0 1
              .023-.061c3.642-8.381 7.451-15.06 11.638-20.403-5.447-19.042-6.761-35.667-4.017-50.81 2.834-15.638 9.918-29.398 21.642-42.021-16.989.12-27.634.721-29.835.856Z'
        fill='#1B75BB'
      />
      <path
        d='M176.514 54.212 148.029 57l-4.671.457-28.83-14.686-6.594-3.798h-.003l-.12-.07c-.005-.011-.008-.014-.01-.014-.016 0-.4-.684-8.385-17.219-1.011-2.09-2.143-4.438-3.413-7.07h.003c1.338-.086
         4.364-.052 8.136.042 6.96.18 16.452.577 22.555.849 3.622.159 6.058.274 6.082.274l30.707 16.137 13.028 22.31Zm-18.419 93.631c-4.819 6.078-8.659 13.377-11.764 20.526 0 .005-.002.01-.005.016l-.002.01c-.455
          1.051-.9 2.107-1.333 3.167 0 .037-.56 1.326-1.681 4.277a303.475 303.475 0 0 0-3.745 10.469c-1.798 1.397-3.986 2.971-5.643 3.799-.57.117-1.33.318-2.302.522l-.039-6.862c-.73-6.448 2.23-17.124 7.132-21.766l.018-.042c7.202-18.045 12.133-32.037
           19.364-14.116Z'
        fill='#F1F4F5'
      />
      <path
        d='M131.619 191.282a.655.655 0 0 1-.653-.649l-.038-6.827c-.723-6.513 2.208-17.314 7.232-22.179l.862-2.165c4.749-11.946 8.181-20.578 12.537-20.589h.009c2.464 0 4.731 2.773 7.133 8.726a.658.658 0 0
         1-.094.649c-4.196 5.293-8.011 11.949-11.661 20.347l-.023.06c-.45 1.039-.89 2.083-1.318 3.132-.029.08-.078.201-.162.408a164.91 164.91 0 0 0-1.522 3.876h-.001l-.012.029a301.245 301.245 0 0 0-3.724
          10.418.663.663 0 0 1-.218.306c-1.636 1.271-3.962 2.973-5.752 3.867a.633.633 0 0 1-.16.056c-.25.051-.537.119-.863.196-.418.099-.892.211-1.437.325a.617.617 0 0 1-.134.014h-.001Zm19.948-51.103h-.004c-3.472.008-7.287
           9.605-11.326 19.765a3377.817 3377.817 0 0 1-.929 2.327.657.657 0 0 1-.146.204c-4.748 4.496-7.625 15.098-6.932 21.219a.548.548 0 0 1 .004.07l.034 6.054c.22-.05.427-.1.621-.146.271-.065.543-.127.815-.186
            1.631-.829 3.75-2.372 5.304-3.573a306.846 306.846 0 0 1 3.685-10.293l.015-.039a165.77 165.77 0 0 1 1.656-4.205.734.734 0 0 1 .021-.061 169.89 169.89 0 0 1 1.324-3.145.449.449 0 0 1
             .023-.061c3.632-8.363 7.434-15.032 11.614-20.372-2.064-5.017-4.008-7.559-5.779-7.559v.001Zm-8.209-82.069a.65.65 0 0 1-.296-.07l-28.831-14.687a.362.362 0 0 1-.029-.016l-6.562-3.78a.684.684 0 0
              1-.039-.021l-.072-.042a.494.494 0 0 1-.135-.089.643.643 0 0 1-.167-.21 6.955 6.955 0 0 1-.274-.515c-.475-.953-.945-1.909-1.411-2.866a5713.932 5713.932 0 0 1-10.128-20.93.654.654 0 0 1
               .561-.936c1.238-.078 3.915-.065 8.182.042 6.409.166 16.387.556 28.098 1.098l.541.025a.65.65 0 0 1 .286.074l30.707 16.138a.645.645 0 0 1 .26.249l13.029 22.31a.654.654 0 0 1-.197.867.653.653 0 0
                1-.303.11l-33.156 3.246a.653.653 0 0 1-.064.003ZM114.84 42.198l28.645 14.592 31.952-3.128-12.421-21.269-30.413-15.983-.407-.018c-11.703-.542-21.672-.931-28.071-1.097-3.326-.083-5.668-.11-7.1-.08
                 9.013 18.689 10.91 22.53 11.263 23.208l6.552 3.775Zm-6.654-3.833.019.012a.655.655 0 0 0-.404-.14c.138 0 .273.048.385.128Z'
        fill='#1B75BB'
      />
      <path
        d='M151.319 41.3c-2.831 3.884-12.32 3.766-20.59-.53a28.695 28.695 0 0 1-2.077-1.187 24.668 24.668 0 0 1-2.878-2.091c-1.206-1.019-2.202-2.084-2.972-3.148a13.349 13.349 0 0 1-.563-.842c-1.671-2.728-1.82-5.389-.153-7.252
         3.245-3.625 11.779-3.114 19.653.915a30.592 30.592 0 0 1 3.069 1.79 25.05 25.05 0 0 1 3.713 2.964 16.58 16.58 0 0 1 1.985 2.313c1.797 2.56 2.242 5.107.813 7.069Z'
        fill='#1B75BB'
      />
      <path
        d='M143.5 44.77c-3.974 0-8.693-1.145-13.073-3.42a29.702 29.702 0 0 1-2.123-1.215 25.326 25.326 0 0 1-2.952-2.146c-1.223-1.033-2.259-2.13-3.079-3.262a13.646 13.646 0 0 1-.59-.883c-1.878-3.065-1.908-5.99-.083-8.029 3.391-3.788
         12.178-3.458 20.438.77a30.511 30.511 0 0 1 3.132 1.828 25.655 25.655 0 0 1 3.809 3.04c.806.79 1.5 1.6 2.063 2.405 2.059 2.933 2.346 5.713.806 7.827-1.503 2.061-4.592 3.085-8.348 3.085Zm-14.297-20.287c-2.918
          0-5.306.724-6.629 2.202-1.413 1.578-1.332 3.94.223 6.476.168.274.346.54.534.8.758 1.047 1.722 2.067 2.865 3.032a24.082 24.082 0 0 0 2.802 2.036c.661.416 1.339.803 2.032 1.163 3.829 1.988 8.22 3.176 12.048 3.258 3.668.08
           6.481-.845 7.713-2.534 1.599-2.196.173-4.896-.819-6.31a16.039 16.039 0 0 0-1.908-2.221 24.394 24.394 0 0 0-3.616-2.885 29.795 29.795 0 0 0-3.006-1.754c-4.213-2.156-8.631-3.263-12.239-3.263Z'
        fill='#1B75BB'
      />
      <path
        d='M143.5 44.77c-3.974 0-8.693-1.145-13.073-3.42a29.702 29.702 0 0 1-2.123-1.215 25.326 25.326 0 0 1-2.952-2.146c-1.223-1.033-2.259-2.13-3.079-3.262a13.646 13.646 0 0 1-.59-.883c-1.878-3.065-1.908-5.99-.083-8.029
         3.391-3.788 12.178-3.458 20.438.77a30.511 30.511 0 0 1 3.132 1.828 25.604 25.604 0 0 1 3.809 3.04c.806.79 1.5 1.6 2.063 2.405 2.059 2.933 2.346 5.713.806 7.826-1.503 2.062-4.592 3.086-8.348 3.086Zm-14.297-20.287c-2.918
          0-5.306.724-6.629 2.202-1.413 1.578-1.332 3.94.223 6.476.168.274.346.54.534.8.758 1.047 1.722 2.067 2.865 3.032a24.082 24.082 0 0 0 2.802 2.036c.661.416 1.339.803 2.032 1.163 3.829 1.988 8.22 3.176 12.048 3.258 3.668.08
           6.481-.845 7.713-2.534 1.599-2.196.173-4.896-.819-6.31a16.039 16.039 0 0 0-1.908-2.221 24.394 24.394 0 0 0-3.616-2.885 29.795 29.795 0 0 0-3.006-1.754c-4.213-2.156-8.631-3.263-12.239-3.263Zm-4.032 167.465c-2.241
            0-4.902-.217-8.043-.826a.632.632 0 0 1-.168-.058c-15.289-7.668-30.276-17.371-43.34-28.058a.652.652 0 0 1-.216-.334l-.149-.549a.636.636 0 0 1-.02-.114c-.576-6.618 1.886-9.341 4.052-10.459 4.793-2.475 13.014.351 21.613
             7.38.419-3.638 2.572-6.293 6.29-7.722 4.358-1.675 10.63-1.521 17.208.423 6.447 1.906 12.265 5.277 16.046 9.286 5.633-14.093 9.082-22.048 13.214-22.055h.006c2.432 0 4.603 2.695 7.037 8.738a.658.658 0 0 1-.094.649c-4.196
              5.292-8.011 11.948-11.661 20.346a.533.533 0 0 1-.023.06c-.45 1.04-.889 2.084-1.318 3.132a11.5 11.5 0 0 1-.162.409c-.241.592-.742 1.823-1.522 3.875h-.001l-.011.029a305.295 305.295 0 0 0-3.725 10.418.646.646 0 0
               1-.218.306c-1.636 1.271-3.961 2.974-5.751 3.868a.652.652 0 0 1-.161.055c-.266.055-.566.126-.903.205-1.67.392-4.24.996-7.979.996h-.001Zm-7.705-2.091c8.051 1.547 12.819.427 15.386-.176.314-.074.598-.14.852-.195
                1.631-.829 3.751-2.371 5.305-3.572a305.428 305.428 0 0 1 3.684-10.293l.017-.039a162.792 162.792 0 0 1 1.523-3.878c.069-.169.113-.276.132-.327a.626.626 0 0 1 .021-.061 169.89 169.89 0 0 1 1.324-3.145.449.449 0 0
                 1 .023-.061c3.632-8.363 7.434-15.032 11.615-20.372-2.063-5.024-3.975-7.571-5.684-7.571h-.003c-3.531.006-7.605 10.214-12.322 22.035l-.051.127c-.019.049-.019.049-.026.063a.65.65 0 0 1-.475.376.655.655 0 0
                  1-.622-.215 12.475 12.475 0 0 0-.375-.42c-7.488-8.257-23.401-13.062-32.13-9.707-3.663 1.407-5.523 4.045-5.529 7.838a.649.649 0 0 1-.38.592.655.655 0 0 1-.696-.096l-.157-.133c-8.361-7.083-16.609-10.191-21.01-7.918-2.588
                   1.336-3.747 4.492-3.356 9.129l.078.29c12.93 10.556 27.742 20.141 42.855 27.729h.001Z'
        fill='#1B75BB'
      />
      <path
        d='M142.908 176.908a325.755 325.755 0 0 0-3.343 9.399c-1.798 1.397-3.986 2.972-5.643 3.799-2.214.455-7.289 2.193-16.669.374a207.433 207.433 0 0 1-4.367-2.251c3.525 1.086 4.767-.598 3.894-3.697 5.49 4.221 11.853 5.442 14.801-.765 2.405 2.469 7.822-.569 11.327-6.859Z'
        fill='#1B75BB'
      />
      <path
        d='M125.192 191.95c-2.244 0-4.911-.218-8.064-.829a.669.669 0 0 1-.168-.058c-1.47-.735-2.93-1.488-4.381-2.257a.657.657 0 0 1-.298-.828.656.656 0 0 1 .797-.373c1.383.426 2.415.399 2.904-.078.487-.475.547-1.475.169-2.818a.654.654 0 0
         1 .649-.829c.137.004.27.052.378.135 3.487 2.68 6.994 3.856 9.623 3.224 1.803-.433 3.213-1.695 4.19-3.752a.647.647 0 0 1 .483-.364.656.656 0 0 1 .575.189c.521.535 1.237.747 2.129.628 2.184-.289 5.553-2.673 8.158-7.349a.655.655 0 0
          1 1.176.07.655.655 0 0 1 .007.477 323.161 323.161 0 0 0-3.336 9.38.658.658 0 0 1-.217.305c-1.636 1.27-3.962 2.973-5.752 3.867a.698.698 0 0 1-.161.056c-.253.052-.544.121-.875.199-1.668.396-4.235 1.005-7.986 1.005Zm-7.727-2.094c8.093
           1.555 12.85.427 15.411-.181.275-.066.552-.13.828-.19 1.631-.829 3.751-2.372 5.305-3.573a417.844 417.844 0 0 1 1.615-4.646c-2.009 2.242-4.255 3.702-6.274 3.969-.97.128-1.837-.032-2.553-.467-1.137 2.008-2.712
            3.265-4.69 3.74-2.711.651-6.011-.239-9.411-2.514.063 1.29-.363 2.04-.801 2.467-.28.273-.616.472-1.006.596.524.269 1.05.535 1.576.799Z'
        fill='#1B75BB'
      />
      <g>
        <path
          d='M73.715 27.505c-2.525-1.627.478-5.254 2.781-2.995 1.443 1.414 1.563 3.526 1.538 5.525-1.922-1.756-2.926-1.632-4.32-2.53Z'
          fill='#F1F4F5'
        />
        <path
          d='M78.034 30.682a.644.644 0 0 1-.435-.17c-1.138-1.04-1.897-1.358-2.7-1.695-.48-.202-.977-.41-1.533-.77-.9-.578-1.35-1.461-1.238-2.422.12-1.035.881-1.972 1.892-2.33 1.01-.357 2.076-.082 2.928.754 1.647 1.615 1.757
           3.942 1.731 5.995a.646.646 0 0 1-.645.637ZM74.9 24.435a1.35 1.35 0 0 0-.45.079c-.54.19-.977.721-1.04 1.261-.054.465.172.876.654 1.187.461.297.885.475 1.334.663.575.242 1.204.506 1.972
            1.044-.074-1.381-.36-2.752-1.325-3.698-.418-.41-.814-.536-1.145-.536Z'
          fill='#1B75BB'
        />
        <path
          d='M74.62 18.916c-2.709-2.713 2.017-6.424 4.28-2.982 1.415 2.154.995 4.82.423 7.308-1.922-2.712-3.207-2.828-4.703-4.326Z'
          fill='#F1F4F5'
        />
        <path
          d='M79.323 23.887a.646.646 0 0 1-.526-.272c-1.156-1.63-2.029-2.242-2.953-2.89-.538-.377-1.095-.767-1.68-1.353-.915-.916-1.223-2.072-.847-3.173.41-1.197 1.552-2.11 2.845-2.274 1.284-.163 2.478.44 3.276 1.653 1.58 2.402 1.093
           5.29.514 7.808a.647.647 0 0 1-.629.501Zm-2.755-8.696c-.08 0-.162.005-.244.015-.802.102-1.537.682-1.786 1.411-.217.635-.03 1.272.538 1.842.506.506.993.847 1.508 1.208.709.497 1.49 1.044 2.393
            2.083.344-1.89.43-3.87-.617-5.462-.47-.715-1.1-1.097-1.792-1.097Z'
          fill='#1B75BB'
        />
      </g>
      <g>
        <path
          d='M186.753 52.835c3.417-.1 2.624-5.48-.95-4.623-2.237.536-3.596 2.552-4.748 4.544 2.906-.589 3.812.134 5.698.079Z'
          fill='#F1F4F5'
        />
        <path
          d='M186.547 53.495c-.644 0-1.182-.08-1.706-.159-.989-.148-1.923-.287-3.659.064a.639.639 0 0 1-.653-.263.66.66 0 0 1-.031-.713c1.173-2.03 2.635-4.245 5.157-4.85 1.287-.309 2.456.046 3.207.974.755.932.941
           2.275.463 3.34-.441.985-1.372 1.569-2.553 1.603a7.224 7.224 0 0 1-.226.004h.001Zm-3.378-1.626c.732 0 1.316.087 1.861.169.552.082 1.074.16 1.705.142.689-.02 1.178-.309
            1.414-.835.274-.61.157-1.418-.285-1.963-.306-.378-.886-.777-1.912-.53-1.582.38-2.708 1.657-3.619 3.054.278-.024.557-.037.837-.037h-.001Z'
          fill='#1B75BB'
        />
      </g>
      <g fill='#1B75BB'>
        <path d='M128.783 209.651a257.744 257.744 0 0 0-3.251-10.034c.191 3.145.126 5.882-.095 8.259l3.346 1.775Z' />
        <path
          d='M128.783 210.303a.646.646 0 0 1-.304-.076l-3.347-1.776a.65.65 0 0 1-.343-.635c.236-2.545.268-5.29.093-8.159a.655.655 0 0 1 .972-.604.65.65 0 0 1 .292.352 261.33 261.33 0 0 1 3.26 10.059.655.655 0 0
         1-.623.839Zm-2.661-2.799 1.582.839a257.39 257.39 0 0 0-1.414-4.443 50.415 50.415 0 0 1-.168 3.604Zm-5.439-2.954-3.599-3.719a.957.957 0 0 1-.264-.768.97.97 0 0 1 .419-.695l5.308-3.557s1.581 2.089-.09 4.009c-2.157 2.478-1.774 4.73-1.774 4.73Z'
        />
        <path
          d='M120.683 205.2a.656.656 0 0 1-.467-.199l-3.599-3.719a1.597 1.597 0 0 1-.444-1.288 1.603 1.603 0 0 1 .704-1.167l5.307-3.558a.65.65 0 0 1 .881.148c.657.868 1.478 2.995-.118 4.83-1.932 2.219-1.626 4.174-1.623 4.193a.661.661 0 0
         1-.013.28.663.663 0 0 1-.354.419.648.648 0 0 1-.274.061Zm1.644-8.46-4.726 3.168a.308.308 0 0 0-.049.469l2.564 2.65c.187-.972.67-2.278 1.85-3.635.857-.985.628-2.035.361-2.652Zm22.882 3.923a186.813 186.813 0 0
          1-8.885 2.484l-2.566-1.549c4.283-3.136 6.127-5.922 7.191-7.197.628-1.355 1.225-3.307 1.713-5.172.101-.387.641-.409.776-.033 1.075 2.983 2.143 5.896 3.152 8.711a2.117 2.117 0 0 1-1.381 2.756Z'
        />
        <path
          d='M136.324 203.798a.652.652 0 0 1-.336-.093l-2.566-1.549a.649.649 0 0 1-.048-1.082c3.535-2.588 5.388-4.951 6.495-6.363.194-.248.368-.469.525-.659.515-1.134 1.081-2.855 1.639-4.988a1.053
         1.053 0 0 1 1.61-.61c.188.126.331.309.406.522.478 1.324.954 2.635 1.423 3.929.589 1.621 1.169 3.217 1.73 4.783a2.756 2.756 0 0 1-.125 2.184 2.758 2.758 0 0 1-1.678 1.413 187.558 187.558 0 0
          1-8.915 2.493.621.621 0 0 1-.16.02Zm-1.406-2.259 1.509.911a186.6 186.6 0 0 0 8.591-2.409 1.466 1.466 0 0 0 .96-1.913c-.573-1.594-1.149-3.187-1.728-4.779l-1.161-3.202c-.528 1.926-1.049 3.447-1.55
           4.528a.63.63 0 0 1-.091.143c-.164.197-.347.431-.556.697-1.065 1.357-2.795 3.563-5.974 6.025v-.001Z'
        />
      </g>
      <g>
        <path
          d='M143.49 229.707a.654.654 0 0 1-.559-.313c-3.852-6.305-9.487-10.464-15.867-11.71-5.597-1.093-11.261.207-15.948 3.663a.665.665 0 0 1-.404.127.662.662 0 0 1-.623-.503.656.656 0 0 1
           .042-.421c1.915-4.174 2.917-10.097-.015-14.202-2.808-3.932-8.649-5.504-17.359-4.674a.653.653 0 0 1-.256-1.274c4.881-1.503 7.008-4.035 6.95-5.879-.052-1.662-1.739-3.014-4.628-3.711a.646.646
            0 0 1-.405-.294.654.654 0 0 1 .218-.898.663.663 0 0 1 .495-.077c3.506.846 5.558 2.646 5.63 4.94.065 2.077-1.555 4.134-4.321 5.677 7.142-.1 12.092 1.72 14.743 5.433 2.802 3.922 2.416 9.238.936 13.516
             4.607-2.773 9.929-3.733 15.197-2.704 6.18 1.207 11.686 4.978 15.677 10.694.744-6.53 3.576-11.637 8.086-14.505 4.034-2.565 9.086-3.06 14.413-1.455-3.011-4.199-2.859-7.573-2.107-9.765 1.025-2.987 3.68-5.387
              7.506-6.886-6.562-.503-9.356-2.625-10.537-4.488-1.049-1.656-1.18-3.629-.398-5.765-3.703.658-9.095.546-11.526-1.403-.857-.688-1.291-1.58-1.253-2.579a.65.65 0 0 1 .679-.627.653.653 0 0 1 .63.677c-.022.586.228
               1.081.765 1.512 2.1 1.683 7.885 1.854 11.654.881a.656.656 0 0 1 .815.565.645.645 0 0 1-.068.364c-.772 1.508-1.431 3.718-.19 5.678 1.746 2.757 6.653 4.167 13.815 3.973a.656.656 0 0 1 .67.583.658.658 0 0
                1-.126.46.66.66 0 0 1-.406.251c-5.558 1.034-9.592 3.736-10.791 7.227-1.067 3.112.076 6.669 3.308 10.289a.65.65 0 0 1-.306 1.06.655.655 0 0 1-.43-.021c-5.722-2.314-11.196-2.111-15.413.57-4.577 2.91-7.289
                 8.377-7.639 15.394a.654.654 0 0 1-.654.621v-.001Z'
          fill='#1B75BB'
        />
      </g>
    </svg>
  );
};
