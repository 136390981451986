/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inspired.PaymentPortal.API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  InspiredPaymentPortalAPIPaymentsResponsesPaymentsResponse,
  InspiredLibrariesApiExceptionHandlingErrorResponse,
  GetPaymentsParams,
  InspiredPaymentPortalAPIPaymentsResponsesMakePaymentResponse,
  InspiredPaymentPortalAPIPaymentsRequestsMakePaymentRequest,
  InspiredPaymentPortalAPIPaymentsResponsesPaymentOptionsResponse,
  GetPaymentOptionsParams,
  InspiredPaymentPortalAPIPaymentsResponsesPaymentsStatusResponse,
  InspiredPaymentPortalAPIPaymentsRequestsMakeInvoicePaymentRequest
} from '../../models/payments';
import { customInstance } from '../../utils/axiosInstance';
import type { ErrorType } from '../../utils/axiosInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getPayments = (params?: GetPaymentsParams) => {
  return customInstance<InspiredPaymentPortalAPIPaymentsResponsesPaymentsResponse>({
    url: `/api/payments`,
    method: 'get',
    params
  });
};

export const getGetPaymentsQueryKey = (params?: GetPaymentsParams) =>
  [`/api/payments`, ...(params ? [params] : [])] as const;

export const getGetPaymentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPayments>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  params?: GetPaymentsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getPayments>>, TError, TData> }
): UseQueryOptions<Awaited<ReturnType<typeof getPayments>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPayments>>> = () => getPayments(params);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPaymentsQueryResult = NonNullable<Awaited<ReturnType<typeof getPayments>>>;
export type GetPaymentsQueryError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetPayments = <
  TData = Awaited<ReturnType<typeof getPayments>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  params?: GetPaymentsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getPayments>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPaymentsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const makePayment = (
  inspiredPaymentPortalAPIPaymentsRequestsMakePaymentRequest: InspiredPaymentPortalAPIPaymentsRequestsMakePaymentRequest
) => {
  return customInstance<InspiredPaymentPortalAPIPaymentsResponsesMakePaymentResponse>({
    url: `/api/payments`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: inspiredPaymentPortalAPIPaymentsRequestsMakePaymentRequest
  });
};

export const getMakePaymentMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof makePayment>>,
    TError,
    { data: InspiredPaymentPortalAPIPaymentsRequestsMakePaymentRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof makePayment>>,
  TError,
  { data: InspiredPaymentPortalAPIPaymentsRequestsMakePaymentRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof makePayment>>,
    { data: InspiredPaymentPortalAPIPaymentsRequestsMakePaymentRequest }
  > = props => {
    const { data } = props ?? {};

    return makePayment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MakePaymentMutationResult = NonNullable<Awaited<ReturnType<typeof makePayment>>>;
export type MakePaymentMutationBody = InspiredPaymentPortalAPIPaymentsRequestsMakePaymentRequest;
export type MakePaymentMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useMakePayment = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof makePayment>>,
    TError,
    { data: InspiredPaymentPortalAPIPaymentsRequestsMakePaymentRequest },
    TContext
  >;
}) => {
  const mutationOptions = getMakePaymentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPaymentOptions = (params?: GetPaymentOptionsParams) => {
  return customInstance<InspiredPaymentPortalAPIPaymentsResponsesPaymentOptionsResponse>({
    url: `/api/payments/payment-options`,
    method: 'get',
    params
  });
};

export const getGetPaymentOptionsQueryKey = (params?: GetPaymentOptionsParams) =>
  [`/api/payments/payment-options`, ...(params ? [params] : [])] as const;

export const getGetPaymentOptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentOptions>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  params?: GetPaymentOptionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentOptions>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getPaymentOptions>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentOptionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentOptions>>> = () =>
    getPaymentOptions(params);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPaymentOptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPaymentOptions>>
>;
export type GetPaymentOptionsQueryError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetPaymentOptions = <
  TData = Awaited<ReturnType<typeof getPaymentOptions>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  params?: GetPaymentOptionsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentOptions>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPaymentOptionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 */
export const getPaymentsStatus = () => {
  return customInstance<InspiredPaymentPortalAPIPaymentsResponsesPaymentsStatusResponse>({
    url: `/api/payments/status`,
    method: 'get'
  });
};

export const getGetPaymentsStatusQueryKey = () => [`/api/payments/status`] as const;

export const getGetPaymentsStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getPaymentsStatus>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentsStatus>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<typeof getPaymentsStatus>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentsStatusQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentsStatus>>> = () =>
    getPaymentsStatus();

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPaymentsStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPaymentsStatus>>
>;
export type GetPaymentsStatusQueryError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

/**
 * @deprecated
 */
export const useGetPaymentsStatus = <
  TData = Awaited<ReturnType<typeof getPaymentsStatus>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getPaymentsStatus>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPaymentsStatusQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const makeInvoicePayment = (
  inspiredPaymentPortalAPIPaymentsRequestsMakeInvoicePaymentRequest: InspiredPaymentPortalAPIPaymentsRequestsMakeInvoicePaymentRequest
) => {
  return customInstance<InspiredPaymentPortalAPIPaymentsResponsesMakePaymentResponse>({
    url: `/api/payments/invoice`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: inspiredPaymentPortalAPIPaymentsRequestsMakeInvoicePaymentRequest
  });
};

export const getMakeInvoicePaymentMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof makeInvoicePayment>>,
    TError,
    { data: InspiredPaymentPortalAPIPaymentsRequestsMakeInvoicePaymentRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof makeInvoicePayment>>,
  TError,
  { data: InspiredPaymentPortalAPIPaymentsRequestsMakeInvoicePaymentRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof makeInvoicePayment>>,
    { data: InspiredPaymentPortalAPIPaymentsRequestsMakeInvoicePaymentRequest }
  > = props => {
    const { data } = props ?? {};

    return makeInvoicePayment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type MakeInvoicePaymentMutationResult = NonNullable<
  Awaited<ReturnType<typeof makeInvoicePayment>>
>;
export type MakeInvoicePaymentMutationBody =
  InspiredPaymentPortalAPIPaymentsRequestsMakeInvoicePaymentRequest;
export type MakeInvoicePaymentMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useMakeInvoicePayment = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof makeInvoicePayment>>,
    TError,
    { data: InspiredPaymentPortalAPIPaymentsRequestsMakeInvoicePaymentRequest },
    TContext
  >;
}) => {
  const mutationOptions = getMakeInvoicePaymentMutationOptions(options);

  return useMutation(mutationOptions);
};
