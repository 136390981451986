/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inspired.PaymentPortal.Auth.Web
 * OpenAPI spec version: 1.0
 */
import { useMutation } from 'react-query';
import type { UseMutationOptions, MutationFunction } from 'react-query';
import type {
  InspiredPaymentPortalAuthWebModelsStartLogoutResponse,
  InspiredLibrariesApiExceptionHandlingErrorResponse
} from '../../models/auth';
import { customInstance } from '../../utils/axiosInstance';
import type { ErrorType } from '../../utils/axiosInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const postAuthApiLogoutStart = () => {
  return customInstance<InspiredPaymentPortalAuthWebModelsStartLogoutResponse>({
    url: `/auth/api/logout/start`,
    method: 'post'
  });
};

export const getPostAuthApiLogoutStartMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthApiLogoutStart>>,
    TError,
    TVariables,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postAuthApiLogoutStart>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postAuthApiLogoutStart>>,
    TVariables
  > = () => {
    return postAuthApiLogoutStart();
  };

  return { mutationFn, ...mutationOptions };
};

export type PostAuthApiLogoutStartMutationResult = NonNullable<
  Awaited<ReturnType<typeof postAuthApiLogoutStart>>
>;

export type PostAuthApiLogoutStartMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const usePostAuthApiLogoutStart = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postAuthApiLogoutStart>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const mutationOptions = getPostAuthApiLogoutStartMutationOptions(options);

  return useMutation(mutationOptions);
};
