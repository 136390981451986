/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inspired.PaymentPortal.API
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from 'react-query';
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  InspiredPaymentPortalAPIEnrolmentsResponsesUserEnrolmentsResponse,
  InspiredLibrariesApiExceptionHandlingErrorResponse,
  InspiredPaymentPortalAPIEnrolmentsResponsesContractOptionsResponse,
  InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest,
  InspiredPaymentPortalAPIEnrolmentsResponsesGetApplicationFormResponse,
  InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest,
  InspiredPaymentPortalAPIEnrolmentsResponsesGetContractViewResponse,
  InspiredPaymentPortalAPIEnrolmentsRequestsSetEnrolmentStateRequest
} from '../../models/payments';
import { customInstance } from '../../utils/axiosInstance';
import type { ErrorType } from '../../utils/axiosInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getEnrolments = () => {
  return customInstance<InspiredPaymentPortalAPIEnrolmentsResponsesUserEnrolmentsResponse>({
    url: `/api/enrolments`,
    method: 'get'
  });
};

export const getGetEnrolmentsQueryKey = () => [`/api/enrolments`] as const;

export const getGetEnrolmentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getEnrolments>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getEnrolments>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<typeof getEnrolments>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEnrolmentsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEnrolments>>> = () => getEnrolments();

  return { queryKey, queryFn, ...queryOptions };
};

export type GetEnrolmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getEnrolments>>>;
export type GetEnrolmentsQueryError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetEnrolments = <
  TData = Awaited<ReturnType<typeof getEnrolments>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getEnrolments>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEnrolmentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getEnrolmentOptions = (enrolmentId: string) => {
  return customInstance<InspiredPaymentPortalAPIEnrolmentsResponsesContractOptionsResponse>({
    url: `/api/enrolments/${enrolmentId}/options`,
    method: 'get'
  });
};

export const getGetEnrolmentOptionsQueryKey = (enrolmentId: string) =>
  [`/api/enrolments/${enrolmentId}/options`] as const;

export const getGetEnrolmentOptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getEnrolmentOptions>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEnrolmentOptions>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getEnrolmentOptions>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEnrolmentOptionsQueryKey(enrolmentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEnrolmentOptions>>> = () =>
    getEnrolmentOptions(enrolmentId);

  return { queryKey, queryFn, enabled: !!enrolmentId, ...queryOptions };
};

export type GetEnrolmentOptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEnrolmentOptions>>
>;
export type GetEnrolmentOptionsQueryError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetEnrolmentOptions = <
  TData = Awaited<ReturnType<typeof getEnrolmentOptions>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getEnrolmentOptions>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetEnrolmentOptionsQueryOptions(enrolmentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const setEnrolmentOptions = (
  enrolmentId: string,
  inspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest: InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest
) => {
  return customInstance<void>({
    url: `/api/enrolments/${enrolmentId}/options`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: inspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest
  });
};

export const getSetEnrolmentOptionsMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setEnrolmentOptions>>,
    TError,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setEnrolmentOptions>>,
  TError,
  {
    enrolmentId: string;
    data: InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setEnrolmentOptions>>,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest;
    }
  > = props => {
    const { enrolmentId, data } = props ?? {};

    return setEnrolmentOptions(enrolmentId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetEnrolmentOptionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof setEnrolmentOptions>>
>;
export type SetEnrolmentOptionsMutationBody =
  InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest;
export type SetEnrolmentOptionsMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useSetEnrolmentOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setEnrolmentOptions>>,
    TError,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest;
    },
    TContext
  >;
}) => {
  const mutationOptions = getSetEnrolmentOptionsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getContractOptions = (enrolmentId: string) => {
  return customInstance<InspiredPaymentPortalAPIEnrolmentsResponsesContractOptionsResponse>({
    url: `/api/enrolments/${enrolmentId}/contract-options`,
    method: 'get'
  });
};

export const getGetContractOptionsQueryKey = (enrolmentId: string) =>
  [`/api/enrolments/${enrolmentId}/contract-options`] as const;

export const getGetContractOptionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getContractOptions>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getContractOptions>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getContractOptions>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContractOptionsQueryKey(enrolmentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContractOptions>>> = () =>
    getContractOptions(enrolmentId);

  return { queryKey, queryFn, enabled: !!enrolmentId, ...queryOptions };
};

export type GetContractOptionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getContractOptions>>
>;
export type GetContractOptionsQueryError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetContractOptions = <
  TData = Awaited<ReturnType<typeof getContractOptions>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getContractOptions>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetContractOptionsQueryOptions(enrolmentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const setContractOptions = (
  enrolmentId: string,
  inspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest: InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest
) => {
  return customInstance<void>({
    url: `/api/enrolments/${enrolmentId}/contract-options`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: inspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest
  });
};

export const getSetContractOptionsMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setContractOptions>>,
    TError,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setContractOptions>>,
  TError,
  {
    enrolmentId: string;
    data: InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setContractOptions>>,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest;
    }
  > = props => {
    const { enrolmentId, data } = props ?? {};

    return setContractOptions(enrolmentId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetContractOptionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof setContractOptions>>
>;
export type SetContractOptionsMutationBody =
  InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest;
export type SetContractOptionsMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useSetContractOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setContractOptions>>,
    TError,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetContractOptionsRequest;
    },
    TContext
  >;
}) => {
  const mutationOptions = getSetContractOptionsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getConfirmationDetails = (enrolmentId: string) => {
  return customInstance<InspiredPaymentPortalAPIEnrolmentsResponsesGetApplicationFormResponse>({
    url: `/api/enrolments/${enrolmentId}/confirmationDetails`,
    method: 'get'
  });
};

export const getGetConfirmationDetailsQueryKey = (enrolmentId: string) =>
  [`/api/enrolments/${enrolmentId}/confirmationDetails`] as const;

export const getGetConfirmationDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getConfirmationDetails>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getConfirmationDetails>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getConfirmationDetails>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConfirmationDetailsQueryKey(enrolmentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConfirmationDetails>>> = () =>
    getConfirmationDetails(enrolmentId);

  return { queryKey, queryFn, enabled: !!enrolmentId, ...queryOptions };
};

export type GetConfirmationDetailsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getConfirmationDetails>>
>;
export type GetConfirmationDetailsQueryError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetConfirmationDetails = <
  TData = Awaited<ReturnType<typeof getConfirmationDetails>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getConfirmationDetails>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetConfirmationDetailsQueryOptions(enrolmentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 */
export const setConfirmationDetails = (
  enrolmentId: string,
  inspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest: InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest
) => {
  return customInstance<void>({
    url: `/api/enrolments/${enrolmentId}/confirmationDetails`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: inspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest
  });
};

export const getSetConfirmationDetailsMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setConfirmationDetails>>,
    TError,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setConfirmationDetails>>,
  TError,
  {
    enrolmentId: string;
    data: InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setConfirmationDetails>>,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest;
    }
  > = props => {
    const { enrolmentId, data } = props ?? {};

    return setConfirmationDetails(enrolmentId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetConfirmationDetailsMutationResult = NonNullable<
  Awaited<ReturnType<typeof setConfirmationDetails>>
>;
export type SetConfirmationDetailsMutationBody =
  InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest;
export type SetConfirmationDetailsMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

/**
 * @deprecated
 */
export const useSetConfirmationDetails = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setConfirmationDetails>>,
    TError,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest;
    },
    TContext
  >;
}) => {
  const mutationOptions = getSetConfirmationDetailsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getApplicationForm = (enrolmentId: string) => {
  return customInstance<InspiredPaymentPortalAPIEnrolmentsResponsesGetApplicationFormResponse>({
    url: `/api/enrolments/${enrolmentId}/application-form`,
    method: 'get'
  });
};

export const getGetApplicationFormQueryKey = (enrolmentId: string) =>
  [`/api/enrolments/${enrolmentId}/application-form`] as const;

export const getGetApplicationFormQueryOptions = <
  TData = Awaited<ReturnType<typeof getApplicationForm>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApplicationForm>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApplicationForm>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetApplicationFormQueryKey(enrolmentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApplicationForm>>> = () =>
    getApplicationForm(enrolmentId);

  return { queryKey, queryFn, enabled: !!enrolmentId, ...queryOptions };
};

export type GetApplicationFormQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApplicationForm>>
>;
export type GetApplicationFormQueryError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetApplicationForm = <
  TData = Awaited<ReturnType<typeof getApplicationForm>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApplicationForm>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApplicationFormQueryOptions(enrolmentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const setApplicationForm = (
  enrolmentId: string,
  inspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest: InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest
) => {
  return customInstance<InspiredPaymentPortalAPIEnrolmentsResponsesGetContractViewResponse>({
    url: `/api/enrolments/${enrolmentId}/application-form`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: inspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest
  });
};

export const getSetApplicationFormMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setApplicationForm>>,
    TError,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setApplicationForm>>,
  TError,
  {
    enrolmentId: string;
    data: InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setApplicationForm>>,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest;
    }
  > = props => {
    const { enrolmentId, data } = props ?? {};

    return setApplicationForm(enrolmentId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetApplicationFormMutationResult = NonNullable<
  Awaited<ReturnType<typeof setApplicationForm>>
>;
export type SetApplicationFormMutationBody =
  InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest;
export type SetApplicationFormMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useSetApplicationForm = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setApplicationForm>>,
    TError,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetApplicationFormRequest;
    },
    TContext
  >;
}) => {
  const mutationOptions = getSetApplicationFormMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @deprecated
 */
export const summitEnrolment = (enrolmentId: string) => {
  return customInstance<void>({ url: `/api/enrolments/${enrolmentId}/submit`, method: 'post' });
};

export const getSummitEnrolmentMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof summitEnrolment>>,
    TError,
    { enrolmentId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof summitEnrolment>>,
  TError,
  { enrolmentId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof summitEnrolment>>,
    { enrolmentId: string }
  > = props => {
    const { enrolmentId } = props ?? {};

    return summitEnrolment(enrolmentId);
  };

  return { mutationFn, ...mutationOptions };
};

export type SummitEnrolmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof summitEnrolment>>
>;

export type SummitEnrolmentMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

/**
 * @deprecated
 */
export const useSummitEnrolment = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof summitEnrolment>>,
    TError,
    { enrolmentId: string },
    TContext
  >;
}) => {
  const mutationOptions = getSummitEnrolmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getContractViewUrl = (enrolmentId: string) => {
  return customInstance<InspiredPaymentPortalAPIEnrolmentsResponsesGetContractViewResponse>({
    url: `/api/enrolments/${enrolmentId}/contractViewUrl`,
    method: 'get'
  });
};

export const getGetContractViewUrlQueryKey = (enrolmentId: string) =>
  [`/api/enrolments/${enrolmentId}/contractViewUrl`] as const;

export const getGetContractViewUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof getContractViewUrl>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getContractViewUrl>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof getContractViewUrl>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetContractViewUrlQueryKey(enrolmentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContractViewUrl>>> = () =>
    getContractViewUrl(enrolmentId);

  return { queryKey, queryFn, enabled: !!enrolmentId, ...queryOptions };
};

export type GetContractViewUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getContractViewUrl>>
>;
export type GetContractViewUrlQueryError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetContractViewUrl = <
  TData = Awaited<ReturnType<typeof getContractViewUrl>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getContractViewUrl>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetContractViewUrlQueryOptions(enrolmentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const downloadContractPdf = (enrolmentId: string) => {
  return customInstance<Blob>({
    url: `/api/enrolments/${enrolmentId}/downloadPdf`,
    method: 'get',
    responseType: 'blob'
  });
};

export const getDownloadContractPdfQueryKey = (enrolmentId: string) =>
  [`/api/enrolments/${enrolmentId}/downloadPdf`] as const;

export const getDownloadContractPdfQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadContractPdf>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof downloadContractPdf>>, TError, TData>;
  }
): UseQueryOptions<Awaited<ReturnType<typeof downloadContractPdf>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDownloadContractPdfQueryKey(enrolmentId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadContractPdf>>> = () =>
    downloadContractPdf(enrolmentId);

  return { queryKey, queryFn, enabled: !!enrolmentId, ...queryOptions };
};

export type DownloadContractPdfQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadContractPdf>>
>;
export type DownloadContractPdfQueryError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useDownloadContractPdf = <
  TData = Awaited<ReturnType<typeof downloadContractPdf>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(
  enrolmentId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof downloadContractPdf>>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadContractPdfQueryOptions(enrolmentId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @deprecated
 */
export const cancelSubmission = (enrolmentId: string) => {
  return customInstance<void>({ url: `/api/enrolments/${enrolmentId}/cancel`, method: 'post' });
};

export const getCancelSubmissionMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelSubmission>>,
    TError,
    { enrolmentId: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelSubmission>>,
  TError,
  { enrolmentId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelSubmission>>,
    { enrolmentId: string }
  > = props => {
    const { enrolmentId } = props ?? {};

    return cancelSubmission(enrolmentId);
  };

  return { mutationFn, ...mutationOptions };
};

export type CancelSubmissionMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelSubmission>>
>;

export type CancelSubmissionMutationError =
  ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

/**
 * @deprecated
 */
export const useCancelSubmission = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelSubmission>>,
    TError,
    { enrolmentId: string },
    TContext
  >;
}) => {
  const mutationOptions = getCancelSubmissionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const setState = (
  enrolmentId: string,
  inspiredPaymentPortalAPIEnrolmentsRequestsSetEnrolmentStateRequest: InspiredPaymentPortalAPIEnrolmentsRequestsSetEnrolmentStateRequest
) => {
  return customInstance<void>({
    url: `/api/enrolments/${enrolmentId}/setState`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: inspiredPaymentPortalAPIEnrolmentsRequestsSetEnrolmentStateRequest
  });
};

export const getSetStateMutationOptions = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setState>>,
    TError,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetEnrolmentStateRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setState>>,
  TError,
  { enrolmentId: string; data: InspiredPaymentPortalAPIEnrolmentsRequestsSetEnrolmentStateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setState>>,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetEnrolmentStateRequest;
    }
  > = props => {
    const { enrolmentId, data } = props ?? {};

    return setState(enrolmentId, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetStateMutationResult = NonNullable<Awaited<ReturnType<typeof setState>>>;
export type SetStateMutationBody =
  InspiredPaymentPortalAPIEnrolmentsRequestsSetEnrolmentStateRequest;
export type SetStateMutationError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useSetState = <
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setState>>,
    TError,
    {
      enrolmentId: string;
      data: InspiredPaymentPortalAPIEnrolmentsRequestsSetEnrolmentStateRequest;
    },
    TContext
  >;
}) => {
  const mutationOptions = getSetStateMutationOptions(options);

  return useMutation(mutationOptions);
};
