import { FC, ReactElement, useCallback } from 'react';

import { DialogActions, DialogContent, DialogTitle, IconButton, DialogProps } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { Button } from 'shared/components/Button';

import { StyledAlertDialog } from './AlertDialog.styled';

interface AlertContainerProps extends DialogProps {
  title: string;
  description: ReactElement | string;
  confirmBtnText: string;
  cancelBtnText: string;
  open: boolean;
  onCancel: () => void;
  onClose: () => void;
  onConfirm: () => void;
  isLoadingButton?: boolean;
  isLoading?: boolean;
  isError?: boolean;
}

type ReasonType = 'backdropClick' | 'escapeKeyDown';

export const AlertDialogContainer: FC<AlertContainerProps> = ({
  open,
  onCancel,
  onConfirm,
  onClose,
  title,
  description,
  confirmBtnText,
  cancelBtnText,
  isLoadingButton,
  isLoading,
  isError = true,
  ...otherProps
}) => {
  const handleOnClose = useCallback(
    (event: {}, reason: ReasonType) => {
      if (reason !== 'backdropClick' && onClose) {
        onClose();
      }
    },
    [onClose]
  );

  const buttonColor = isError ? 'error' : 'primary';

  return (
    <StyledAlertDialog open={open} onClose={handleOnClose} {...otherProps}>
      <DialogTitle>
        {title}
        <IconButton onClick={onCancel} size='large'>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{cancelBtnText}</Button>
        {isLoadingButton ? (
          <LoadingButton
            variant='contained'
            color={buttonColor}
            onClick={onConfirm}
            loading={isLoading}
          >
            {confirmBtnText}
          </LoadingButton>
        ) : (
          <Button variant='contained' color={buttonColor} onClick={onConfirm}>
            {confirmBtnText}
          </Button>
        )}
      </DialogActions>
    </StyledAlertDialog>
  );
};
