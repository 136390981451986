import { useMatch } from 'react-router-dom';

import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { ROUTES } from 'shared/constants';

const INITIAL_ACTION_REQUIRED_STATE = undefined;
const SESSION_STORAGE_ACTION_REQUIRED_KEY = 'paymentHubReEnrolmentActionRequired';

export const useIsReEnrolmentRoutesPath = () => {
  const matchReEnrolment = useMatch(ROUTES.RE_ENROLMENT);
  const matchReEnrolmentOptions = useMatch(ROUTES.RE_ENROLMENT_OPTIONS);
  const matchReEnrolmentConfirmation = useMatch(ROUTES.RE_ENROLMENT_CONFIRMATION);
  const matchReEnrolmentContract = useMatch(ROUTES.RE_ENROLMENT_CONTRACT);

  return Boolean(
    matchReEnrolment ||
      matchReEnrolmentOptions ||
      matchReEnrolmentConfirmation ||
      matchReEnrolmentContract
  );
};

export const useReEnrolmentActionRequiredSessionStorage = () => {
  const [storedValue, setStoredValue] = useSessionStorage<boolean | undefined>(
    SESSION_STORAGE_ACTION_REQUIRED_KEY,
    INITIAL_ACTION_REQUIRED_STATE
  );

  return { storedValue, setStoredValue };
};
