import { FC, useMemo } from 'react';

import { FormattedMessage as ReactIntlFormattedMessage } from 'react-intl';
import { Props as ReactIntlFormattedMessageProps } from 'react-intl/src/components/message';

import { useLocale } from 'shared/providers/Locale';

import { findFallbackMessage } from './Intl.helpers';

export const FormattedMessage: FC<ReactIntlFormattedMessageProps> = ({ id, ...rest }) => {
  const { translations, fallbackSettings, locale } = useLocale();

  const defaultMessage = useMemo(
    () =>
      findFallbackMessage({
        id,
        defaultLocale: locale,
        fallback: fallbackSettings ? fallbackSettings[locale] : undefined,
        translations
      }),
    [locale, translations, fallbackSettings, id]
  );

  return <ReactIntlFormattedMessage id={id} defaultMessage={defaultMessage} {...rest} />;
};
