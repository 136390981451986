import { QueryKey } from 'react-query';

import { getGetMandateQueryKey } from 'shared/hooks/payment-api/direct-debit-endpoint';
import { getGetSummaryQueryKey } from 'shared/hooks/payment-api/summary-endpoint';
import { getGetProfileQueryKey } from 'shared/hooks/payment-api/profile-endpoint';
import { getGetContactUsOptionsQueryKey } from 'shared/hooks/payment-api/contact-us-endpoint';
import { getGetInvoicesQueryKey } from 'shared/hooks/payment-api/invoices-endpoint';
import {
  getGetEnrolmentsQueryKey,
  getGetConfirmationDetailsQueryKey,
  getGetEnrolmentOptionsQueryKey
} from 'shared/hooks/payment-api/enrolments-endpoint';
import {
  getGetPaymentOptionsQueryKey,
  getGetPaymentsQueryKey
} from 'shared/hooks/payment-api/payments-endpoint';
import { getGetAuthApiLoginStatusQueryKey } from 'shared/hooks/auth-api/login';
import { getGetSettingsQueryKey } from 'shared/hooks/payment-api/settings-endpoint';
import { GetPaymentOptionsParams } from 'shared/models/payments';

type QueryKeyFunction = (param: any) => QueryKey;
type QueryKeyArray = QueryKey;

interface QueryKeys {
  all: QueryKeyArray;
  settings: QueryKeyArray;
  summary: QueryKeyFunction;
  invoices: QueryKeyFunction;
  profile: QueryKeyArray;
  contactUsQueryOptions: QueryKeyArray;
  payments: QueryKeyFunction;
  loginStatus: QueryKeyArray;
  directDebit: QueryKeyArray;
  paymentOptions: QueryKeyFunction;
  reEnrolmentOptions: QueryKeyFunction;
  reEnrolment: QueryKeyArray;
  reEnrolmentConfirmation: QueryKeyFunction;
}

export const queryKeys: QueryKeys = {
  all: ['payment-portal'],
  settings: ['payment-portal', ...getGetSettingsQueryKey()],
  summary: params => ['payment-portal', ...getGetSummaryQueryKey(params)],
  invoices: params => ['payment-portal', ...getGetInvoicesQueryKey(params)],
  profile: ['payment-portal', ...getGetProfileQueryKey()],
  contactUsQueryOptions: ['payment-portal', ...getGetContactUsOptionsQueryKey()],
  payments: params => ['payment-portal', ...getGetPaymentsQueryKey(params)],
  loginStatus: ['payment-portal', ...getGetAuthApiLoginStatusQueryKey()],
  directDebit: ['payment-portal', ...getGetMandateQueryKey()],
  paymentOptions: (params?: GetPaymentOptionsParams) => [
    'payment-portal',
    ...getGetPaymentOptionsQueryKey(params)
  ],
  reEnrolment: ['payment-portal', ...getGetEnrolmentsQueryKey()],
  reEnrolmentConfirmation: params => [
    'payment-portal',
    ...getGetConfirmationDetailsQueryKey(params)
  ],
  reEnrolmentOptions: params => ['payment-portal', ...getGetEnrolmentOptionsQueryKey(params)]
};
