import { Button, ButtonProps, styled } from '@mui/material';

import { TextTransformType } from 'shared/types/textTransformType';

const CUSTOM_PROPS = ['textTransform'] as PropertyKey[];

export const StyledButton = styled(Button, {
  shouldForwardProp: (propName: PropertyKey) => !CUSTOM_PROPS.includes(propName)
})<ButtonProps & { textTransform?: TextTransformType }>`
  text-transform: ${({ textTransform }) => textTransform || 'capitalize'};
` as typeof Button;
