import { useMemo } from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { ROUTES } from 'shared/constants';
import { Preview as LayoutPreview, Layout } from 'shared/components/Layout';
import { RootBoundary } from 'shared/components/RootBoundary';
import { useGetFormattedSettings } from 'shared/hooks/useGetFormattedSettings';

const App = () => {
  const { disabledRoutes } = useGetFormattedSettings();

  const availableRoutes = useMemo(() => {
    return [
      {
        index: true,
        lazy: () => import('pages/AccountSummary')
      },
      {
        path: ROUTES.INVOICES,
        lazy: () => import('pages/Invoices')
      },
      {
        path: ROUTES.MAKE_PAYMENT,
        lazy: () => import('pages/MakePayment')
      },
      {
        path: ROUTES.SUCCESS_PAYMENT,
        lazy: () => import('pages/PaymentSuccess')
      },
      {
        path: ROUTES.BOLETO_PAYMENT,
        lazy: () => import('pages/PaymentsBoleto')
      },
      {
        path: ROUTES.PAYMENTS,
        lazy: () => import('pages/Payments')
      },
      {
        path: ROUTES.PROFILE,
        lazy: () => import('pages/Profile')
      },
      {
        path: ROUTES.CONTACT_US,
        lazy: () => import('pages/ContactUs')
      },
      {
        path: ROUTES.DIRECT_DEBIT,
        lazy: () => import('pages/DirectDebit')
      },
      {
        path: ROUTES.RE_ENROLMENT,
        lazy: () => import('pages/ReEnrolment')
      },
      {
        path: ROUTES.RE_ENROLMENT_OPTIONS,
        lazy: () => import('pages/ReEnrolmentOptions')
      },
      {
        path: ROUTES.RE_ENROLMENT_CONFIRMATION,
        lazy: () => import('pages/ReEnrolmentConfirmation')
      },
      {
        path: ROUTES.RE_ENROLMENT_CONTRACT,
        lazy: () => import('pages/ReEnrolmentContract')
      }
    ].filter(route => !disabledRoutes.includes(route?.path || ''));
  }, [disabledRoutes]);

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: ROUTES.HOME,
          errorElement: <RootBoundary />,
          element: <Layout />,
          children: availableRoutes
        }
      ]),
    [availableRoutes]
  );

  return <RouterProvider router={router} fallbackElement={<LayoutPreview />} />;
};

export default App;
