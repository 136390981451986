import { Link, LinkProps, styled } from '@mui/material';

import { hideMixin, showMixin } from 'styles/mixins';
import { openSidebarWidth } from 'shared/constants';

export const StyledLogoHolder = styled(Link, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'isSidebarOpen'
})<LinkProps & { isSidebarOpen: boolean }>`
  margin: ${({ theme }) => theme.spacing(3, 2)};

  ${({ isSidebarOpen, theme }) =>
    isSidebarOpen ? { ...showMixin(theme) } : { ...hideMixin(theme) }}

  > img {
    // max-width of the image is width of opened sidebar minus parents margin from both sides
    max-width: ${({ theme }) => `calc(${openSidebarWidth}px - ${theme.spacing(2 * 2)})`};
    object-fit: contain;
    vertical-align: middle;
  }
`;
