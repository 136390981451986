/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Inspired.PaymentPortal.API
 * OpenAPI spec version: 1.0
 */
import { useQuery } from 'react-query';
import type { UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from 'react-query';
import type {
  InspiredPaymentPortalAPISettingsSettingsResponse,
  InspiredLibrariesApiExceptionHandlingErrorResponse
} from '../../models/payments';
import { customInstance } from '../../utils/axiosInstance';
import type { ErrorType } from '../../utils/axiosInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getSettings = () => {
  return customInstance<InspiredPaymentPortalAPISettingsSettingsResponse>({
    url: `/api/settings`,
    method: 'get'
  });
};

export const getGetSettingsQueryKey = () => [`/api/settings`] as const;

export const getGetSettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getSettings>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSettings>>, TError, TData>;
}): UseQueryOptions<Awaited<ReturnType<typeof getSettings>>, TError, TData> & {
  queryKey: QueryKey;
} => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSettingsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettings>>> = () => getSettings();

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getSettings>>>;
export type GetSettingsQueryError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>;

export const useGetSettings = <
  TData = Awaited<ReturnType<typeof getSettings>>,
  TError = ErrorType<InspiredLibrariesApiExceptionHandlingErrorResponse>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getSettings>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSettingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
