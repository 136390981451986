import { AppBar, AppBarProps, CSSObject, styled, Theme } from '@mui/material';

import { openSidebarWidth } from 'shared/constants';

const openedMixin = (theme: Theme): CSSObject => ({
  width: `calc(100% - ${openSidebarWidth}px)`,
  marginLeft: `${openSidebarWidth}px`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
});

export const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'open'
})<AppBarProps & { open: boolean }>`
  transition: ${({ theme }) =>
    theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })};

  ${({ theme }) => theme.breakpoints.up('lg')} {
    ${({ theme, open }) => open && { ...openedMixin(theme) }}
  }

  & > .MuiToolbar-root {
    align-items: stretch;
    justify-content: space-between;

    .MuiButtonBase-root:not(.MuiButton-text) {
      width: ${({ theme }) => theme.spacing(8)};
    }

    .MuiTypography-root {
      ${({ theme }) => theme.breakpoints.down('md')} {
        padding: 0 ${({ theme }) => theme.spacing(0.5)};
      }

      ${({ theme }) => theme.breakpoints.up('md')} {
        padding: 0 ${({ theme }) => theme.spacing(2)};
      }

      ${({ theme }) => theme.breakpoints.up('lg')} {
        padding: 0 ${({ theme }) => theme.spacing(3)};
      }
    }
  }
`;
