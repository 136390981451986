export const InspiredLogo = () => {
  return (
    <svg width='80' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M30.432 18.157v-7.9c0-1.363-.136-1.698-1.458-1.709a.115.115 0 1 1 0-.23h5.3v9.839h-3.842ZM45.826 5.468c0-1.103-.854-1.968-1.944-1.968a1.948
         1.948 0 0 0-1.968 1.968c0 1.072.883 1.944 1.968 1.944a1.946 1.946 0 0 0 1.944-1.944Zm-40.376 0c0-1.103-.854-1.968-1.944-1.968a1.947 1.947
          0 0 0-1.968 1.968c0 1.072.883 1.944 1.968 1.944A1.946 1.946 0 0 0 5.45 5.468Zm1.52 12.583a.106.106 0 0 1-.106.106H.106a.106.106 0 1 1
           0-.212c1.322-.01 1.468-.376 1.468-1.764v-5.925c0-1.362-.136-1.697-1.458-1.708a.115.115 0 1 1 0-.23h5.299v7.863c0 1.293.07 1.752
            1.449 1.763.058 0 .106.048.106.107Zm33.023-4.813.002.108c0 1.291-.338 2.507-.95 3.423-.645.963-1.543 1.494-2.526 1.494-.708
             0-1.344-.29-1.837-.836-.016-.018-.028-.036-.04-.051-.031-.037-.045-.082-.03-.124l.004-.011a.09.09 0 0 1
              .02-.028c.044-.047.113-.045.164-.007.013.01.047.038.085.061a.447.447 0 0 0 .245.071c.958 0 1.005-1.414
               1.005-3.914v-.373c0-2.5-.047-3.915-1.005-3.915a.447.447 0 0 0-.245.071c-.038.024-.072.051-.085.062-.051.038-.12.04-.164-.007a.105.105
                0 0 1-.02-.028l-.004-.011c-.015-.043-.001-.088.03-.124.012-.015.024-.034.04-.052.493-.546 1.129-.835 1.837-.835.983 0
                 1.88.53 2.525 1.494.613.915.95 2.131.95 3.423l-.001.109Zm7.353 4.813a.106.106 0 0 1-.106.106h-6.758a.106.106 0 0 1
                  0-.212c1.322-.01 1.468-.376 1.468-1.764v-5.925c0-1.362-.136-1.697-1.458-1.708a.115.115 0 1 1 0-.23h5.299v7.863c0
                   1.293.07 1.752 1.45 1.763.057 0 .105.048.105.107Zm-33.851-.107c-.64-.028-.708-.56-.71-1.734V8.317H7.488a.115.115 0 1 0 0
                    .231c1.322.01 1.458.346 1.458 1.708v5.925c0 1.388-.146 1.754-1.467 1.764a.106.106 0 1 0 0 .212h6.017a.106.106 0 1 0
                     0-.213Zm44.014-7.77c0-1.103-.853-1.967-1.944-1.967a1.947 1.947 0 0 0-1.968 1.968c0 1.07.883 1.943 1.968 1.943a1.946
                      1.946 0 0 0 1.944-1.943Zm-2.791 7.877a.106.106 0 0 1-.107.106h-6.757a.106.106 0 1 1 0-.212c1.322-.01 1.467-.376
                       1.467-1.764v-5.925c0-1.362-.136-1.697-1.458-1.708a.115.115 0 1 1 0-.23h5.3v7.863c0 1.293.07 1.752 1.448 1.763.059
                        0 .107.048.107.107Zm-26.24-3.287c0 1.61-1.002 2.738-2.328
                         3.212-.556.208-1.134.3-1.634.3-.076 0-.162.003-.216-.002-.066-.008-.11-.05-.11-.111 0-.062.044-.107.11-.107.04 0
                          .116.022.156.024a1.19 1.19 0 0 0 .532-.106c.494-.27.639-.877.639-1.248
                           0-.684-.482-1.221-2.074-1.703l-.537-.167c-.933-.284-2.104-.559-2.667-1.619-.207-.39-.332-.885-.332-1.527 0-1.61
                            1.002-2.738 2.329-3.212a4.762 4.762 0 0 1 1.634-.3c.075 0 .162-.004.215.002.067.008.111.05.111.111s-.044.107-.11.107c-.04
                             0-.117-.022-.157-.025a1.188 1.188 0 0 0-.531.107c-.495.27-.64.877-.64 1.247 0 .685.482 1.222 2.074 1.703l.537.167c.934.285
                              2.105.56 2.667 1.62.207.39.332.885.332 1.527Zm-.533-5.073a1.47 1.47 0 0 0-1.476-1.494c-.838 0-1.495.657-1.495 1.494 0
                               .815.67 1.477 1.495 1.477.814 0 1.476-.662 1.476-1.477Zm-7.394 7.092a1.47 1.47 0 0 0 1.476 1.494c.838 0 1.494-.656
                                1.494-1.494 0-.814-.67-1.476-1.494-1.476-.814 0-1.476.662-1.476 1.476Zm-1.954-4.479v-.659c0-1.255-.177-2.12-1.075-2.835-.515-.4-1.173-.603-1.957-.603-.967
                                 0-1.765.3-2.307.866-.047.048-.08.082-.105.111a.123.123 0 0 0-.032.079c0 .061.05.11.11.11.031 0
                                  .061-.02.079-.032.077-.055.237-.174.319-.205a.991.991 0 0 1 .326-.07c.517 0 .838.363.838
                                   2.172v2.692h3.809V12.65a13.543 13.543 0 0 1-.006-.345Zm-1.904 5.973c-1.085 0-1.968-.872-1.968-1.943 0-1.104.864-1.968
                                    1.968-1.968 1.09 0 1.944.864 1.944 1.968a1.946 1.946 0 0 1-1.944 1.943Zm17.426 2.754c0-1.104-.854-1.968-1.944-1.968a1.947
                                     1.947 0 0 0-1.968 1.968c0 1.071.883 1.943 1.968 1.943a1.946 1.946 0 0 0 1.944-1.943Z'
        fill='#405166'
      />
      <path
        d='M78.41 8.317v7.9c0 1.364.136 1.699 1.458 1.709.064 0 .115.052.115.116a.115.115 0 0 1-.115.115h-5.3v-9.84h3.841Zm-9.561
         4.92-.002-.11c0-1.29.338-2.506.95-3.422.645-.963 1.542-1.494 2.526-1.494.709 0 1.344.29 1.837.836.016.018.027.036.04.052.03.036.045.081.03.123l-.005.011a.082.082
          0 0 1-.02.028c-.043.047-.112.045-.163.007-.013-.01-.047-.037-.085-.06a.448.448 0 0 0-.245-.071c-.958-.001-1.005 1.413-1.005 3.913v.374c0 2.5.047 3.914 1.005 3.914a.45.45
           0 0 0 .245-.071c.038-.024.072-.051.085-.062.051-.037.12-.04.163.007a.085.085 0 0 1 .02.028l.005.012c.015.042 0 .087-.03.123-.013.015-.024.034-.04.052-.493.546-1.128.835-1.837.835-.984
            0-1.88-.53-2.525-1.494-.613-.916-.95-2.13-.95-3.422v-.11Zm-3.385 4.765a1.946 1.946 0 0 1-1.944-1.944c0-1.103.854-1.968 1.944-1.968 1.103 0 1.968.865 1.968 1.968 0 1.072-.884
             1.944-1.968 1.944Zm-2.193.257c-1.86 0-3.269-.57-4.187-1.692-.946-1.158-1.048-2.54-1.048-3.08 0-1.49.626-2.885 1.81-4.036 1.139-1.091 2.542-1.255 3.277-1.255 1.986
              0 3.31.926 4.046 2.829.244.675.263 1.3.263 2.11v.05h-3.49v-.18c.039-.747.056-1.728.056-2.37 0-1.047-.036-1.714-.538-2.146-.071-.072-.238-.08-.375-.08-1.027 0-1.208
               1.308-1.208 4.059v1.796c0 2.632.583 3.671 2.08 3.709.33.008.388.04.387.102-.002.175-1.063.184-1.073.184ZM78.635 5.468c0-1.103-.854-1.968-1.944-1.968a1.947
                1.947 0 0 0-1.968 1.968c0 1.072.883 1.944 1.968 1.944a1.946 1.946 0 0 0 1.944-1.944Z'
        fill='#1375BC'
      />
    </svg>
  );
};
