import { styled, Box, BoxProps } from '@mui/material';

import { openSidebarWidth } from 'shared/constants/styles';

export const StyledBox = styled(Box, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'fixedWidth'
})<BoxProps & { fixedWidth: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing(3)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${({ theme }) => theme.spacing(2)};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    ${({ fixedWidth, theme }) =>
      fixedWidth && {
        maxWidth: `calc(${theme.breakpoints.values.xl}px - ${openSidebarWidth}px)`,
        alignSelf: 'center',
        width: '100%'
      }}
  }
`;
