import { FC, useMemo, PropsWithChildren, useCallback, useEffect, useState, useRef } from 'react';

import { useGetAuthApiLoginStatus, usePostAuthApiLoginStart } from 'shared/hooks/auth-api/login';
import { usePostAuthApiLogoutStart } from 'shared/hooks/auth-api/logout';
import { removeAuthHeader, setAuthHeader } from 'shared/utils/apiService';
import { queryClient } from 'shared/utils/quryClient';
import { queryKeys } from 'shared/constants';
import { useFilterSessionStorage } from 'shared/components/Filter/hooks';
import { useReEnrolmentActionRequiredSessionStorage } from 'shared/components/Layout/ReEnrolmentActionRequired/ReEnrolmentActionRequired.hooks';

import AuthContext, { AuthContext as IAuthContext } from './Auth.context';

const AuthContainerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [init, setInit] = useState(true);
  const startFlag = useRef(false);

  const { removeFiltersFromStorage } = useFilterSessionStorage();
  const { setStoredValue: setReEnrolmentActionRequiredStorageValue } =
    useReEnrolmentActionRequiredSessionStorage();

  const {
    data: loginStatusData,
    isLoading: loginStatusLoading,
    error: loginStatusError
  } = useGetAuthApiLoginStatus({
    query: { queryKey: queryKeys.loginStatus, retry: false, useErrorBoundary: false }
  });
  const loginStartMutation = usePostAuthApiLoginStart();
  const logoutStartMutation = usePostAuthApiLogoutStart();

  const error = useMemo(
    () => loginStatusError || loginStartMutation.error || logoutStartMutation?.error,
    [loginStatusError, loginStartMutation.error, logoutStartMutation?.error]
  );

  const postLoginStartAndRedirect = useCallback(
    async () => {
      const returnUrl = window.location.href;

      if (!startFlag.current) {
        // hack to stop cyclic queries
        startFlag.current = true;
        const { authorizationRequestUrl } = await loginStartMutation.mutateAsync({
          data: { returnUrl }
        });

        window.location.href = authorizationRequestUrl as string;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const logout = useCallback(async () => {
    const { logoutRequestUrl } = await logoutStartMutation.mutateAsync();

    await queryClient.invalidateQueries(queryKeys.all);
    removeAuthHeader();
    removeFiltersFromStorage();
    setReEnrolmentActionRequiredStorageValue(undefined);

    // remove all query parameters in url
    window.history.pushState(null, '', window.location.origin + window.location.pathname);

    window.location.href = logoutRequestUrl as string;
  }, [logoutStartMutation, removeFiltersFromStorage, setReEnrolmentActionRequiredStorageValue]);

  const handleSetLogin = useCallback(() => {
    setAuthHeader(loginStatusData?.token as string);
    setIsLoggedIn(true);
  }, [loginStatusData?.token]);

  useEffect(() => {
    if (loginStatusData && init) {
      setInit(false);

      loginStatusData.isLoggedIn ? handleSetLogin() : postLoginStartAndRedirect();
    }
  }, [handleSetLogin, init, loginStatusData, postLoginStartAndRedirect]);

  const authData = useMemo(
    (): IAuthContext => ({
      error,
      logout,
      isLoggedIn,
      isLogOutLoading: logoutStartMutation?.isLoading,
      isLoading: loginStatusLoading || loginStartMutation.isLoading,
      postLoginStartAndRedirect,
      actAsUser: loginStatusData?.masqueradingDetails
    }),
    [
      error,
      logout,
      isLoggedIn,
      loginStatusLoading,
      postLoginStartAndRedirect,
      loginStartMutation.isLoading,
      logoutStartMutation?.isLoading,
      loginStatusData?.masqueradingDetails
    ]
  );

  return <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>;
};

export default AuthContainerProvider;
