import { FC, useCallback } from 'react';

import { MenuItem, MenuProps, ListItemText } from '@mui/material';

import { useLocale } from 'shared/providers/Locale/Locale.hooks';

import { StyledMenu } from './Menu.styled';

export const LocaleMenuComponent: FC<MenuProps> = props => {
  const { supportedLocales, locale, switchLocale } = useLocale();

  const handleClick = useCallback((loc: string) => switchLocale(loc), [switchLocale]);

  return (
    <StyledMenu {...props}>
      {supportedLocales.map(loc => (
        <MenuItem
          key={loc.locale}
          selected={loc.locale === locale}
          onClick={() => handleClick(loc.locale)}
        >
          <ListItemText>{loc.localeName}</ListItemText>
        </MenuItem>
      ))}
    </StyledMenu>
  );
};
